var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.util.malli.registry.js");
'use strict';var wZ,Rma,Sma,Tma,Uma,Vma,CZ,Wma,Xma,Yma,EZ,Zma,$ma,ana,JZ,KZ,bna,cna,dna,SZ,ena,fna,gna,VZ,hna,ina,jna,kna;wZ=function(){};$CLJS.xZ=function(a){return null!=a?$CLJS.Bc===a.ki?!0:a.Wc?!1:$CLJS.$a(wZ,a):$CLJS.$a(wZ,a)};Rma=new $CLJS.r(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.yZ=new $CLJS.M("lib","source-column-alias","lib/source-column-alias",1494821386);
Sma=new $CLJS.M("metabase.lib.schema.metadata","database","metabase.lib.schema.metadata/database",270611124);Tma=new $CLJS.M("lib","persisted-info","lib/persisted-info",-118569625);$CLJS.zZ=new $CLJS.M("source","previous-stage","source/previous-stage",1962610878);$CLJS.AZ=new $CLJS.M(null,"database-id","database-id",1883826326);Uma=new $CLJS.r("metabase.lib.metadata.protocols","metadata-provider?","metabase.lib.metadata.protocols/metadata-provider?",-472923865,null);
$CLJS.BZ=new $CLJS.M("source","table-defaults","source/table-defaults",909975194);Vma=new $CLJS.M(null,"human-readable-values","human-readable-values",-624842907);CZ=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.internal","metabase.lib.schema.metadata/column.remapping.internal",-285151559);Wma=new $CLJS.M("metabase.lib.schema.metadata","metadata-providerable","metabase.lib.schema.metadata/metadata-providerable",1520216538);Xma=new $CLJS.M(null,"dbms-version","dbms-version",-69238036);
Yma=new $CLJS.M(null,"field-name","field-name",1300687948);$CLJS.DZ=new $CLJS.M(null,"dataset","dataset",1159262238);EZ=new $CLJS.M("metabase.lib.schema.metadata","persisted-info","metabase.lib.schema.metadata/persisted-info",1457716669);Zma=new $CLJS.M(null,"field-definitions","field-definitions",1650155116);$CLJS.FZ=new $CLJS.M("source","expressions","source/expressions",-458367840);$CLJS.GZ=new $CLJS.M(null,"definition","definition",-1198729982);$ma=new $CLJS.M(null,"state","state",-1988618099);
$CLJS.HZ=new $CLJS.M("source","implicitly-joinable","source/implicitly-joinable",-729101197);ana=new $CLJS.M(null,"is-audit","is-audit",327058420);$CLJS.IZ=new $CLJS.M("source","joins","source/joins",1225821486);JZ=new $CLJS.M("metabase.lib.schema.metadata","column-source","metabase.lib.schema.metadata/column-source",1071627828);KZ=new $CLJS.M("metabase.lib.schema.metadata","metadata-provider","metabase.lib.schema.metadata/metadata-provider",-1514728827);
$CLJS.LZ=new $CLJS.M("lib","desired-column-alias","lib/desired-column-alias",-1998967402);bna=new $CLJS.M("lib","external-remap","lib/external-remap",543358316);$CLJS.MZ=new $CLJS.M(null,"selected?","selected?",-742502788);cna=new $CLJS.M("metabase.lib.schema.metadata","segment","metabase.lib.schema.metadata/segment",-393440267);dna=new $CLJS.M("metabase.lib.schema.metadata","metric","metabase.lib.schema.metadata/metric",1984974682);$CLJS.NZ=new $CLJS.M(null,"table-name","table-name",-2117866341);
$CLJS.OZ=new $CLJS.M("source","card","source/card",-139977973);$CLJS.PZ=new $CLJS.M(null,"active","active",1895962068);$CLJS.QZ=new $CLJS.M(null,"dataset-query","dataset-query",1851066427);$CLJS.RZ=new $CLJS.M(null,"result-metadata","result-metadata",2017934672);SZ=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.external","metabase.lib.schema.metadata/column.remapping.external",304054297);ena=new $CLJS.M(null,"details","details",1956795411);
$CLJS.TZ=new $CLJS.M("source","fields","source/fields",-649667981);fna=new $CLJS.M("metabase.lib.schema.metadata","card","metabase.lib.schema.metadata/card",147726027);$CLJS.UZ=new $CLJS.M("source","native","source/native",-1444604147);gna=new $CLJS.M("metabase.lib.schema.metadata","table","metabase.lib.schema.metadata/table",-2128230385);VZ=new $CLJS.M("metabase.lib.schema.metadata","persisted-info.definition","metabase.lib.schema.metadata/persisted-info.definition",496848961);
$CLJS.WZ=new $CLJS.M("lib","card-id","lib/card-id",-1770167062);hna=new $CLJS.M("metadata.column.remapping","internal","metadata.column.remapping/internal",-1077920983);ina=new $CLJS.r(null,"metadata-provider?","metadata-provider?",1669871524,null);jna=new $CLJS.M("lib","internal-remap","lib/internal-remap",-220033801);kna=new $CLJS.M("metadata.column.remapping","external","metadata.column.remapping/external",-1813287755);$CLJS.XZ=new $CLJS.M(null,"source-alias","source-alias",1652088724);
$CLJS.YZ=new $CLJS.M(null,"engine","engine",1459054265);$CLJS.X(JZ,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.os,$CLJS.OZ,$CLJS.UZ,$CLJS.zZ,$CLJS.BZ,$CLJS.TZ,$CLJS.jL,$CLJS.dL,$CLJS.IZ,$CLJS.FZ,$CLJS.HZ],null));$CLJS.X(SZ,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,kna],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$i,$CLJS.WK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.rE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XK,$CLJS.hL],null)],null));
$CLJS.X(CZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,hna],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$i,$CLJS.WK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.rE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,$CLJS.li],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[Vma,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,$CLJS.li],null)],null)],null));
$CLJS.X($CLJS.VK,new $CLJS.P(null,19,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.pt,"Valid column metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.NK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.Zj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pB,$CLJS.AE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$i,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.hL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mE,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.qs,$CLJS.Zj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fi,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.AE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aL,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.hL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$K,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.hL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XZ,
new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.rE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qE,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.rE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PK,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JZ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WZ,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.YK],
null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yZ,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.rE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LZ,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zj,new $CLJS.h(null,2,[$CLJS.Zn,1,$CLJS.jk,60],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MZ,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Gs],null),new $CLJS.P(null,3,5,$CLJS.Q,
[bna,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SZ],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[jna,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CZ],null)],null)],null)],null));
$CLJS.X(VZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NZ,$CLJS.rE],null),new $CLJS.P(null,2,5,$CLJS.Q,[Zma,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[Yma,$CLJS.rE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pB,$CLJS.AE],null)],null)],null)],null)],null)],null));
$CLJS.X(EZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PZ,$CLJS.Gs],null),new $CLJS.P(null,2,5,$CLJS.Q,[$ma,$CLJS.rE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NZ,$CLJS.rE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GZ,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VZ],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MK,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,
$CLJS.rE],null)],null)],null));
$CLJS.X(fna,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.pt,"Valid Card metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.iL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$i,$CLJS.YK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.rE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AZ,$CLJS.ZK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QZ,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.ik],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RZ,
new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,$CLJS.ik],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DZ,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Gs],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bL,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.SK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Tma,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,EZ],null)],null)],null)],null));
$CLJS.X(cna,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.pt,"Valid Segment metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.RK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$i,$CLJS.QK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.rE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bL,$CLJS.SK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.ik],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Jt,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.rE],null)],null)],null));
$CLJS.X(dna,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.pt,"Valid Metric metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.TK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$i,$CLJS.OK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.rE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bL,$CLJS.SK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.ik],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Jt,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.rE],null)],null)],null));
$CLJS.X(gna,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.pt,"Valid Table metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.UK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$i,$CLJS.SK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.rE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mE,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.rE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pl,new $CLJS.h(null,
1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.rE],null)],null)],null));
$CLJS.X(Sma,new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.pt,"Valid Database metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.fL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$i,$CLJS.ZK],null),new $CLJS.P(null,3,5,$CLJS.Q,[Xma,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.ik],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[ena,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.ik],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.YZ,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Yi],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eA,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aj,$CLJS.Yi],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[ana,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Gs],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cL,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.ik],null)],null)],null));
$CLJS.X(KZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.pt,"Valid MetadataProvider"],null),new $CLJS.Gc(function(){return $CLJS.xZ},Uma,$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,$CLJS.Ti,$CLJS.oj,$CLJS.yk,$CLJS.ui,$CLJS.kk,$CLJS.ok],[Rma,ina,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.U(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Sq],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.xZ)?$CLJS.xZ.H:null]))],null));
$CLJS.X(Wma,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KZ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.pt,"map with a MetadataProvider in the key :lib/metadata (i.e. a query)"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KZ],null)],null)],null)],null));