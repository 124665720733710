var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.i18n.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var Gna,Hna,Ina,Jna,Kna,W0;
$CLJS.T0=function(){function a(e,f){if($CLJS.Va(f))return"";e=$CLJS.xA(e);var k=$CLJS.zh.h(f);switch(k instanceof $CLJS.M?k.T:null){case "default":return $CLJS.AV("Default period","Default periods",e);case "millisecond":return $CLJS.AV("Millisecond","Milliseconds",e);case "second":return $CLJS.AV("Second","Seconds",e);case "minute":return $CLJS.AV("Minute","Minutes",e);case "hour":return $CLJS.AV("Hour","Hours",e);case "day":return $CLJS.AV("Day","Days",e);case "week":return $CLJS.AV("Week","Weeks",
e);case "month":return $CLJS.AV("Month","Months",e);case "quarter":return $CLJS.AV("Quarter","Quarters",e);case "year":return $CLJS.AV("Year","Years",e);case "minute-of-hour":return $CLJS.AV("Minute of hour","Minutes of hour",e);case "hour-of-day":return $CLJS.AV("Hour of day","Hours of day",e);case "day-of-week":return $CLJS.AV("Day of week","Days of week",e);case "day-of-month":return $CLJS.AV("Day of month","Days of month",e);case "day-of-year":return $CLJS.AV("Day of year","Days of year",e);case "week-of-year":return $CLJS.AV("Week of year",
"Weeks of year",e);case "month-of-year":return $CLJS.AV("Month of year","Months of year",e);case "quarter-of-year":return $CLJS.AV("Quarter of year","Quarters of year",e);default:return f=$CLJS.Pt($CLJS.gh(f),/-/),e=$CLJS.y(f),f=$CLJS.A(e),e=$CLJS.B(e),$CLJS.At(" ",$CLJS.ee($CLJS.uA(f),e))}}function b(e){return d.g?d.g(1,e):d.call(null,1,e)}function c(){return d.g?d.g(1,null):d.call(null,1,null)}var d=null;d=function(e,f){switch(arguments.length){case 0:return c.call(this);case 1:return b.call(this,
e);case 2:return a.call(this,e,f)}throw Error("Invalid arity: "+arguments.length);};d.o=c;d.h=b;d.g=a;return d}();$CLJS.U0=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.ij,$CLJS.fC],null),$CLJS.ij,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.temporal-bucket","with-temporal-bucket-method"),function(f){return $CLJS.dC(f)},e,a,b,c,d)}();Gna=$CLJS.Xe($CLJS.N);Hna=$CLJS.Xe($CLJS.N);
Ina=$CLJS.Xe($CLJS.N);Jna=$CLJS.Xe($CLJS.N);Kna=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.ij,$CLJS.fC],null),$CLJS.ij,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));$CLJS.V0=new $CLJS.di($CLJS.Fh.g("metabase.lib.temporal-bucket","temporal-bucket-method"),$CLJS.dC,Kna,Gna,Hna,Ina,Jna);$CLJS.V0.m(null,$CLJS.ci,function(){return null});$CLJS.V0.m(null,$CLJS.cH,function(a){return $CLJS.si.h(a)});W0=new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.UG,null,$CLJS.XG,null,$CLJS.YG,null,$CLJS.VG,null],null),null);
$CLJS.Lna=$CLJS.eg.j($CLJS.Cf,$CLJS.Pk.g($CLJS.Qk.h(W0),$CLJS.hf.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.Xt,$CLJS.cH,$CLJS.si,a],null);return $CLJS.E.g(a,$CLJS.qk)?$CLJS.R.j(b,$CLJS.ci,!0):b})),$CLJS.oH);$CLJS.Mna=$CLJS.Rk.g(function(a){var b=new $CLJS.h(null,2,[$CLJS.Xt,$CLJS.cH,$CLJS.si,a],null);return $CLJS.E.g(a,$CLJS.Yt)?$CLJS.R.j(b,$CLJS.ci,!0):b},$CLJS.jH);
$CLJS.Nna=$CLJS.eg.j($CLJS.Cf,$CLJS.Pk.g($CLJS.Qk.h(W0),$CLJS.hf.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.Xt,$CLJS.cH,$CLJS.si,a],null);return $CLJS.E.g(a,$CLJS.Yt)?$CLJS.R.j(b,$CLJS.ci,!0):b})),$CLJS.pH);$CLJS.A0.m(null,$CLJS.cH,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.si);return $CLJS.T0.h(a)});
$CLJS.H0.m(null,$CLJS.cH,function(a,b,c){return $CLJS.gl.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.mE,$CLJS.a0.j(a,b,c),$CLJS.y0,$CLJS.WA($CLJS.V0.h(c))],null),$CLJS.em(c,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ci,$CLJS.i0],null))]))});
$CLJS.X0=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.ij,$CLJS.fC],null),$CLJS.ij,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.temporal-bucket","available-temporal-buckets-method"),function(f,k,l){return $CLJS.dC(l)},e,a,b,c,d)}();$CLJS.X0.m(null,$CLJS.ci,function(){return $CLJS.bh});
$CLJS.Y0=function(){function a(d,e,f){return $CLJS.X0.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();