var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var YW,ZW,aX,bX,nla,dX,eX,XW,fX,ola,pla,qla,rla,jX,kX,mX,nX,oX,pX,qX,rX,tla,ula,tX,vla,uX,wla,wX,xla,yla,zla,Ala,Bla,UW;$CLJS.VW=function(a,b){if("string"===typeof b)return UW(a,b);throw new TypeError("re-seq must match against a string.");};$CLJS.WW=function(a,b,c){var d=$CLJS.fm(a,b);if($CLJS.n(d)){var e=$CLJS.R.j;d=$CLJS.Fb(d);c=c.h?c.h(d):c.call(null,d);a=e.call($CLJS.R,a,b,c)}return a};
YW=function(a){var b=XW;return $CLJS.EA(function(c){return function(d,e,f){e=b.h?b.h(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};ZW=function(a){if($CLJS.md(a))return a;throw $CLJS.hi("Invalid pattern: don't know how to handle symbol.",new $CLJS.h(null,1,[$CLJS.Hi,a],null));};
$CLJS.$W=function(a,b,c){return $CLJS.xd(c)?$CLJS.eg.g(c,function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var z=0;;)if(z<m){var C=$CLJS.kd(l,z),G=$CLJS.I(C,0,null),K=$CLJS.I(C,1,null);C=t;var S=$CLJS.Q,V=G;G=$CLJS.be.g(b,G);G=a.g?a.g(G,K):a.call(null,G,K);C.add(new $CLJS.P(null,2,5,S,[V,G],null));z+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.A(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[v,function(){var z=$CLJS.be.g(b,v),C=x;return a.g?a.g(z,C):a.call(null,z,C)}()],null),f($CLJS.Lc(k)))}return null}},null,null)}(c)}()):$CLJS.wd(c)?$CLJS.Rk.g($CLJS.Ve(a,$CLJS.A(c)instanceof $CLJS.M?$CLJS.be.g(b,$CLJS.A(c)):b),c):c};aX=function(a){var b=(b=a instanceof $CLJS.M)?$CLJS.ie(a):b;return $CLJS.n(b)?[$CLJS.ie(a),"/",$CLJS.gh(a)].join(""):$CLJS.gh(a)};
bX=function(a,b){var c=$CLJS.wd(b)&&!$CLJS.zf(b)&&$CLJS.A(b)instanceof $CLJS.M;return c?$CLJS.ud(a)?(b=$CLJS.A(b),a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.A(b)):c};
nla=function(a,b){return $CLJS.jf(function(c){var d=$CLJS.lf;a:try{if($CLJS.zd(c)&&1<=$CLJS.D(c))try{var e=$CLJS.Wk.j(c,0,1);if($CLJS.zd(e)&&1===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.n($CLJS.Ve($CLJS.E,a)(f))){var k=$CLJS.Wk.g(c,1);var l=new $CLJS.P(null,1,5,$CLJS.Q,[k],null)}else throw $CLJS.Y;}catch(t){if(t instanceof Error){var m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;
}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y){l=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[c],null)],null);break a}throw m;}throw t;}return $CLJS.A($CLJS.y(d($CLJS.Wa,l)))},$CLJS.H([b]))};
dX=function(a,b){for(;;)switch(b=$CLJS.il.h($CLJS.lf($CLJS.Wa,b)),$CLJS.D(b)){case 0:return null;case 1:return a=$CLJS.A(b),cX.h?cX.h(a):cX.call(null,a);default:if($CLJS.n($CLJS.Re($CLJS.Ve(bX,a),b))){var c=a;b=nla(a,b);a=c}else{c=$CLJS.hf.g(cX,b);if($CLJS.E.g(c,b))return $CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),b);b=c}}};eX=function(a){var b=$CLJS.wd(a);return b?(b=!$CLJS.zf(a))?$CLJS.yA($CLJS.ge,$CLJS.Ya)($CLJS.A(a)):b:b};
XW=function(a){return $CLJS.n($CLJS.yA($CLJS.ge,$CLJS.Ya)(a))?$CLJS.zh.h($CLJS.CA(aX(a).toLowerCase(),/_/,"-")):a};fX=function(a,b){var c=eX(b);return $CLJS.n(c)?(b=XW($CLJS.A(b)),$CLJS.ud(a)?(a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,b)):c};ola=function(a){return $CLJS.J.j(new $CLJS.h(null,3,[$CLJS.ci,$CLJS.Td,$CLJS.nj,XW,$CLJS.TN,XW],null),a,function(b){var c=$CLJS.J,d=c.g;b=$CLJS.Ee([a,b]);b=gX.g?gX.g(b,hX):gX.call(null,b,hX);return d.call(c,b,a)})};
pla=function(a){a=$CLJS.eg.j($CLJS.N,$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=XW(c);var d=ola(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,d.h?d.h(b):d.call(null,b)],null)}),a);return $CLJS.E.g($CLJS.nj.h(a),$CLJS.BF)&&$CLJS.Va($CLJS.TN.h(a))?$CLJS.R.j(a,$CLJS.TN,$CLJS.AG):a};qla=function(a){return $CLJS.eg.j($CLJS.N,$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=aX(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.R.j(pla(b),$CLJS.T,c)],null)}),a)};
rla=function(a){a=gX.h?gX.h(a):gX.call(null,a);return iX.h?iX.h(a):iX.call(null,a)};jX=function(a){return $CLJS.WW($CLJS.WW($CLJS.eb(function(b,c){return $CLJS.WW(b,c,$CLJS.zh)},a,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.eD,$CLJS.Lea,$CLJS.GO,sla,$CLJS.Zz,$CLJS.si],null)),$CLJS.eC,rla),$CLJS.VN,$CLJS.xV)};kX=function(a){return $CLJS.Fd(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,a,null],null):a};mX=function(a){return lX.h(kX(a))};
nX=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);return cX($CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.hf.g(lX,b)))};oX=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,mX(b)],null),$CLJS.hf.g(lX,c))};pX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(a)?new $CLJS.P(null,2,5,$CLJS.Q,[b,mX(a)],null):new $CLJS.P(null,1,5,$CLJS.Q,[b],null)};
qX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,mX(a)],null)};rX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,lX.h(a)],null)};tla=function(a){return $CLJS.Df($CLJS.cm(iX,sX(a)))};ula=function(a){for(;;)if($CLJS.n(eX(a)))a=new $CLJS.P(null,1,5,$CLJS.Q,[a],null);else return $CLJS.Fe($CLJS.Rk.g(kX,a))};
tX=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.BF);return $CLJS.n(b)?$CLJS.Sk.j(a,$CLJS.BF,lX):a};
vla=function(a){return $CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,tX(t)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,
2,5,$CLJS.Q,[l,tX(f)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())};uX=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.JQ);return $CLJS.n(b)?$CLJS.Sk.j(a,$CLJS.JQ,vla):a};wla=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.lQ);b=$CLJS.Va(a)?vX.h?vX.h(b):vX.call(null,b):b;return $CLJS.n(a)?uX(b):b};wX=function(a){return $CLJS.ud(a)?$CLJS.y(a):null!=a};
xla=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.EF);var c=$CLJS.J.g(b,$CLJS.xN),d=$CLJS.J.g(b,$CLJS.UO),e=$CLJS.J.g(b,$CLJS.lQ);try{if($CLJS.n(d)){var f=$CLJS.O(b),k=$CLJS.J.g(f,$CLJS.UO);var l=$CLJS.ZL($CLJS.Hk.g(f,$CLJS.UO),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EF,$CLJS.UO],null),k)}else l=b;var m=$CLJS.n(a)?$CLJS.Sk.j(l,$CLJS.EF,vX):l,t=$CLJS.n(c)?$CLJS.Sk.j(m,$CLJS.xN,$CLJS.Ve($CLJS.Rk,iX)):m,u=$CLJS.n(e)?$CLJS.Sk.j(t,$CLJS.lQ,uX):t,v=$CLJS.O(u),x=$CLJS.J.g(v,$CLJS.lQ);return $CLJS.n(x)?$CLJS.R.j(iX($CLJS.Hk.g(v,
$CLJS.lQ)),$CLJS.lQ,x):iX(v)}catch(z){if(z instanceof Error)throw m=z,$CLJS.gi($CLJS.hE("Error canonicalizing query: {0}",$CLJS.H([$CLJS.vV(m)])),new $CLJS.h(null,1,[$CLJS.EF,a],null),m);throw z;}};
yla=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.EF),c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.WF);c=$CLJS.J.g(c,$CLJS.RE);return $CLJS.y(b)&&$CLJS.y(c)?(b=$CLJS.eg.j($CLJS.bh,$CLJS.qC,$CLJS.y($CLJS.lf($CLJS.Wa,function k(e,f){try{if($CLJS.zd(f)&&3===$CLJS.D(f))try{var l=$CLJS.F(f,0);if($CLJS.he(l,$CLJS.hG)){var m=$CLJS.F(f,1),t=$CLJS.F(f,2);return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[f,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,m,$CLJS.Hk.g(t,$CLJS.nG)],null)],null)],null)}throw $CLJS.Y;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw u;}else throw $CLJS.Y;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Y)return $CLJS.CV(k,e,f);throw l;}throw u;}}($CLJS.Cf,b)))),$CLJS.xX.j(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EF,$CLJS.RE],null),$CLJS.Pk.g($CLJS.Df,$CLJS.Ve($CLJS.Qk,b)))):a};
zla=function(a){try{return yla(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.gi($CLJS.JE("Error performing whole query transformations"),new $CLJS.h(null,1,[$CLJS.EF,a],null),b);}throw c;}};
Ala=function(a,b){var c=$CLJS.eg.g($CLJS.jd(a),function(){return function f(e){return new $CLJS.ne(null,function(){for(var k=e;;)if(k=$CLJS.y(k)){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var z=0;;)if(z<m){var C=$CLJS.kd(l,z),G=$CLJS.I(C,0,null);C=$CLJS.I(C,1,null);var K=$CLJS.be.g(b,G);C=yX.g?yX.g(C,K):yX.call(null,C,K);null!=C&&t.add(new $CLJS.P(null,2,5,$CLJS.Q,[G,C],null));z+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.A(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);u=function(){var z=x,C=$CLJS.be.g(b,v);return yX.g?yX.g(z,C):yX.call(null,z,C)}();if(null!=u)return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null),f($CLJS.Lc(k)));k=$CLJS.Lc(k)}else return null},null,null)}(a)}());return $CLJS.y(c)?c:null};Bla=function(a,b){a=$CLJS.Rk.g(function(c){var d=$CLJS.be.g(b,zX);return yX.g?yX.g(c,d):yX.call(null,c,d)},a);return $CLJS.n($CLJS.Re($CLJS.Wa,a))?a:null};
UW=function UW(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.Df(d);return $CLJS.ee(f,new $CLJS.ne(null,function(){var k=e.length;k=d.index+(1>k?1:k);return k<=b.length?(k=b.substring(k),UW.g?UW.g(a,k):UW.call(null,a,k)):null},null,null))};
$CLJS.xX=function xX(a){switch(arguments.length){case 3:return xX.j(arguments[0],arguments[1],arguments[2]);case 4:return xX.v(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return xX.N(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return xX.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return xX.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.w(c.slice(6),0,null))}};$CLJS.xX.j=function(a,b,c){var d=$CLJS.y(b);b=$CLJS.A(d);if(d=$CLJS.B(d))a=$CLJS.R.j(a,b,$CLJS.xX.j($CLJS.J.g(a,b),d,c));else{d=$CLJS.R.j;var e=$CLJS.J.g(a,b);c=c.h?c.h(e):c.call(null,e);a=d.call($CLJS.R,a,b,c)}return a};
$CLJS.xX.v=function(a,b,c,d){var e=$CLJS.y(b);b=$CLJS.A(e);if(e=$CLJS.B(e))a=$CLJS.R.j(a,b,$CLJS.xX.v($CLJS.J.g(a,b),e,c,d));else{e=$CLJS.R.j;var f=$CLJS.J.g(a,b);c=c.g?c.g(f,d):c.call(null,f,d);a=e.call($CLJS.R,a,b,c)}return a};$CLJS.xX.N=function(a,b,c,d,e){var f=$CLJS.y(b);b=$CLJS.A(f);if(f=$CLJS.B(f))a=$CLJS.R.j(a,b,$CLJS.xX.N($CLJS.J.g(a,b),f,c,d,e));else{f=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=c.j?c.j(k,d,e):c.call(null,k,d,e);a=f.call($CLJS.R,a,b,c)}return a};
$CLJS.xX.W=function(a,b,c,d,e,f){var k=$CLJS.y(b);b=$CLJS.A(k);if(k=$CLJS.B(k))a=$CLJS.R.j(a,b,$CLJS.xX.W($CLJS.J.g(a,b),k,c,d,e,f));else{k=$CLJS.R.j;var l=$CLJS.J.g(a,b);c=c.v?c.v(l,d,e,f):c.call(null,l,d,e,f);a=k.call($CLJS.R,a,b,c)}return a};$CLJS.xX.l=function(a,b,c,d,e,f,k){var l=$CLJS.y(b);b=$CLJS.A(l);return(l=$CLJS.B(l))?$CLJS.R.j(a,b,$CLJS.Se.l($CLJS.xX,$CLJS.J.g(a,b),l,c,d,$CLJS.H([e,f,k]))):$CLJS.R.j(a,b,$CLJS.Se.l(c,$CLJS.J.g(a,b),d,e,f,$CLJS.H([k])))};
$CLJS.xX.B=function(a){var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);var e=$CLJS.B(d);d=$CLJS.A(e);var f=$CLJS.B(e);e=$CLJS.A(f);var k=$CLJS.B(f);f=$CLJS.A(k);k=$CLJS.B(k);return this.l(b,a,c,d,e,f,k)};$CLJS.xX.A=6;
var cX=function cX(a){for(;;){if($CLJS.xd(a))return $CLJS.zt(a,cX);if($CLJS.Dd(a))a=$CLJS.Df(a);else if($CLJS.zd(a)){if($CLJS.Va($CLJS.Re($CLJS.Wa,a)))return null;var c=a,d=$CLJS.y(c),e=$CLJS.A(d),f=$CLJS.B(d),k=e,l=f,m=$CLJS.I(l,0,null),t=l,u=k,v=u instanceof $CLJS.M?u.T:null;switch(v){case "not":if($CLJS.Ml(m)){var x=$CLJS.A(m),z=x instanceof $CLJS.M?x.T:null;switch(z){case "not":a=$CLJS.hd(m);continue;case "and":return dX($CLJS.rs,$CLJS.hf.g(function(){return function(C){return new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.ts,C],null)}}(a,x,z,u,v,c,d,e,f,k,l,m,t),$CLJS.Lc(m)));case "or":return dX($CLJS.xs,$CLJS.hf.g(function(){return function(C){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,C],null)}}(a,x,z,u,v,c,d,e,f,k,l,m,t),$CLJS.Lc(m)));default:return a}}else return a;case "and":return dX($CLJS.xs,t);case "or":return dX($CLJS.rs,t);default:return $CLJS.Rk.g(cX,a)}}else return a}},Cla=new $CLJS.M(null,"value_field","value_field",-980977878),AX=new $CLJS.M(null,"ascending","ascending",-988350486),
BX=new $CLJS.M(null,"named","named",-422393479),CX=new $CLJS.M(null,"values_source_config","values_source_config",-590570309),DX=new $CLJS.M(null,"descending","descending",-24766135),EX=new $CLJS.M(null,"datetime-field","datetime-field",21731696),Dla=new $CLJS.M(null,"use-as-display-name?","use-as-display-name?",686752941),FX=new $CLJS.M(null,"field-literal","field-literal",-1295883554),GX=new $CLJS.M(null,"viz-settings","viz-settings",256055379),Ela=new $CLJS.M(null,"rows","rows",850049680),Fla=
new $CLJS.M(null,"special-fn","special-fn",1290649344),zX=new $CLJS.M("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),hX=new $CLJS.M(null,"ignore-path","ignore-path",944069061),Gla=new $CLJS.M(null,"label_field","label_field",-1573182765),Hla=new $CLJS.M(null,"fk-\x3e","fk-\x3e",-499026738),Ila=new $CLJS.M(null,"joined-field","joined-field",-2048778268),sla=new $CLJS.M(null,"visibility_type","visibility_type",-508434247);var HX,Jla=$CLJS.Xe($CLJS.N),Kla=$CLJS.Xe($CLJS.N),Lla=$CLJS.Xe($CLJS.N),Mla=$CLJS.Xe($CLJS.N),Nla=$CLJS.J.j($CLJS.N,$CLJS.ij,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));HX=new $CLJS.di($CLJS.Fh.g("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.Pk.g(XW,$CLJS.A),Nla,Jla,Kla,Lla,Mla);HX.m(null,$CLJS.XF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XF,b],null);return null!=a?$CLJS.be.g(b,a):b});
HX.m(null,$CLJS.mB,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mB,a instanceof $CLJS.M?aX(a):a],null)});HX.m(null,$CLJS.EV,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?$CLJS.be.g(HX.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EV,b,c],null)),a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EV,gX.g?gX.g(b,hX):gX.call(null,b,hX),XW(c)],null)});
HX.m(null,$CLJS.hG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=gX.g?gX.g(a,hX):gX.call(null,a,hX);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,b,function(){var d=$CLJS.n($CLJS.pB.h(c))?$CLJS.Sk.j(c,$CLJS.pB,$CLJS.zh):c;d=$CLJS.n($CLJS.nG.h(c))?$CLJS.Sk.j(d,$CLJS.nG,$CLJS.zh):d;return $CLJS.n($CLJS.xR.h(c))?$CLJS.Sk.j(d,$CLJS.xR,function(e){return $CLJS.n($CLJS.CE.h(e))?$CLJS.Sk.j(e,$CLJS.CE,$CLJS.zh):e}):d}()],null)});
HX.m(null,FX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[FX,b instanceof $CLJS.M?aX(b):b,$CLJS.zh.h(a)],null)});HX.m(null,EX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[EX,gX.g?gX.g(b,hX):gX.call(null,b,hX),$CLJS.gt,XW(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[EX,gX.g?gX.g(b,hX):gX.call(null,b,hX),XW(c)],null)});
HX.m(null,$CLJS.tG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);return $CLJS.n(a)?$CLJS.be.g(HX.h(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.tG,b,c,d],null)),gX.g?gX.g(a,hX):gX.call(null,a,hX)):new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.tG,gX.g?gX.g(b,hX):gX.call(null,b,hX),$CLJS.Fd(c)?c:XW(c),XW(d)],null)});
HX.m(null,$CLJS.aJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aJ,b,XW(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aJ,$CLJS.Sv],null)});HX.m(null,$CLJS.ZI,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZI,b,XW(a)],null)});
HX.m(null,$CLJS.iJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.iJ,gX.g?gX.g(b,hX):gX.call(null,b,hX),c,XW(a)],null)});HX.m(null,$CLJS.oJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.oJ,gX.g?gX.g(b,hX):gX.call(null,b,hX),c,XW(a)],null)});
HX.m(null,$CLJS.gJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gJ,gX.g?gX.g(b,hX):gX.call(null,b,hX),XW(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gJ,gX.g?gX.g(b,hX):gX.call(null,b,hX)],null)});
HX.m(null,$CLJS.mJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.mJ,gX.g?gX.g(b,hX):gX.call(null,b,hX),XW(c),XW(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mJ,gX.g?gX.g(b,hX):gX.call(null,b,hX),XW(c)],null)});
HX.m(null,$CLJS.YI,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.YI,gX.g?gX.g(b,hX):gX.call(null,b,hX),gX.g?gX.g(c,hX):gX.call(null,c,hX),XW(a)],null)});HX.m(null,$CLJS.Ej,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,b,a],null)});
HX.m(null,$CLJS.ci,function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);return $CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[XW(a)],null),$CLJS.hf.h(function(c){return gX.g?gX.g(c,hX):gX.call(null,c,hX)}),b)});
var IX=function IX(a){if($CLJS.n($CLJS.yA($CLJS.ge,$CLJS.Ya)(a))){var c=XW(a);var d=new $CLJS.ah(null,new $CLJS.h(null,18,[$CLJS.Zn,null,$CLJS.Xr,null,$CLJS.wG,null,$CLJS.qG,null,$CLJS.it,null,$CLJS.iG,null,$CLJS.BG,null,$CLJS.CG,null,$CLJS.NF,null,$CLJS.TF,null,$CLJS.Wr,null,$CLJS.OF,null,$CLJS.vG,null,$CLJS.oG,null,$CLJS.jk,null,$CLJS.Qx,null,$CLJS.bG,null,$CLJS.uG,null],null),null);c=d.h?d.h(c):d.call(null,c)}else c=null;return $CLJS.n(c)?c:$CLJS.n(eX(a))?(a=$CLJS.A(a),IX.h?IX.h(a):IX.call(null,
a)):null},Ola=new $CLJS.h(null,8,[$CLJS.nj,XW,$CLJS.lQ,function(a){a=YW(a);return $CLJS.y($CLJS.JQ.h(a))?$CLJS.Sk.j(a,$CLJS.JQ,qla):a},$CLJS.EF,new $CLJS.h(null,6,[$CLJS.XF,function JX(a){if($CLJS.n($CLJS.yA($CLJS.ge,$CLJS.Ya)(a)))return XW(a);if($CLJS.n(fX(BX,a))){a=$CLJS.y(a);$CLJS.A(a);var c=$CLJS.B(a);a=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[BX,JX.h?JX.h(a):JX.call(null,a)],null),c)}return $CLJS.n($CLJS.n(eX(a))?IX($CLJS.hd(a)):null)?$CLJS.Rk.g(JX,a):gX.g?gX.g(a,
hX):gX.call(null,a,hX)},$CLJS.bQ,function(a){return $CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[aX(u),gX.g?gX.g(t,hX):gX.call(null,t,hX)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=
$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[aX(l),gX.g?gX.g(f,hX):gX.call(null,f,hX)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.RR,function(a){return $CLJS.Df(function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m);t=$CLJS.n(eX(t))?HX.h(t):$CLJS.ce(HX.h($CLJS.ce(t)));l.add(t);m+=
1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}l=$CLJS.A(e);return $CLJS.ee($CLJS.n(eX(l))?HX.h(l):$CLJS.ce(HX.h($CLJS.ce(l))),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.LQ,function(a){a=YW(a);a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.lQ);if($CLJS.n(b))return a=$CLJS.BV(a,new $CLJS.h(null,1,[$CLJS.lQ,$CLJS.EF],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.lQ],null),a=gX.g?gX.g(a,b):gX.call(null,a,b),$CLJS.BV(a,new $CLJS.h(null,1,[$CLJS.EF,$CLJS.lQ],
null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.EF],null);return gX.g?gX.g(a,b):gX.call(null,a,b)},$CLJS.UO,new $CLJS.h(null,1,[zX,jX],null),$CLJS.GN,new $CLJS.h(null,1,[zX,function(a){a=gX.g?gX.g(a,$CLJS.EF):gX.call(null,a,$CLJS.EF);var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.CE),d=$CLJS.J.g(b,$CLJS.RE);a=$CLJS.J.g(b,$CLJS.VE);b=$CLJS.n(c)?$CLJS.Sk.j(b,$CLJS.CE,XW):b;d=$CLJS.n($CLJS.yA($CLJS.ge,$CLJS.Ya)(d))?$CLJS.Sk.j(b,$CLJS.RE,XW):b;return $CLJS.n(a)?$CLJS.Sk.j(d,$CLJS.VE,aX):d}],null)],null),$CLJS.Fy,
new $CLJS.h(null,1,[$CLJS.rO,$CLJS.Td],null),$CLJS.xN,new $CLJS.h(null,1,[zX,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.nj),d=$CLJS.J.g(b,$CLJS.tJ),e=$CLJS.J.g(b,$CLJS.$i);a=$CLJS.J.g(b,CX);b=$CLJS.n(e)?$CLJS.Sk.j(b,$CLJS.$i,aX):b;c=$CLJS.n(c)?$CLJS.Sk.j(b,$CLJS.nj,XW):b;d=$CLJS.n(d)?$CLJS.Sk.j(c,$CLJS.tJ,function(f){return gX.g?gX.g(f,hX):gX.call(null,f,hX)}):c;d=$CLJS.n(a)?$CLJS.xX.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[CX,Gla],null),function(f){return gX.g?gX.g(f,hX):gX.call(null,f,hX)}):d;return $CLJS.n(a)?
$CLJS.xX.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[CX,Cla],null),function(f){return gX.g?gX.g(f,hX):gX.call(null,f,hX)}):d}],null),$CLJS.sN,function(a){return null==a?null:XW(a)},$CLJS.UO,new $CLJS.h(null,1,[zX,jX],null),GX,XW],null),gX=function gX(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return gX.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
gX.l=function(a,b){b=$CLJS.I(b,0,null);var c=b instanceof $CLJS.M?new $CLJS.P(null,1,5,$CLJS.Q,[b],null):$CLJS.Df(b);b=$CLJS.y(c)?$CLJS.zA(Ola,c):null;try{return $CLJS.md(b)?b.h?b.h(a):b.call(null,a):$CLJS.yd(a)?a:$CLJS.xd(a)?$CLJS.eg.g($CLJS.N,function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),z=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);z=XW(z);z=new $CLJS.P(null,
2,5,$CLJS.Q,[z,gX.l(x,$CLJS.H([$CLJS.be.g($CLJS.Df(c),z)]))],null);u.add(z);v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}m=$CLJS.A(l);u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);u=XW(u);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[u,gX.l(m,$CLJS.H([$CLJS.be.g($CLJS.Df(c),u)]))],null),k($CLJS.Lc(l)))}return null}},null,null)}(a)}()):$CLJS.n(eX(a))?HX.h(a):$CLJS.wd(a)?$CLJS.Rk.g(function(e){return gX.l(e,$CLJS.H([$CLJS.be.g($CLJS.Df(c),zX)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.gi($CLJS.hE("Error normalizing form: {0}",$CLJS.H([$CLJS.vV(d)])),new $CLJS.h(null,3,[$CLJS.Xz,a,$CLJS.ol,c,Fla,b],null),d);}throw e;}};gX.A=1;gX.B=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};
var lX=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.ij,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.n(eX(f))?$CLJS.A(f):null},e,a,b,c,d)}();lX.m(null,$CLJS.ci,function(a){return a});
lX.m(null,$CLJS.hG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(fX($CLJS.hG,b))){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);return lX.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,c,$CLJS.Fe($CLJS.gl.l($CLJS.H([b,a])))],null))}return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,b,$CLJS.Fe(a)],null)});
lX.m(null,$CLJS.XF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.td(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XF,b],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XF,b,a],null)});lX.m(null,$CLJS.XK,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(eX(a))?lX.h(a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,a,null],null)});
lX.m(null,FX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,b,new $CLJS.h(null,1,[$CLJS.pB,a],null)],null)});lX.m(null,Hla,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=mX(b);$CLJS.I(c,0,null);b=$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=mX(a);$CLJS.I(c,0,null);a=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,a,$CLJS.R.j(c,$CLJS.HN,b)],null)});
lX.m(null,Ila,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=mX(a);return $CLJS.Se.v($CLJS.JV,a,$CLJS.R,$CLJS.H([$CLJS.wP,b]))});
lX.m(null,EX,function(a){var b=$CLJS.D(a);switch(b){case 3:$CLJS.I(a,0,null);b=$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null);a=mX(b);b=c;$CLJS.I(a,0,null);$CLJS.I(a,1,null);c=$CLJS.I(a,2,null);c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.pB);$CLJS.Va(d)||$CLJS.OS.g(d,b)?a=$CLJS.Se.v($CLJS.JV,a,$CLJS.R,$CLJS.H([$CLJS.nG,b])):(c=$CLJS.IA($CLJS.Dy),$CLJS.n($CLJS.HA("metabase.mbql.util",c))&&(b=$CLJS.hE("{0} is not a valid temporal unit for {1}; not adding to clause {2}",$CLJS.H([b,d,$CLJS.Mh.l($CLJS.H([a]))])),
b instanceof Error?$CLJS.GA("metabase.mbql.util",c,$CLJS.zw(),b):$CLJS.GA("metabase.mbql.util",c,$CLJS.zw.l($CLJS.H([b])),null)));return a;case 4:return $CLJS.I(a,0,null),b=$CLJS.I(a,1,null),$CLJS.I(a,2,null),c=$CLJS.I(a,3,null),lX.h(new $CLJS.P(null,3,5,$CLJS.Q,[EX,b,c],null));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}});
lX.m(null,$CLJS.EV,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);var e=mX(b);$CLJS.I(e,0,null);b=$CLJS.I(e,1,null);e=$CLJS.I(e,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,b,$CLJS.R.j(e,$CLJS.xR,$CLJS.gl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.CE,c],null),$CLJS.n(d)?$CLJS.Ee([c,d]):null,a])))],null)});
for(var KX=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,$CLJS.rs,$CLJS.ts],null)),LX=null,MX=0,NX=0;;)if(NX<MX){var OX=LX.X(null,NX);lX.m(null,OX,function(){return function(a){return nX(a)}}(KX,LX,MX,NX,OX));NX+=1}else{var PX=$CLJS.y(KX);if(PX){var QX=PX;if($CLJS.Ad(QX)){var RX=$CLJS.lc(QX),Pla=$CLJS.mc(QX),Qla=RX,Rla=$CLJS.D(RX);KX=Pla;LX=Qla;MX=Rla}else{var SX=$CLJS.A(QX);lX.m(null,SX,function(){return function(a){return nX(a)}}(KX,LX,MX,NX,SX,QX,PX));KX=$CLJS.B(QX);LX=null;MX=0}NX=0}else break}
lX.m(null,$CLJS.QF,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QF,mX(a),mX(b)],null),c)});lX.m(null,$CLJS.tG,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);b=$CLJS.B(b);var c=mX(a);a=$CLJS.n(bX($CLJS.hG,a))?$CLJS.JV.l(c,$CLJS.Hk,$CLJS.H([$CLJS.nG])):c;return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tG,a],null),b)});
for(var TX=$CLJS.y(new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.ZF,$CLJS.sG,$CLJS.gG,$CLJS.$F,$CLJS.Ur,$CLJS.yG,$CLJS.Rr,$CLJS.Tr,$CLJS.Nr,$CLJS.Pr,$CLJS.LF,$CLJS.SF,$CLJS.JF,$CLJS.UF,$CLJS.KF],null)),UX=null,VX=0,WX=0;;)if(WX<VX){var XX=UX.X(null,WX);lX.m(null,XX,function(){return function(a){return oX(a)}}(TX,UX,VX,WX,XX));WX+=1}else{var YX=$CLJS.y(TX);if(YX){var ZX=YX;if($CLJS.Ad(ZX)){var $X=$CLJS.lc(ZX),Sla=$CLJS.mc(ZX),Tla=$X,Ula=$CLJS.D($X);TX=Sla;UX=Tla;VX=Ula}else{var aY=$CLJS.A(ZX);lX.m(null,aY,
function(){return function(a){return oX(a)}}(TX,UX,VX,WX,aY,ZX,YX));TX=$CLJS.B(ZX);UX=null;VX=0}WX=0}else break}lX.m(null,Ela,function(){return null});lX.m(null,$CLJS.xM,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xM,lX.h(b),a],null)});
lX.m(null,BX,function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);c=lX.h;var e=$CLJS.Q;b=lX.h(b);d=$CLJS.I(d,0,null);d=$CLJS.O(d);a=!1===$CLJS.J.g(d,Dla)?new $CLJS.h(null,1,[$CLJS.T,a],null):new $CLJS.h(null,1,[$CLJS.mE,a],null);return c.call(lX,new $CLJS.P(null,3,5,e,[$CLJS.xM,b,a],null))});
for(var bY=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qx,$CLJS.iG],null)),cY=null,dY=0,eY=0;;)if(eY<dY){var fY=cY.X(null,eY);lX.m(null,fY,function(){return function(a){return pX(a)}}(bY,cY,dY,eY,fY));eY+=1}else{var gY=$CLJS.y(bY);if(gY){var hY=gY;if($CLJS.Ad(hY)){var iY=$CLJS.lc(hY),Vla=$CLJS.mc(hY),Wla=iY,Xla=$CLJS.D(iY);bY=Vla;cY=Wla;dY=Xla}else{var jY=$CLJS.A(hY);lX.m(null,jY,function(){return function(a){return pX(a)}}(bY,cY,dY,eY,jY,hY,gY));bY=$CLJS.B(hY);cY=null;dY=0}eY=0}else break}
for(var kY=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.bG,$CLJS.DG,$CLJS.NF,$CLJS.wG,$CLJS.uG,$CLJS.Zn,$CLJS.jk,$CLJS.vG,$CLJS.OF],null)),lY=null,mY=0,nY=0;;)if(nY<mY){var oY=lY.X(null,nY);lX.m(null,oY,function(){return function(a){return qX(a)}}(kY,lY,mY,nY,oY));nY+=1}else{var pY=$CLJS.y(kY);if(pY){var qY=pY;if($CLJS.Ad(qY)){var rY=$CLJS.lc(qY),Yla=$CLJS.mc(qY),Zla=rY,$la=$CLJS.D(rY);kY=Yla;lY=Zla;mY=$la}else{var sY=$CLJS.A(qY);lX.m(null,sY,function(){return function(a){return qX(a)}}(kY,lY,mY,nY,
sY,qY,pY));kY=$CLJS.B(qY);lY=null;mY=0}nY=0}else break}lX.m(null,$CLJS.TF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TF,mX(b),a],null)});
for(var tY=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oG,$CLJS.qG],null)),uY=null,vY=0,wY=0;;)if(wY<vY){var xY=uY.X(null,wY);lX.m(null,xY,function(){return function(a){return rX(a)}}(tY,uY,vY,wY,xY));wY+=1}else{var yY=$CLJS.y(tY);if(yY){var zY=yY;if($CLJS.Ad(zY)){var AY=$CLJS.lc(zY),ama=$CLJS.mc(zY),bma=AY,cma=$CLJS.D(AY);tY=ama;uY=bma;vY=cma}else{var BY=$CLJS.A(zY);lX.m(null,BY,function(){return function(a){return rX(a)}}(tY,uY,vY,wY,BY,zY,yY));tY=$CLJS.B(zY);uY=null;vY=0}wY=0}else break}
lX.m(null,$CLJS.BG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BG,lX.h(b),lX.h(a)],null)});
lX.m(null,$CLJS.RF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?$CLJS.be.g(lX.h(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RF,b],null)),gX.l(a,$CLJS.H([hX]))):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RF,$CLJS.Df(function(){return function e(d){return new $CLJS.ne(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,
2,5,$CLJS.Q,[lX.h(v),lX.h(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}k=$CLJS.A(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[lX.h(m),lX.h(k)],null),e($CLJS.Lc(f)))}return null}},null,null)}(b)}())],null)});
lX.m(null,$CLJS.AF,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AF,lX.h(a),$CLJS.E.g(0,b)?1:lX.h(b)],null),$CLJS.hf.g(lX,c))});
var iX=function iX(a){if($CLJS.yd(a))return $CLJS.Sd(function(d,e,f){return $CLJS.R.j(d,e,iX.h?iX.h(f):iX.call(null,f))},a,a);if($CLJS.xd(a))return $CLJS.zt(a,iX);if($CLJS.n(eX(a))){var c=function(){try{return lX.h(a)}catch(k){if(k instanceof Error){var d=k,e=$CLJS.IA($CLJS.By);if($CLJS.n($CLJS.HA("metabase.mbql.normalize",e))){var f=$CLJS.JE("Invalid clause:");f instanceof Error?$CLJS.GA("metabase.mbql.normalize",e,$CLJS.zw.l($CLJS.H([a])),f):$CLJS.GA("metabase.mbql.normalize",e,$CLJS.zw.l($CLJS.H([f,
a])),null)}throw $CLJS.gi($CLJS.hE("Invalid MBQL clause: {0}",$CLJS.H([$CLJS.vV(d)])),new $CLJS.h(null,1,[$CLJS.cS,a],null),d);}throw k;}}();return $CLJS.y(c)?$CLJS.eg.j($CLJS.be.g($CLJS.jd(c),$CLJS.A(c)),$CLJS.hf.h(iX),$CLJS.Lc(c)):c}return $CLJS.Dd(a)?$CLJS.Rk.g(iX,a):$CLJS.ud(a)?$CLJS.eg.j($CLJS.jd(a),$CLJS.hf.h(iX),a):a},sX=function sX(a){return function f(d,e){try{if($CLJS.n(function(){var x=ZW($CLJS.Dd);return x.h?x.h(e):x.call(null,e)}()))return f(d,$CLJS.Df(e));throw $CLJS.Y;}catch(x){if(x instanceof
Error)if(d=x,d===$CLJS.Y)try{if($CLJS.n(function(){var z=ZW($CLJS.ge);return z.h?z.h(e):z.call(null,e)}()))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[e],null)],null);throw $CLJS.Y;}catch(z){if(z instanceof Error)if(d=z,d===$CLJS.Y)try{if($CLJS.zd(e)&&2<=$CLJS.D(e))try{var k=$CLJS.Wk.j(e,0,2);if($CLJS.zd(k)&&2===$CLJS.D(k))try{var l=$CLJS.F(k,0);if($CLJS.n($CLJS.fE($CLJS.ge,$CLJS.Te(new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.Xr,null,$CLJS.it,null,$CLJS.CG,null,$CLJS.Wr,null,
BX,null],null),null)))(l)))try{var m=$CLJS.F(k,1);if($CLJS.n(IX(m)))return $CLJS.eg.j($CLJS.Cf,$CLJS.gE(sX),a);throw $CLJS.Y;}catch(C){if(C instanceof Error){var t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error)if(t=C,t===$CLJS.Y)try{if($CLJS.zd(e)&&
1<=$CLJS.D(e))try{var u=$CLJS.Wk.j(e,0,1);if($CLJS.zd(u)&&1===$CLJS.D(u))try{if($CLJS.F(u,0)instanceof $CLJS.M)return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Y;}catch(G){if(G instanceof Error){var v=G;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Y)return e;throw v;}throw G;}else throw t;else throw C;}else throw d;else throw z;
}else throw d;else throw x;}}($CLJS.Cf,a)},CY=function CY(a){return function f(d,e){try{var k=ZW($CLJS.Dd);var l=k.h?k.h(e):k.call(null,e);if($CLJS.n(l))return f(d,$CLJS.Df(e));throw $CLJS.Y;}catch(C){if(C instanceof Error)if(l=C,l===$CLJS.Y)try{if($CLJS.zd(e)&&2===$CLJS.D(e))try{var m=$CLJS.F(e,1);if($CLJS.he(m,$CLJS.eG)){var t=$CLJS.F(e,0);return f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eG,t],null))}throw $CLJS.Y;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,
$CLJS.YF))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YF,t],null));throw $CLJS.Y;}catch(K){if(K instanceof Error){var u=K;if(u===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,AX))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eG,t],null));throw $CLJS.Y;}catch(S){if(S instanceof Error)if(l=S,l===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,DX))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YF,t],null));throw $CLJS.Y;}catch(V){if(V instanceof Error)if(m=V,m===
$CLJS.Y)try{var v=$CLJS.F(e,0);if($CLJS.he(v,AX))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eG,t],null));throw $CLJS.Y;}catch(Z){if(Z instanceof Error)if(m=Z,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,DX))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YF,t],null));throw $CLJS.Y;}catch(ha){if(ha instanceof Error)if(m=ha,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,$CLJS.eG))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eG,mX(t)],null);throw $CLJS.Y;}catch(ra){if(ra instanceof
Error)if(m=ra,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,$CLJS.YF))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YF,mX(t)],null);throw $CLJS.Y;}catch(Na){if(Na instanceof Error){t=Na;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw Na;}else throw m;else throw ra;}else throw m;else throw ha;}else throw m;else throw Z;}else throw m;else throw V;}else throw l;else throw S;}else throw u;}else throw K;}else throw l;else throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Y)try{if($CLJS.zd(e)&&
0<=$CLJS.D(e))try{var x=$CLJS.Wk.j(e,0,0);if($CLJS.zd(x)&&0===$CLJS.D(x))try{var z=$CLJS.Wk.g(e,0);if($CLJS.E.g(z,a))return $CLJS.Df($CLJS.il.h($CLJS.hf.g(CY,a)));throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)return $CLJS.$W(f,d,e);throw u;}throw K;}else throw l;else throw G;
}else throw l;else throw C;}}($CLJS.Cf,a)},vX=$CLJS.Pk.g(iX,function(a){var b=wX($CLJS.XF.h(a))?$CLJS.Sk.j(a,$CLJS.XF,tla):a;b=wX($CLJS.WF.h(a))?$CLJS.Sk.j(b,$CLJS.WF,ula):b;b=wX($CLJS.RE.h(a))?$CLJS.Sk.j(b,$CLJS.RE,$CLJS.Ve($CLJS.Rk,kX)):b;b=wX($CLJS.RR.h(a))?$CLJS.Sk.j(b,$CLJS.RR,CY):b;return wX($CLJS.LQ.h(a))?$CLJS.Sk.j(b,$CLJS.LQ,wla):b}),dma=new $CLJS.h(null,3,[$CLJS.lQ,$CLJS.Td,$CLJS.EF,new $CLJS.h(null,2,[$CLJS.LQ,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.lQ);if($CLJS.n(b))return a=
$CLJS.BV(a,new $CLJS.h(null,1,[$CLJS.lQ,$CLJS.EF],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.lQ],null),a=yX.g?yX.g(a,b):yX.call(null,a,b),$CLJS.BV(a,new $CLJS.h(null,1,[$CLJS.EF,$CLJS.lQ],null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.EF],null);return yX.g?yX.g(a,b):yX.call(null,a,b)},$CLJS.GN,new $CLJS.h(null,1,[zX,function(a){var b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.EF],null);return yX.g?yX.g(a,b):yX.call(null,a,b)}],null)],null),GX,$CLJS.Td],null),yX=function yX(a){switch(arguments.length){case 1:return yX.h(arguments[0]);
case 2:return yX.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};yX.h=function(a){return yX.g(a,$CLJS.Cf)};yX.g=function(a,b){try{var c=$CLJS.y(b)?$CLJS.zA(dma,b):null;return $CLJS.md(c)?c.h?c.h(a):c.call(null,a):$CLJS.yd(a)?a:$CLJS.xd(a)?Ala(a,b):$CLJS.wd(a)?Bla(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.gi("Error removing empty clauses from form.",new $CLJS.h(null,2,[$CLJS.Xz,a,$CLJS.ol,b],null),d);throw d;}};yX.A=2;
$CLJS.DY=function(){var a=$CLJS.Pk.l(yX,zla,xla,$CLJS.H([gX]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.gi($CLJS.hE("Error normalizing query: {0}",$CLJS.H([$CLJS.vV(c)])),new $CLJS.h(null,1,[$CLJS.EF,b],null),c);}throw d;}}}();$CLJS.EY=function EY(a,b){if($CLJS.Va($CLJS.y(a)))a=(0,$CLJS.DY)(b);else{var d=$CLJS.J,e=d.g;var f=$CLJS.xt(a);b=$CLJS.Ee([$CLJS.id(a),b]);f=EY.g?EY.g(f,b):EY.call(null,f,b);a=e.call(d,f,$CLJS.id(a))}return a};