var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.literal.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.schema.macros.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var UL,VL,WL,Jha,Kha,$L,Lha,cM,dM,eM,fM,Mha,gM,hM,jM,kM,XL,lM,Nha,mM,Oha,nM,oM,pM,Pha,qM,rM,Qha,sM,Rha,tM,uM,Sha,wM,Tha,Uha,Vha,yM,zM,AM,BM,CM,DM,EM,FM,Wha,GM,HM,IM,KM,LM,Xha,MM,NM,OM,PM,Yha,QM,RM,SM,TM,Zha,UM,VM,WM,XM,$ha,YM,aia,ZM,$M,aN,bia,bN,cN,dN,eN,gN,hN,cia,iN,jN,dia,kN,lN,mN,nN,oN,eia,pN,fia,qN,rN,tN,gia,uN,vN,wN,hia,zN,AN,iia,jia,BN,DN,EN,FN,kia,IN,JN,lia,mia,nia,KN,LN,MN,NN,ON,PN,oia,QN,pia,RN,SN,UN,WN,XN,YN,ZN,qia,ria,aO,sia,tia,bO,cO,dO,eO,fO,uia,gO,via,wia,hO,iO,jO,kO,lO,
xia,yia,zia,mO,Aia,nO,Bia,pO,qO,Cia,sO,tO,uO,vO,Dia,yO,Eia,Fia,zO,AO,BO,Gia,CO,DO,EO,FO,Hia,HO,IO,JO,KO,LO,Iia,MO,NO,Jia,OO,PO,QO,RO,SO,TO,VO,Kia,WO,Lia,XO,YO,ZO,Mia,Nia,Oia,aP,bP,cP,Pia,dP,eP,fP,gP,Qia,Ria,iP,Sia,Tia,jP,kP,lP,mP,nP,Uia,oP,pP,qP,rP,Via,sP,tP,uP,vP,xP,Wia,yP,zP,Xia,AP,BP,CP,Yia,DP,Zia,FP,$ia,GP,HP,IP,JP,KP,LP,MP,aja,bja,cja,NP,dja,OP,PP,eja,fja,QP,RP,SP,gja,TP,hja,UP,ija,jja,VP,kja,lja,mja,YP,ZP,nja,$P,aQ,cQ,oja,dQ,pja,eQ,fQ,gQ,qja,rja,hQ,iQ,jQ,kQ,sja,mQ,nQ,tja,oQ,pQ,rQ,sQ,tQ,uQ,vQ,
wQ,uja,xQ,yQ,vja,zQ,wja,AQ,BQ,CQ,xja,DQ,EQ,yja,zja,FQ,GQ,HQ,Aja,Bja,IQ,Cja,Dja,Eja,KQ,Fja,MQ,Gja,Hja,NQ,OQ,Ija,Jja,Kja,PQ,QQ,RQ,SQ,TQ,UQ,VQ,Lja,WQ,XQ,Mja,YQ,Nja,Oja,Pja,ZQ,$Q,aR,Qja,bR,cR,Rja,dR,Sja,eR,Tja,Uja,gR,hR,iR,jR,kR,Vja,lR,mR,Wja,nR,oR,pR,qR,rR,sR,tR,uR,Xja,vR,wR,yR,zR,AR,BR,CR,DR,ER,FR,GR,HR,Yja,IR,JR,KR,LR,MR,Zja,NR,OR,PR,QR,$ja,SR,aka,bka,UR,cka,VR,dka,WR,eka,fka,YR,gka,ZR,aS,bS,hka,ika,fS,gS,jka,kka,hS,iS,jS,kS,lS,lka,mS,nS,pS,qS,mka;
$CLJS.TL=function(a){var b=$CLJS.yo.g(a,null),c=$CLJS.lF(b,$CLJS.Ls,function(d){return $CLJS.xn(d,$CLJS.Cf)});return function(){function d(k,l,m){return(l=$CLJS.y(c.j?c.j(k,l,m):c.call(null,k,l,m)))?new $CLJS.h(null,3,[$CLJS.pl,b,$CLJS.Ej,k,$CLJS.Rs,l],null):null}function e(k){return f.j(k,$CLJS.Cf,$CLJS.Cf)}var f=null;f=function(k,l,m){switch(arguments.length){case 1:return e.call(this,k);case 3:return d.call(this,k,l,m)}throw Error("Invalid arity: "+arguments.length);};f.h=e;f.j=d;return f}()};
UL=function(a,b){return $CLJS.xd(a)?$CLJS.J.g(a,b):a};VL=function(a,b,c,d){d=$CLJS.n(d)?d:$CLJS.An($CLJS.yo.g($CLJS.pl.h(a),null));if($CLJS.n(b)){var e=UL($CLJS.rt.h(b),c);$CLJS.n(e)?(e=$CLJS.Iq(e,d),a=e.g?e.g(a,d):e.call(null,a,d)):a=null;return $CLJS.n(a)?a:UL($CLJS.pt.h(b),c)}return null};WL=function(a,b){return $CLJS.vd(a)||$CLJS.Jl(a)?$CLJS.J.g(a,b):$CLJS.wd(a)?$CLJS.J.g($CLJS.Df(a),b):null};
Jha=function(a,b){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.pl),d=$CLJS.J.g(a,$CLJS.nj);b=$CLJS.O(b);var e=$CLJS.J.j(b,$CLJS.Rs,$CLJS.tea),f=$CLJS.J.j(b,$CLJS.oea,!0),k=$CLJS.J.g(b,$CLJS.cC),l=$CLJS.J.j(b,$CLJS.aC,$CLJS.ZB),m=VL(a,$CLJS.Oq.h(c),k,b);if($CLJS.n(m))return m;m=VL(a,$CLJS.ct.h(c),k,b);if($CLJS.n(m))return m;m=VL(a,e.h?e.h(d):e.call(null,d),k,b);if($CLJS.n(m))return m;m=VL(a,function(){var t=$CLJS.fo.h(c);return e.h?e.h(t):e.call(null,t)}(),k,b);if($CLJS.n(m))return m;m=VL(a,$CLJS.Oq.h(c),
l,b);if($CLJS.n(m))return m;m=VL(a,$CLJS.ct.h(c),l,b);if($CLJS.n(m))return m;d=VL(a,e.h?e.h(d):e.call(null,d),l,b);if($CLJS.n(d))return d;d=VL(a,function(){var t=$CLJS.fo.h(c);return e.h?e.h(t):e.call(null,t)}(),l,b);if($CLJS.n(d))return d;k=$CLJS.n(f)?VL(a,e.h?e.h($CLJS.XB):e.call(null,$CLJS.XB),k,b):f;return $CLJS.n(k)?k:$CLJS.n(f)?VL(a,e.h?e.h($CLJS.XB):e.call(null,$CLJS.XB),l,b):f};
Kha=function(a,b,c){a=$CLJS.Q;var d=$CLJS.eg.g;var e=$CLJS.ml.h(b);var f=$CLJS.O(b);var k=$CLJS.J.g(f,$CLJS.pl),l=$CLJS.O(c);f=$CLJS.J.g(l,$CLJS.cC);l=$CLJS.J.j(l,$CLJS.aC,$CLJS.ZB);k=$CLJS.Oq.h(k);f=UL($CLJS.$B.h(k),f);f=$CLJS.n(f)?f:UL($CLJS.$B.h(k),l);d=d.call($CLJS.eg,e,f);return new $CLJS.P(null,2,5,a,[d,Jha(b,c)],null)};
$CLJS.YL=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Ej);a=$CLJS.J.g(b,$CLJS.Rs);var d=$CLJS.O(null),e=$CLJS.J.j(d,$CLJS.pea,$CLJS.Qi),f=$CLJS.J.j(d,$CLJS.rea,Kha);return $CLJS.n(a)?$CLJS.eb(function(k,l){var m=f.j?f.j(b,l,d):f.call(null,b,l,d),t=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);l=$CLJS.R.j(l,$CLJS.Qi,m);l=e.h?e.h(l):e.call(null,l);return XL(k,c,t,l)},null,a):null};
$L=function(a,b,c){var d=$CLJS.zA($CLJS.q($CLJS.bE),new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null));if($CLJS.n(d))return d;c=c.o?c.o():c.call(null);$CLJS.Oh.v($CLJS.bE,$CLJS.ZL,new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null),c);return c};$CLJS.aM=function(a){return $L($CLJS.xk,a,function(){return $CLJS.mF.h(a)})};Lha=function(){var a=bM;return $L($CLJS.Ls,a,function(){var b=$CLJS.mF.h(a),c=$CLJS.TL(a);return function(d){return $CLJS.n(b.h?b.h(d):b.call(null,d))?null:c.h?c.h(d):c.call(null,d)}})};
cM=function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,$CLJS.me(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,a],null):a],null)};dM=function(a){if($CLJS.zd(a)){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);switch(b instanceof $CLJS.M?b.T:null){case "optional":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,cM(c)],null)],null);case "rest":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xr,cM(c)],null);default:return cM(a)}}else return cM(a)};
eM=function(a,b){var c=$CLJS.zd(b);return c?(c=$CLJS.A(b)instanceof $CLJS.M)?$CLJS.ud(a)?(b=$CLJS.A(b),a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.A(b)):c:c};
fM=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.pt,["not a ",$CLJS.p.h(a)," clause"].join("")],null),$CLJS.Ve(eM,a)],null),$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.is,new $CLJS.P(null,2,5,$CLJS.Q,["tag",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,a],null)],null)],null),function(){return function e(d){return new $CLJS.ne(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);
a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,2,5,$CLJS.Q,[v,dM(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}k=$CLJS.A(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[m,dM(k)],null),e($CLJS.Lc(f)))}return null}},null,null)}($CLJS.ft(2,2,b))}())],null)};Mha=function(a){return $CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M?$CLJS.A(a):null};
gM=function(a){return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.Oi,Mha,$CLJS.pt,["valid instance of one of these MBQL clauses: ",$CLJS.At(", ",$CLJS.hf.g($CLJS.A,a))].join("")],null)],null),function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,
$CLJS.me(t)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,t],null):t],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.me(f)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,f],null):f],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())};
hM=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.SL],null)],null)};$CLJS.iM={};jM={};kM={};$CLJS.ZL=function ZL(a,b,c){var e=$CLJS.y(b);b=$CLJS.A(e);var f=$CLJS.B(e);if(f){e=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=ZL.j?ZL.j(k,f,c):ZL.call(null,k,f,c);a=e.call($CLJS.R,a,b,c)}else a=$CLJS.R.j(a,b,c);return a};
XL=function XL(a,b,c,d){var f=$CLJS.y(c);c=$CLJS.A(f);var k=$CLJS.B(f),l=WL(b,c);f=$CLJS.n(a)?a:$CLJS.wd(b)?$CLJS.Cf:$CLJS.yd(b)?$CLJS.N:$CLJS.jd(b);return $CLJS.n($CLJS.n(c)?$CLJS.YB.h($CLJS.pd(f)):c)?a:$CLJS.n(c)?(b=WL(f,c),d=XL.v?XL.v(b,l,k,d):XL.call(null,b,l,k,d),$CLJS.Ol(c)&&$CLJS.wd(f)&&c>$CLJS.D(f)&&(b=$CLJS.df(c-$CLJS.D(f),null),b=$CLJS.gf.g(f,b),f=null==f||$CLJS.Dd(f)?b:$CLJS.eg.g($CLJS.jd(f),b)),null==f||$CLJS.Jl(f)?$CLJS.R.j(f,c,d):$CLJS.vd(f)?$CLJS.be.g(f,d):$CLJS.Se.g($CLJS.U,$CLJS.R.j($CLJS.Df(f),
c,d))):$CLJS.xd(a)?(c=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.qea],null),XL.v?XL.v(f,b,c,d):XL.call(null,f,b,c,d)):$CLJS.n($CLJS.YB.h($CLJS.pd(f)))?$CLJS.be.g(f,d):$CLJS.zd($CLJS.Fe(f))?f:$CLJS.od(new $CLJS.P(null,1,5,$CLJS.Q,[d],null),new $CLJS.h(null,1,[$CLJS.YB,!0],null))};lM=new $CLJS.M("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",-662824303);Nha=new $CLJS.r("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);
mM=new $CLJS.M("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",-345484364);Oha=new $CLJS.r("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);nM=new $CLJS.M("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",1412149596);oM=new $CLJS.M("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",-985876836);pM=new $CLJS.M("metabase.mbql.schema","substring","metabase.mbql.schema/substring",188871083);
Pha=new $CLJS.r("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);qM=new $CLJS.M("metabase.mbql.schema","TimeLiteral","metabase.mbql.schema/TimeLiteral",-822346498);rM=new $CLJS.M("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",549586740);Qha=new $CLJS.M(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);sM=new $CLJS.M("metabase.mbql.schema","StringExpressionArg","metabase.mbql.schema/StringExpressionArg",596241960);
Rha=new $CLJS.M(null,"from","from",1815293044);tM=new $CLJS.M("metabase.mbql.schema","and","metabase.mbql.schema/and",-86675456);uM=new $CLJS.r(null,"does-not-contain","does-not-contain",1365584674,null);Sha=new $CLJS.M(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);$CLJS.vM=new $CLJS.M(null,"snippet-name","snippet-name",819240328);wM=new $CLJS.M("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",560735368);
Tha=new $CLJS.r("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);Uha=new $CLJS.r("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);$CLJS.xM=new $CLJS.M(null,"aggregation-options","aggregation-options",-1904917550);Vha=new $CLJS.r("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);yM=new $CLJS.M("location","zip_code","location/zip_code",1641155222);
zM=new $CLJS.r(null,"ExpressionArg","ExpressionArg",1060743736,null);AM=new $CLJS.r(null,"get-hour","get-hour",-2031721710,null);BM=new $CLJS.M("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",-140332301);CM=new $CLJS.r(null,"ag:var","ag:var",-1804440286,null);DM=new $CLJS.M("metabase.mbql.schema","IntGreaterThanZeroOrNumericExpression","metabase.mbql.schema/IntGreaterThanZeroOrNumericExpression",1485789132);
EM=new $CLJS.M("metabase.mbql.schema","exp","metabase.mbql.schema/exp",-1188751039);FM=new $CLJS.M("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",-364748152);Wha=new $CLJS.M(null,"lon-max","lon-max",1590224717);GM=new $CLJS.M("metabase.mbql.schema","asc","metabase.mbql.schema/asc",-302489464);HM=new $CLJS.r(null,"stddev","stddev",775056588,null);IM=new $CLJS.M("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-1954451840);
$CLJS.JM=new $CLJS.M(null,"snippet","snippet",953581994);KM=new $CLJS.r("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);LM=new $CLJS.M(null,"string-expression","string-expression",-1395337766);Xha=new $CLJS.M(null,"lat-field","lat-field",-830652957);MM=new $CLJS.M("metabase.mbql.schema","now","metabase.mbql.schema/now",-975392912);NM=new $CLJS.M("metabase.mbql.schema","avg","metabase.mbql.schema/avg",1123586863);
OM=new $CLJS.M("metabase.mbql.schema","not","metabase.mbql.schema/not",-1753911131);PM=new $CLJS.M("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",-670335423);Yha=new $CLJS.r("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);QM=new $CLJS.M("location","country","location/country",1666636202);RM=new $CLJS.M("metabase.mbql.schema","trim","metabase.mbql.schema/trim",350521664);
SM=new $CLJS.M("metabase.mbql.schema","Reference","metabase.mbql.schema/Reference",-770092616);TM=new $CLJS.M("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",-1080110117);Zha=new $CLJS.r("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);UM=new $CLJS.M(null,"unary","unary",-989314568);VM=new $CLJS.M("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",297284410);
WM=new $CLJS.M("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",324961083);XM=new $CLJS.r(null,"ceil","ceil",-184398425,null);$ha=new $CLJS.M(null,"lon-min","lon-min",-787291357);YM=new $CLJS.r(null,"NonBlankString","NonBlankString",-719244809,null);aia=new $CLJS.M(null,"match","match",1220059550);ZM=new $CLJS.r(null,"count-where","count-where",2025939247,null);$M=new $CLJS.M("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",1844658706);
aN=new $CLJS.M(null,"max-results-bare-rows","max-results-bare-rows",2096475889);bia=new $CLJS.M(null,"embedded-question","embedded-question",-2146473954);bN=new $CLJS.M("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",1831406784);cN=new $CLJS.r(null,"sum","sum",1777518341,null);dN=new $CLJS.M("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",197727284);eN=new $CLJS.r("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);
$CLJS.fN=new $CLJS.M("date","range","date/range",1647265776);gN=new $CLJS.r(null,"between","between",-1523336493,null);hN=new $CLJS.M(null,"clause-form","clause-form",1820463737);cia=new $CLJS.r("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);iN=new $CLJS.M("metabase.mbql.schema","Query","metabase.mbql.schema/Query",1321874752);jN=new $CLJS.r(null,"field","field",338095027,null);dia=new $CLJS.M(null,"segment-id","segment-id",1810133590);
kN=new $CLJS.M("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",94927598);lN=new $CLJS.r(null,"not-null","not-null",313812992,null);mN=new $CLJS.M("metabase.mbql.schema","Joins","metabase.mbql.schema/Joins",1376278278);nN=new $CLJS.M("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",-1587636578);oN=new $CLJS.M("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",556298105);eia=new $CLJS.M(null,"template-tag","template-tag",310841038);
pN=new $CLJS.M(null,"invalid","invalid",412869516);fia=new $CLJS.r("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);qN=new $CLJS.M("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",1473793750);rN=new $CLJS.r(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.sN=new $CLJS.M(null,"context","context",-830191113);tN=new $CLJS.r(null,"get-year","get-year",704520253,null);
gia=new $CLJS.M(null,"format-rows?","format-rows?",992129486);uN=new $CLJS.M("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",336705322);vN=new $CLJS.M("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",829238608);wN=new $CLJS.M("metabase.mbql.schema","variable","metabase.mbql.schema/variable",672451083);$CLJS.xN=new $CLJS.M(null,"parameters","parameters",-1229919748);hia=new $CLJS.M(null,"json-download","json-download",-971130133);
$CLJS.yN=new $CLJS.M(null,"card","card",-1430355152);zN=new $CLJS.M(null,"string-or-field","string-or-field",-1970678542);AN=new $CLJS.r(null,"!\x3d","!\x3d",-201205829,null);iia=new $CLJS.r("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);jia=new $CLJS.r("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);BN=new $CLJS.r(null,"expression","expression",1842843403,null);$CLJS.CN=new $CLJS.M("date","month-year","date/month-year",1948031290);
DN=new $CLJS.M("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-2109835363);EN=new $CLJS.r(null,"ends-with","ends-with",1183740516,null);FN=new $CLJS.M("metabase.mbql.schema","time","metabase.mbql.schema/time",727162193);$CLJS.GN=new $CLJS.M(null,"joins","joins",1033962699);kia=new $CLJS.M(null,"slug","slug",2029314850);$CLJS.HN=new $CLJS.M(null,"source-field","source-field",933829534);IN=new $CLJS.r(null,"Field","Field",430385967,null);
JN=new $CLJS.r(null,"convert-timezone","convert-timezone",1515728526,null);lia=new $CLJS.r("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);mia=new $CLJS.M(null,"disable-max-results?","disable-max-results?",857693204);nia=new $CLJS.M(null,"items","items",1031954938);KN=new $CLJS.M(null,"datetime-expression","datetime-expression",391782195);LN=new $CLJS.M("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",1605865100);
MN=new $CLJS.M(null,"more","more",-2058821800);NN=new $CLJS.M(null,"first-clause","first-clause",-20953491);ON=new $CLJS.M("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",-671453147);PN=new $CLJS.r(null,"OrderComparable","OrderComparable",772072595,null);oia=new $CLJS.r("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);QN=new $CLJS.r(null,"contains","contains",-1977535957,null);
pia=new $CLJS.r("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);RN=new $CLJS.r("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);SN=new $CLJS.M("number","\x3d","number/\x3d",-2094581309);$CLJS.TN=new $CLJS.M(null,"widget-type","widget-type",1836256899);UN=new $CLJS.M("metabase.mbql.schema","max","metabase.mbql.schema/max",1256568155);$CLJS.VN=new $CLJS.M(null,"fingerprint","fingerprint",598613022);
WN=new $CLJS.r(null,"is-null","is-null",-356519403,null);XN=new $CLJS.M(null,"other-clauses","other-clauses",1570511021);YN=new $CLJS.M("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",-1003477115);ZN=new $CLJS.M("metabase.mbql.schema","upper","metabase.mbql.schema/upper",-646839893);qia=new $CLJS.M(null,"map-tiles","map-tiles",1961865797);$CLJS.$N=new $CLJS.M(null,"required","required",1807647006);ria=new $CLJS.M(null,"embedded-csv-download","embedded-csv-download",1858870014);
aO=new $CLJS.M(null,"unnamed-aggregation","unnamed-aggregation",-93854280);sia=new $CLJS.M(null,"datetime-x","datetime-x",1519265947);tia=new $CLJS.M(null,"datetime-y","datetime-y",-1666955771);bO=new $CLJS.r(null,"CaseClauses","CaseClauses",-1749071354,null);cO=new $CLJS.M(null,"date-arithmetics","date-arithmetics",-1832808309);dO=new $CLJS.M("string","contains","string/contains",1602423827);eO=new $CLJS.M("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",1430203983);
fO=new $CLJS.M("metabase.mbql.schema","or","metabase.mbql.schema/or",1154080514);uia=new $CLJS.r("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);gO=new $CLJS.M("string","!\x3d","string/!\x3d",-1083772573);via=new $CLJS.r(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);wia=new $CLJS.r("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);
hO=new $CLJS.M("metabase.mbql.schema","min","metabase.mbql.schema/min",1119925081);iO=new $CLJS.r("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);jO=new $CLJS.M("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",-11600894);kO=new $CLJS.M("metabase.mbql.schema","Join","metabase.mbql.schema/Join",-2100258965);lO=new $CLJS.r(null,"share","share",1051097594,null);
xia=new $CLJS.r("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);yia=new $CLJS.r("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);zia=new $CLJS.r("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);mO=new $CLJS.M("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",1830595254);Aia=new $CLJS.M(null,"xlsx-download","xlsx-download",-1622892009);
nO=new $CLJS.M("metabase.mbql.schema","contains","metabase.mbql.schema/contains",-241280695);Bia=new $CLJS.r("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);$CLJS.oO=new $CLJS.M(null,"collection","collection",-683361892);pO=new $CLJS.M("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",45949435);qO=new $CLJS.M("metabase.mbql.schema","median","metabase.mbql.schema/median",414562044);
$CLJS.rO=new $CLJS.M("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);Cia=new $CLJS.r("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);sO=new $CLJS.M("number","\x3c\x3d","number/\x3c\x3d",-1499316353);tO=new $CLJS.r(null,"metric","metric",2049329604,null);uO=new $CLJS.r(null,"concat","concat",-467652465,null);vO=new $CLJS.M(null,"variable","variable",-281346492);$CLJS.wO=new $CLJS.M("date","quarter-year","date/quarter-year",-1453950150);
Dia=new $CLJS.r(null,"TimeUnit","TimeUnit",1200517789,null);$CLJS.xO=new $CLJS.M("date","relative","date/relative",25987732);yO=new $CLJS.M("location","city","location/city",-1746973325);Eia=new $CLJS.M(null,"public-dashboard","public-dashboard",645968405);Fia=new $CLJS.r("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);zO=new $CLJS.M("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",1054422818);AO=new $CLJS.M("number","\x3e\x3d","number/\x3e\x3d",-1670691032);
BO=new $CLJS.M("number","between","number/between",97700581);Gia=new $CLJS.r("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);CO=new $CLJS.M("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",-576867936);DO=new $CLJS.r(null,"sqrt","sqrt",370479598,null);EO=new $CLJS.M("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",515738880);FO=new $CLJS.M("metabase.mbql.schema","expression","metabase.mbql.schema/expression",-1270197207);
$CLJS.GO=new $CLJS.M(null,"semantic_type","semantic_type",272485089);Hia=new $CLJS.M(null,"metric-id","metric-id",-686486942);HO=new $CLJS.r(null,"*","*",345799209,null);IO=new $CLJS.r(null,"+","+",-740910886,null);JO=new $CLJS.r(null,"-","-",-471816912,null);KO=new $CLJS.r(null,"template-tag","template-tag",1951372565,null);LO=new $CLJS.M(null,"allowed-for","allowed-for",122724334);Iia=new $CLJS.M(null,"question","question",-1411720117);MO=new $CLJS.r(null,"asc","asc",1997386096,null);
NO=new $CLJS.M("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",-391950078);Jia=new $CLJS.M(null,"pulse-id","pulse-id",1331432237);OO=new $CLJS.M("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",277590849);PO=new $CLJS.r(null,"\x3c\x3d","\x3c\x3d",1244895369,null);QO=new $CLJS.r(null,"\x3c","\x3c",993667236,null);RO=new $CLJS.r(null,"\x3e","\x3e",1085014381,null);SO=new $CLJS.r(null,"\x3d","\x3d",-1501502141,null);
TO=new $CLJS.M("metabase.mbql.schema","OrderComparable","metabase.mbql.schema/OrderComparable",1970325905);$CLJS.UO=new $CLJS.M(null,"source-metadata","source-metadata",-477816085);VO=new $CLJS.M("string","ends-with","string/ends-with",302681156);Kia=new $CLJS.r("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);WO=new $CLJS.r(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);Lia=new $CLJS.r(null,"MetricID","MetricID",-2128635641,null);
XO=new $CLJS.r(null,"and","and",668631710,null);YO=new $CLJS.r(null,"\x3e\x3d","\x3e\x3d",1016916022,null);ZO=new $CLJS.r(null,"round","round",-645002441,null);Mia=new $CLJS.M(null,"to","to",192099007);$CLJS.$O=new $CLJS.M("date","single","date/single",1554682003);Nia=new $CLJS.M(null,"action-id","action-id",-1727958578);Oia=new $CLJS.r("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);aP=new $CLJS.r(null,"exp","exp",1378825265,null);
bP=new $CLJS.r(null,"Filter","Filter",-424893332,null);cP=new $CLJS.r(null,"cum-count","cum-count",-323900016,null);Pia=new $CLJS.M(null,"dashboard-id","dashboard-id",1965414288);dP=new $CLJS.M("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-1731861963);eP=new $CLJS.M("metabase.mbql.schema","abs","metabase.mbql.schema/abs",152348622);fP=new $CLJS.M("metabase.mbql.schema","desc","metabase.mbql.schema/desc",604322345);
gP=new $CLJS.M(null,"numeric-expression","numeric-expression",-1661337235);$CLJS.hP=new $CLJS.M(null,"source-table","source-table",-225307692);Qia=new $CLJS.M(null,"embedded-dashboard","embedded-dashboard",-485078014);Ria=new $CLJS.r("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);iP=new $CLJS.r(null,"floor","floor",-772394748,null);Sia=new $CLJS.M(null,"middleware","middleware",1462115504);
Tia=new $CLJS.r("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);jP=new $CLJS.M(null,"requires-features","requires-features",-101116256);kP=new $CLJS.M("metabase.mbql.schema","power","metabase.mbql.schema/power",2136530448);lP=new $CLJS.M(null,"clause-name","clause-name",-996419059);mP=new $CLJS.M("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-1905561955);nP=new $CLJS.r(null,"now","now",-9994004,null);
Uia=new $CLJS.r("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);oP=new $CLJS.r(null,"not","not",1044554643,null);pP=new $CLJS.r(null,"avg","avg",1837937727,null);qP=new $CLJS.r(null,"sum-where","sum-where",-519087341,null);rP=new $CLJS.M(null,"max-results","max-results",-32858165);Via=new $CLJS.r("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);sP=new $CLJS.r(null,"case","case",-1510733573,null);
tP=new $CLJS.r(null,"distinct","distinct",-148347594,null);uP=new $CLJS.r(null,"get-second","get-second",-425414791,null);vP=new $CLJS.M("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",537143190);$CLJS.wP=new $CLJS.M(null,"join-alias","join-alias",1454206794);xP=new $CLJS.r(null,"is-empty","is-empty",600228619,null);Wia=new $CLJS.r("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
yP=new $CLJS.r(null,"relative-datetime","relative-datetime",-1369266491,null);zP=new $CLJS.M("number","!\x3d","number/!\x3d",-673025509);Xia=new $CLJS.M(null,"original","original",-445386197);AP=new $CLJS.M("metabase.mbql.schema","interval","metabase.mbql.schema/interval",-1491935720);BP=new $CLJS.r(null,"abs","abs",1394505050,null);CP=new $CLJS.r(null,"datetime-diff","datetime-diff",-1521323614,null);Yia=new $CLJS.r("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);
DP=new $CLJS.M("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",-1044004696);Zia=new $CLJS.r(null,"DatetimeDiffUnit","DatetimeDiffUnit",-1310225671,null);$CLJS.EP=new $CLJS.M(null,"date","date",-1463434462);FP=new $CLJS.M(null,"second-clause","second-clause",-461435645);$ia=new $CLJS.r("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);GP=new $CLJS.r(null,"rtrim","rtrim",979195078,null);
HP=new $CLJS.M("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",1829772896);IP=new $CLJS.M("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",-1089141753);JP=new $CLJS.r("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);KP=new $CLJS.M("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",1210108656);LP=new $CLJS.r(null,"CaseOptions","CaseOptions",1989286806,null);MP=new $CLJS.r(null,"or","or",1876275696,null);
aja=new $CLJS.r("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);bja=new $CLJS.M(null,"constraints","constraints",422775616);cja=new $CLJS.r("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);NP=new $CLJS.M("metabase.mbql.schema","Field","metabase.mbql.schema/Field",-551724627);dja=new $CLJS.M(null,"csv-download","csv-download",2141432084);OP=new $CLJS.M("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",367107562);
PP=new $CLJS.r(null,"datetime-add","datetime-add",1850134938,null);eja=new $CLJS.r("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);fja=new $CLJS.r("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);QP=new $CLJS.r("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);RP=new $CLJS.r("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);
SP=new $CLJS.r("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);gja=new $CLJS.r("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);TP=new $CLJS.r(null,"get-quarter","get-quarter",-1326657176,null);hja=new $CLJS.r("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);UP=new $CLJS.r("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);ija=new $CLJS.r("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);
jja=new $CLJS.r("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);VP=new $CLJS.r(null,"temporal-extract","temporal-extract",-872749364,null);kja=new $CLJS.r("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);lja=new $CLJS.r("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);mja=new $CLJS.M(null,"display_name","display_name",-1494335013);$CLJS.WP=new $CLJS.M(null,"snippet-id","snippet-id",1987785841);
$CLJS.XP=new $CLJS.M(null,"database","database",1849087575);YP=new $CLJS.r(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);ZP=new $CLJS.r(null,"get-day-of-week","get-day-of-week",-644295017,null);nja=new $CLJS.r("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);$P=new $CLJS.M("metabase.mbql.schema","floor","metabase.mbql.schema/floor",-1217604600);
aQ=new $CLJS.M("metabase.mbql.schema","NumericExpressionArgOrInterval","metabase.mbql.schema/NumericExpressionArgOrInterval",-596692841);$CLJS.bQ=new $CLJS.M(null,"expressions","expressions",255689909);cQ=new $CLJS.M("metabase.mbql.schema","ExpressionArg","metabase.mbql.schema/ExpressionArg",372701340);oja=new $CLJS.M(null,"ad-hoc","ad-hoc",-2033634036);dQ=new $CLJS.M("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",912159158);
pja=new $CLJS.r("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);eQ=new $CLJS.M("metabase.mbql.schema","aggregation-options","metabase.mbql.schema/aggregation-options",-936374135);fQ=new $CLJS.M("metabase.mbql.schema","log","metabase.mbql.schema/log",1754975861);gQ=new $CLJS.r(null,"absolute-datetime","absolute-datetime",1080191062,null);qja=new $CLJS.r(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);rja=new $CLJS.M(null,"action","action",-811238024);
hQ=new $CLJS.r(null,"get-day","get-day",1768100384,null);iQ=new $CLJS.M("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",323823775);jQ=new $CLJS.M("metabase.mbql.schema","between","metabase.mbql.schema/between",-1943504157);kQ=new $CLJS.r(null,"not-empty","not-empty",2029453590,null);$CLJS.lQ=new $CLJS.M(null,"native","native",-613060878);sja=new $CLJS.r("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);mQ=new $CLJS.M(null,"page","page",849072397);
nQ=new $CLJS.r(null,"length","length",-2065447907,null);tja=new $CLJS.M(null,"dashboard","dashboard",-631747508);oQ=new $CLJS.r(null,"get-week","get-week",752472178,null);pQ=new $CLJS.r(null,"get-month","get-month",1271156796,null);rQ=new $CLJS.r(null,"dimension","dimension",-2111181571,null);sQ=new $CLJS.M(null,"boolean-expression","boolean-expression",-394924008);tQ=new $CLJS.M("metabase.mbql.schema","length","metabase.mbql.schema/length",1810214269);
uQ=new $CLJS.r(null,"StringFilterOptions","StringFilterOptions",-847398229,null);vQ=new $CLJS.M("metabase.mbql.schema","segment","metabase.mbql.schema/segment",2142314297);wQ=new $CLJS.r("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);uja=new $CLJS.r("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);xQ=new $CLJS.M("metabase.mbql.schema","case","metabase.mbql.schema/case",459951449);yQ=new $CLJS.r(null,"substring","substring",-1513569493,null);
vja=new $CLJS.r("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);zQ=new $CLJS.M(null,"internal","internal",-854870097);wja=new $CLJS.r("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);AQ=new $CLJS.M("metabase.mbql.schema","DateTimeExpressionArg","metabase.mbql.schema/DateTimeExpressionArg",1033762257);BQ=new $CLJS.M("metabase.mbql.schema","TemplateTag","metabase.mbql.schema/TemplateTag",-1837740311);
CQ=new $CLJS.M("metabase.mbql.schema","EqualityComparable","metabase.mbql.schema/EqualityComparable",-72675242);xja=new $CLJS.r("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);DQ=new $CLJS.M(null,"more-values-or-fields","more-values-or-fields",-886177554);EQ=new $CLJS.r(null,"ltrim","ltrim",-1000166486,null);yja=new $CLJS.r("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);
zja=new $CLJS.r("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);FQ=new $CLJS.M("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",1214423543);GQ=new $CLJS.M("metabase.mbql.schema","template-tag","metabase.mbql.schema/template-tag",1296341515);HQ=new $CLJS.r("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);
Aja=new $CLJS.r("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);Bja=new $CLJS.r("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);IQ=new $CLJS.r(null,"desc","desc",-560950005,null);$CLJS.JQ=new $CLJS.M(null,"template-tags","template-tags",1853115685);Cja=new $CLJS.M(null,"public-question","public-question",629369976);Dja=new $CLJS.r("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);
Eja=new $CLJS.M(null,"binary","binary",-1802232288);KQ=new $CLJS.r(null,"time-interval","time-interval",-1949813754,null);Fja=new $CLJS.M(null,"userland-query?","userland-query?",-123699383);$CLJS.LQ=new $CLJS.M(null,"source-query","source-query",198004422);MQ=new $CLJS.M("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-1932527842);Gja=new $CLJS.r(null,"SegmentID","SegmentID",-1773652704,null);
Hja=new $CLJS.r("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);NQ=new $CLJS.M("metabase.mbql.schema","UnnamedAggregation","metabase.mbql.schema/UnnamedAggregation",-196705529);OQ=new $CLJS.M("metabase.mbql.schema","share","metabase.mbql.schema/share",337132634);Ija=new $CLJS.M(null,"executed-by","executed-by",-739811161);Jja=new $CLJS.r("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",977707224,null);
Kja=new $CLJS.r("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);PQ=new $CLJS.M(null,"amount","amount",364489504);QQ=new $CLJS.r(null,"percentile","percentile",1039342775,null);RQ=new $CLJS.M("metabase.mbql.schema","metric","metabase.mbql.schema/metric",-610527242);SQ=new $CLJS.r(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);TQ=new $CLJS.M("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",1249807143);
UQ=new $CLJS.r(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);VQ=new $CLJS.r(null,"trim","trim",-1880116002,null);Lja=new $CLJS.r(null,"RawTimeLiteral","RawTimeLiteral",-2137224789,null);WQ=new $CLJS.r(null,"coalesce","coalesce",-1999813740,null);XQ=new $CLJS.M("metabase.mbql.schema","sum","metabase.mbql.schema/sum",1064545669);Mja=new $CLJS.M("metabase.mbql.schema","DateTimeValue","metabase.mbql.schema/DateTimeValue",1983295553);
YQ=new $CLJS.M("string","\x3d","string/\x3d",983744235);Nja=new $CLJS.M(null,"skip-results-metadata?","skip-results-metadata?",251010463);Oja=new $CLJS.r("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);Pja=new $CLJS.r("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);ZQ=new $CLJS.r(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);$Q=new $CLJS.M(null,"value-or-field","value-or-field",-1387286309);
aR=new $CLJS.r(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);Qja=new $CLJS.M(null,"lat-min","lat-min",1630784161);bR=new $CLJS.r(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);cR=new $CLJS.r(null,"inside","inside",-681932758,null);Rja=new $CLJS.r("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);dR=new $CLJS.M("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",1794540739);
Sja=new $CLJS.r("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);eR=new $CLJS.r(null,"starts-with","starts-with",2007034536,null);Tja=new $CLJS.M(null,"process-viz-settings?","process-viz-settings?",-173592315);Uja=new $CLJS.r("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);$CLJS.fR=new $CLJS.M(null,"card-id","card-id",-1770060179);gR=new $CLJS.M(null,"variadic","variadic",882626057);
hR=new $CLJS.r(null,"upper","upper",1886775433,null);iR=new $CLJS.M(null,"field-or-expression","field-or-expression",-1409494368);jR=new $CLJS.M("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",746170880);kR=new $CLJS.r(null,"optional","optional",-600484260,null);Vja=new $CLJS.r("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);lR=new $CLJS.M("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-2115604229);
mR=new $CLJS.M(null,"sugar","sugar",-73788488);Wja=new $CLJS.M(null,"lat-max","lat-max",841568226);nR=new $CLJS.r(null,"power","power",702679448,null);oR=new $CLJS.r("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);pR=new $CLJS.M("metabase.mbql.schema","replace","metabase.mbql.schema/replace",-371168701);qR=new $CLJS.M("metabase.mbql.schema","NumericExpressionArg","metabase.mbql.schema/NumericExpressionArg",-1965874261);
rR=new $CLJS.M("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",1872461942);sR=new $CLJS.r(null,"median","median",-2084869638,null);tR=new $CLJS.M("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",1268586893);uR=new $CLJS.r(null,"cum-sum","cum-sum",-589533420,null);Xja=new $CLJS.r("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);vR=new $CLJS.M(null,"y","y",-1757859776);wR=new $CLJS.r(null,"aggregation","aggregation",-1056959073,null);
$CLJS.xR=new $CLJS.M(null,"binning","binning",1709835866);yR=new $CLJS.r(null,"TimezoneId","TimezoneId",-1406242397,null);zR=new $CLJS.M("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",1034573295);AR=new $CLJS.M("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",1597044182);BR=new $CLJS.M("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",209553857);CR=new $CLJS.r(null,"Reference","Reference",2024574086,null);DR=new $CLJS.M(null,"b","b",1482224470);
ER=new $CLJS.M(null,"a","a",-2123407586);FR=new $CLJS.M("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",1846805017);GR=new $CLJS.r(null,"replace","replace",853943757,null);HR=new $CLJS.M("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",576093708);Yja=new $CLJS.r("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);IR=new $CLJS.M("metabase.mbql.schema","/","metabase.mbql.schema//",363603269);
JR=new $CLJS.M("metabase.mbql.schema","-","metabase.mbql.schema/-",1213831346);KR=new $CLJS.M("metabase.mbql.schema","*","metabase.mbql.schema/*",202015001);LR=new $CLJS.M("metabase.mbql.schema","+","metabase.mbql.schema/+",852320058);MR=new $CLJS.r(null,"segment","segment",675610331,null);Zja=new $CLJS.r("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);NR=new $CLJS.M("metabase.mbql.schema","field","metabase.mbql.schema/field",-73678671);
OR=new $CLJS.r("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);PR=new $CLJS.M("metabase.mbql.schema","count","metabase.mbql.schema/count",-962464844);QR=new $CLJS.M("string","starts-with","string/starts-with",1266861170);$ja=new $CLJS.r(null,"TemporalExtractUnit","TemporalExtractUnit",1691102108,null);$CLJS.RR=new $CLJS.M(null,"order-by","order-by",1527318070);SR=new $CLJS.M(null,"advanced-math-expressions","advanced-math-expressions",1076581757);
aka=new $CLJS.r("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);bka=new $CLJS.M("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);$CLJS.TR=new $CLJS.M(null,"condition","condition",1668437652);UR=new $CLJS.M("string","does-not-contain","string/does-not-contain",-1536178964);cka=new $CLJS.M(null,"report-timezone","report-timezone",-2053796389);VR=new $CLJS.r("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);
dka=new $CLJS.M(null,"card-name","card-name",-2035606807);WR=new $CLJS.M("metabase.mbql.schema","value","metabase.mbql.schema/value",772933314);eka=new $CLJS.r("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);fka=new $CLJS.r("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);YR=new $CLJS.r(null,"log","log",45015523,null);gka=new $CLJS.r("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);
ZR=new $CLJS.r(null,"time","time",-1268547887,null);$CLJS.$R=new $CLJS.M(null,"database_type","database_type",-54700895);aS=new $CLJS.r(null,"EqualityComparable","EqualityComparable",-2076258858,null);bS=new $CLJS.r(null,"get-minute","get-minute",-1186349426,null);$CLJS.cS=new $CLJS.M(null,"clause","clause",1479668060);$CLJS.dS=new $CLJS.M(null,"expression-name","expression-name",-1798450709);$CLJS.eS=new $CLJS.M("date","all-options","date/all-options",549325958);
hka=new $CLJS.r("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);ika=new $CLJS.M(null,"embedded-json-download","embedded-json-download",-1980927033);fS=new $CLJS.M("location","state","location/state",-114378652);gS=new $CLJS.r(null,"datetime-subtract","datetime-subtract",1915839224,null);jka=new $CLJS.M(null,"aggregation-clause-index","aggregation-clause-index",-1510292261);kka=new $CLJS.M(null,"lon-field","lon-field",517872067);
hS=new $CLJS.M("metabase.mbql.schema","TemporalLiteral","metabase.mbql.schema/TemporalLiteral",-904081026);iS=new $CLJS.r(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);jS=new $CLJS.M(null,"numeric","numeric",-1495594714);kS=new $CLJS.r(null,"variable","variable",1359185035,null);lS=new $CLJS.r(null,"lower","lower",-1534114948,null);lka=new $CLJS.M(null,"embedded-xlsx-download","embedded-xlsx-download",-1105087722);
mS=new $CLJS.M("metabase.mbql.schema","concat","metabase.mbql.schema/concat",2073716991);nS=new $CLJS.M("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",-204212346);$CLJS.oS=new $CLJS.M(null,"limit","limit",-1355822363);pS=new $CLJS.M("metabase.mbql.schema","round","metabase.mbql.schema/round",-1325713739);qS=new $CLJS.r(null,"ExtractWeekMode","ExtractWeekMode",397218079,null);mka=new $CLJS.M(null,"pulse","pulse",-244494476);var rS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rE],null),sS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.AE],null),tS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nE],null),uS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jE],null),vS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vE],null),wS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hL],null),nka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YK],null),oka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.SK],null),xS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.HI],null),yS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.LI],null),zS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.CI],null),AS=new $CLJS.ah(null,new $CLJS.h(null,12,[$CLJS.pi,null,$CLJS.Yt,null,$CLJS.pj,null,$CLJS.ci,null,$CLJS.Gi,null,$CLJS.yj,null,$CLJS.mk,null,$CLJS.Gj,null,$CLJS.ji,null,$CLJS.Fj,null,$CLJS.qi,null,$CLJS.Ai,null],null),null),BS=new $CLJS.ah(null,new $CLJS.h(null,7,[$CLJS.qk,null,$CLJS.UG,null,$CLJS.ci,null,$CLJS.wi,null,$CLJS.XG,null,$CLJS.jj,null,$CLJS.Ui,null],null),null),
CS=$CLJS.Zt.g(AS,BS),pka=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.pt,"date bucketing unit"],null)],null),AS),qka=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.pt,"time bucketing unit"],null)],null),BS),DS=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.pt,"datetime bucketing unit"],null)],null),CS),ES=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lJ],null),rka=new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,
1,[$CLJS.pt,"temporal extract unit"],null),$CLJS.YG,$CLJS.Ai,$CLJS.mk,$CLJS.XI,$CLJS.rJ,$CLJS.sJ,$CLJS.Gj,$CLJS.Gi,$CLJS.wi,$CLJS.jj,$CLJS.VG],null),ska=new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.pt,"datetime-diff unit"],null),$CLJS.UG,$CLJS.Ui,$CLJS.qk,$CLJS.Yt,$CLJS.pj,$CLJS.yj,$CLJS.pi,$CLJS.ji],null),FS=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.pt,"temporal-extract week extraction mode"],null),$CLJS.RI,$CLJS.$I,$CLJS.eJ],null),GS=new $CLJS.P(null,
10,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.pt,"relative-datetime unit"],null),$CLJS.ci,$CLJS.Ui,$CLJS.qk,$CLJS.Yt,$CLJS.pj,$CLJS.yj,$CLJS.pi,$CLJS.ji],null),NS,uka,VS,WS,XS,YS,ZS,$S,aT,MU,wka,NU,xka,yka,OU,zka,Aka,Bka;$CLJS.X(WM,fM($CLJS.aJ,$CLJS.H(["n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.Sv],null),$CLJS.Cs],null),"unit",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,GS],null)])));var HS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WM],null);
$CLJS.X(AP,fM($CLJS.ZI,$CLJS.H(["n",$CLJS.Cs,"unit",GS])));var tka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AP],null);
$CLJS.X(qN,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.pt,"valid :absolute-datetime clause",$CLJS.Oi,function(a){if($CLJS.Va(eM($CLJS.VI,a)))a=pN;else{a=$CLJS.hd(a);var b=$CLJS.aM(xS);a=b.h?b.h(a):b.call(null,a);a=$CLJS.n(a)?$CLJS.EP:$CLJS.bJ}return a}],null),new $CLJS.P(null,2,5,$CLJS.Q,[pN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.pt,"not an :absolute-datetime clause"],null),$CLJS.Ue(!1)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EP,fM($CLJS.VI,
$CLJS.H(["date",xS,"unit",pka]))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bJ,fM($CLJS.VI,$CLJS.H(["datetime",yS,"unit",DS]))],null)],null));var IS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qN],null);$CLJS.X(FN,fM($CLJS.Ey,$CLJS.H(["time",zS,"unit",qka])));var JS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FN],null),KS=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.pt,"date or datetime literal"],null),IS,yS,xS],null);
$CLJS.X(qM,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.pt,"time literal"],null),JS,zS],null));$CLJS.X(hS,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.pt,"temporal literal"],null),KS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qM],null)],null));var LS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hS],null);
$CLJS.X(Mja,gM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof IS?new $CLJS.Gc(function(){return IS},$CLJS.od(lia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,zQ,$CLJS.oj,$CLJS.yk,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),gQ,"metabase/mbql/schema.cljc",69,$CLJS.VI,1,!0,168,168,$CLJS.Kc,"Schema for an `:absolute-datetime` clause.",$CLJS.n(IS)?IS.H:null])):null));return $CLJS.n(a)?a:gQ}(),IS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof HS?new $CLJS.Gc(function(){return HS},$CLJS.od(hka,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,
$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yP,"metabase/mbql/schema.cljc",29,$CLJS.aJ,1,131,131,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.aJ,$CLJS.rk,$CLJS.U($CLJS.Qj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.Sv],null),$CLJS.Cs],null)),$CLJS.si,$CLJS.U($CLJS.Qj,$CLJS.U(kR,WO))],null),$CLJS.Kc,"Schema for a valid relative-datetime clause.",
$CLJS.n(HS)?HS.H:null])):null));return $CLJS.n(a)?a:yP}(),HS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof JS?new $CLJS.Gc(function(){return JS},$CLJS.od(jja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,zQ,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),ZR,"metabase/mbql/schema.cljc",27,$CLJS.Ey,1,!0,175,175,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ey,$CLJS.Ey,$CLJS.U($CLJS.Qj,Lja),$CLJS.si,$CLJS.U($CLJS.Qj,Dia)],null),$CLJS.Kc,"Schema for a valid time clause.",$CLJS.n(JS)?JS.H:null])):null));return $CLJS.n(a)?a:ZR}(),JS],null)])));
$CLJS.X(WR,fM($CLJS.Ej,$CLJS.H(["value",$CLJS.li,"type-info",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$R,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,rS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eD,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,sS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GO,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.qs,tS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.si,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,DS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,rS],null)],null)],null)],null)])));var MS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WR],null);$CLJS.X(FO,fM($CLJS.mB,$CLJS.H(["expression-name",rS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,$CLJS.ik],null)])));
NS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FO],null);
uka=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.pt,"You must specify :num-bins when using the :num-bins strategy."],null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.CE);c=$CLJS.J.g(c,$CLJS.kE);return $CLJS.E.g(b,$CLJS.kE)?c:!0}],null)],null)}(function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.pt,"You must specify :bin-width when using the :bin-width strategy."],
null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.CE);c=$CLJS.J.g(c,$CLJS.BE);return $CLJS.E.g(b,$CLJS.BE)?c:!0}],null)],null)}(new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.pt,"binning options"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CE,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.pt,"binning strategy"],null),$CLJS.kE,$CLJS.BE,$CLJS.ci],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kE,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),uS],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.BE,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,$CLJS.Bl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.pt,"bin width must be \x3e\x3d 0."],null),$CLJS.Te($CLJS.Wl)],null)],null)],null)],null)));$CLJS.OS=function OS(a){switch(arguments.length){case 1:return OS.h(arguments[0]);case 2:return OS.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.OS.h=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.pB);b=$CLJS.J.g(b,$CLJS.nG);return $CLJS.OS.g(a,b)};$CLJS.OS.g=function(a,b){a=$CLJS.n($CLJS.n(b)?a:b)?$CLJS.rC(a,$CLJS.SC)?AS:$CLJS.rC(a,$CLJS.RD)?BS:$CLJS.rC(a,$CLJS.rD)?CS:null:null;return $CLJS.n(a)?$CLJS.Id(a,b):!0};$CLJS.OS.A=2;
$CLJS.X(lR,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.pt,"field options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pB,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,sS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HN,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,wS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nG,new $CLJS.h(null,
1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,DS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wP,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,rS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xR,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,uka],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.pt,"Invalid :temporal-unit for the specified :base-type."],null),$CLJS.OS],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.pt,"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."],null),$CLJS.Te($CLJS.CE)],null)],null));
$CLJS.X(NR,function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.pt,":field clauses using a string field name must specify :base-type."],null),function(b){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.pB);return"string"===typeof c?b:!0}],null)],null)}(fM($CLJS.hG,$CLJS.H(["id-or-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,wS,rS],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lR],null)],null)]))));var PS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NR],null);
$CLJS.X(NP,gM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof NS?new $CLJS.Gc(function(){return NS},$CLJS.od(RN,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.bQ,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),BN,"metabase/mbql/schema.cljc",60,$CLJS.mB,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.mB,$CLJS.dS,$CLJS.U($CLJS.Qj,YM),$CLJS.gj,$CLJS.U($CLJS.Qj,$CLJS.U(kR,$CLJS.ik))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(NS)?NS.H:null])):null));return $CLJS.n(a)?a:BN}(),NS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof PS?new $CLJS.Gc(function(){return PS},
$CLJS.od(iO,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.pr,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,$CLJS.ui,$CLJS.kk,$CLJS.ok],["0.39.0",$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jN,"metabase/mbql/schema.cljc",51,$CLJS.hG,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(PS)?PS.H:null])):null));return $CLJS.n(a)?a:jN}(),PS],null)])));
$CLJS.QS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NP],null);$CLJS.X(lM,fM($CLJS.XF,$CLJS.H(["aggregation-clause-index",$CLJS.Cs,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,$CLJS.ik],null)])));var RS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lM],null);
$CLJS.X(SM,gM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof RS?new $CLJS.Gc(function(){return RS},$CLJS.od(Jja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),wR,"metabase/mbql/schema.cljc",23,$CLJS.XF,1,418,418,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.XF,jka,$CLJS.U($CLJS.Qj,$CLJS.Cs),$CLJS.gj,$CLJS.U($CLJS.Qj,$CLJS.U(kR,$CLJS.ik))],null),$CLJS.Kc,"Schema for a valid aggregation clause.",$CLJS.n(RS)?RS.H:null])):null));return $CLJS.n(a)?a:wR}(),RS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof NS?new $CLJS.Gc(function(){return NS},
$CLJS.od(RN,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.bQ,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BN,"metabase/mbql/schema.cljc",60,$CLJS.mB,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.mB,$CLJS.dS,$CLJS.U($CLJS.Qj,YM),
$CLJS.gj,$CLJS.U($CLJS.Qj,$CLJS.U(kR,$CLJS.ik))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(NS)?NS.H:null])):null));return $CLJS.n(a)?a:BN}(),NS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof PS?new $CLJS.Gc(function(){return PS},$CLJS.od(iO,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.pr,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,
$CLJS.ui,$CLJS.kk,$CLJS.ok],["0.39.0",$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jN,"metabase/mbql/schema.cljc",51,$CLJS.hG,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(PS)?PS.H:null])):null));return $CLJS.n(a)?a:jN}(),PS],null)])));
var SS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SM],null),TS=new $CLJS.ah(null,new $CLJS.h(null,11,[$CLJS.Ct,null,$CLJS.zG,null,$CLJS.AF,null,$CLJS.FG,null,$CLJS.HF,null,$CLJS.kG,null,$CLJS.RF,null,$CLJS.It,null,$CLJS.xG,null,$CLJS.jG,null,$CLJS.GG,null],null),null),US=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PM],null);
$CLJS.X(sM,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.Oi,function(a){return"string"===typeof a?$CLJS.Zj:$CLJS.n(eM(TS,a))?LM:$CLJS.n(eM($CLJS.Ej,a))?$CLJS.Ej:$CLJS.Et}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zj,$CLJS.Zj],null),new $CLJS.P(null,2,5,$CLJS.Q,[LM,US],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,MS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Et,$CLJS.QS],null)],null));VS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sM],null);
WS=new $CLJS.ah(null,new $CLJS.h(null,26,[$CLJS.DF,null,$CLJS.qJ,null,$CLJS.Xr,null,$CLJS.mJ,null,$CLJS.jJ,null,$CLJS.VF,null,$CLJS.it,null,$CLJS.zF,null,$CLJS.nJ,null,$CLJS.gJ,null,$CLJS.kG,null,$CLJS.CG,null,$CLJS.kJ,null,$CLJS.mG,null,$CLJS.FF,null,$CLJS.TI,null,$CLJS.Wr,null,$CLJS.IF,null,$CLJS.RF,null,$CLJS.NI,null,$CLJS.Ox,null,$CLJS.UI,null,$CLJS.fJ,null,$CLJS.YI,null,$CLJS.EG,null,$CLJS.fG,null],null),null);
XS=new $CLJS.ah(null,new $CLJS.h(null,9,[$CLJS.Tr,null,$CLJS.Nr,null,$CLJS.rs,null,$CLJS.ts,null,$CLJS.Pr,null,$CLJS.yG,null,$CLJS.xs,null,$CLJS.Ur,null,$CLJS.Rr,null],null),null);YS=new $CLJS.ah(null,new $CLJS.h(null,17,[$CLJS.Zn,null,$CLJS.wG,null,$CLJS.qG,null,$CLJS.iG,null,$CLJS.BG,null,$CLJS.DG,null,$CLJS.NF,null,$CLJS.TF,null,$CLJS.xM,null,$CLJS.OF,null,$CLJS.vG,null,$CLJS.oG,null,$CLJS.jk,null,$CLJS.Qx,null,$CLJS.bG,null,$CLJS.cG,null,$CLJS.uG,null],null),null);
ZS=new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.cJ,null,$CLJS.PI,null,$CLJS.oJ,null,$CLJS.Wr,null,$CLJS.iJ,null],null),null);$S=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dR],null);aT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DN],null);$CLJS.bT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FQ],null);$CLJS.cT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jR],null);
$CLJS.X(qR,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.pt,"numeric expression argument",$CLJS.Oi,function(a){return"number"===typeof a?$CLJS.Rt:$CLJS.n(eM(WS,a))?gP:$CLJS.n(eM(YS,a))?$CLJS.XF:$CLJS.n(eM($CLJS.Ej,a))?$CLJS.Ej:$CLJS.hG}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rt,$CLJS.Bl],null),new $CLJS.P(null,2,5,$CLJS.Q,[gP,$S],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XF,$CLJS.cT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,MS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hG,
$CLJS.QS],null)],null));var dT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qR],null);
$CLJS.X(AQ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.pt,"datetime expression argument",$CLJS.Oi,function(a){return $CLJS.n(eM(YS,a))?$CLJS.XF:$CLJS.n(eM($CLJS.Ej,a))?$CLJS.Ej:$CLJS.n(eM(ZS,a))?KN:$CLJS.Et}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XF,$CLJS.cT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,MS],null),new $CLJS.P(null,2,5,$CLJS.Q,[KN,$CLJS.bT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Et,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,KS,$CLJS.QS],null)],null)],null));
var eT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AQ],null);
$CLJS.X(cQ,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.pt,"expression argument",$CLJS.Oi,function(a){return"number"===typeof a?$CLJS.Rt:$CLJS.Cd(a)?$CLJS.Gs:$CLJS.n(eM(XS,a))?sQ:$CLJS.n(eM(WS,a))?gP:$CLJS.n(eM(ZS,a))?KN:"string"===typeof a?$CLJS.Zj:$CLJS.n(eM(TS,a))?LM:$CLJS.n(eM($CLJS.Ej,a))?$CLJS.Ej:$CLJS.Et}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rt,$CLJS.Bl],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.Gs],null),new $CLJS.P(null,2,5,$CLJS.Q,[sQ,aT],null),new $CLJS.P(null,
2,5,$CLJS.Q,[gP,$S],null),new $CLJS.P(null,2,5,$CLJS.Q,[KN,$CLJS.bT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zj,$CLJS.Zj],null),new $CLJS.P(null,2,5,$CLJS.Q,[LM,US],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,MS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Et,$CLJS.QS],null)],null));var fT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cQ],null);$CLJS.X(aQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.pt,"numeric expression arg or interval"],null),tka,dT],null));
var gT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aQ],null);$CLJS.X(DM,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.pt,"int greater than zero or numeric expression",$CLJS.Oi,function(a){return"number"===typeof a?$CLJS.Rt:$CLJS.Et}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rt,uS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Et,$S],null)],null));var vka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DM],null);
$CLJS.X(LN,fM($CLJS.kG,$CLJS.H(["a",fT,"b",fT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,fT],null)])));var hT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LN],null);$CLJS.X(pM,fM($CLJS.AF,$CLJS.H(["s",VS,"start",vka,"length",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,dT],null)])));var iT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pM],null);$CLJS.X(tQ,fM($CLJS.Ox,$CLJS.H(["s",VS])));var jT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tQ],null);$CLJS.X(RM,fM($CLJS.xG,$CLJS.H(["s",VS])));
var kT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RM],null);$CLJS.X(VM,fM($CLJS.GG,$CLJS.H(["s",VS])));var lT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VM],null);$CLJS.X(nN,fM($CLJS.zG,$CLJS.H(["s",VS])));var mT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nN],null);$CLJS.X(ZN,fM($CLJS.Ct,$CLJS.H(["s",VS])));var nT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZN],null);$CLJS.X(IM,fM($CLJS.It,$CLJS.H(["s",VS])));var oT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IM],null);
$CLJS.X(pR,fM($CLJS.FG,$CLJS.H(["s",VS,"match",$CLJS.Zj,"replacement",$CLJS.Zj])));var pT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pR],null);$CLJS.X(mS,fM($CLJS.HF,$CLJS.H(["a",VS,"b",VS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,VS],null)])));var qT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mS],null);$CLJS.X(OO,fM($CLJS.jG,$CLJS.H(["s",VS,"pattern",$CLJS.Zj])));var rT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OO],null);
$CLJS.X(LR,fM($CLJS.Wr,$CLJS.H(["x",gT,"y",gT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,gT],null)])));var sT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LR],null);$CLJS.X(JR,fM($CLJS.it,$CLJS.H(["x",dT,"y",gT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,gT],null)])));var tT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JR],null);$CLJS.X(IR,fM($CLJS.CG,$CLJS.H(["x",dT,"y",dT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,dT],null)])));var uT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IR],null);
$CLJS.X(KR,fM($CLJS.Xr,$CLJS.H(["x",dT,"y",dT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,dT],null)])));var vT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KR],null);$CLJS.X($P,fM($CLJS.fG,$CLJS.H(["x",dT])));var wT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$P],null);$CLJS.X(TQ,fM($CLJS.DF,$CLJS.H(["x",dT])));var xT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TQ],null);$CLJS.X(pS,fM($CLJS.mG,$CLJS.H(["x",dT])));var yT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pS],null);$CLJS.X(eP,fM($CLJS.IF,$CLJS.H(["x",dT])));
var zT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eP],null);$CLJS.X(kP,fM($CLJS.FF,$CLJS.H(["x",dT,"y",dT])));var AT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kP],null);$CLJS.X(HP,fM($CLJS.VF,$CLJS.H(["x",dT])));var BT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HP],null);$CLJS.X(EM,fM($CLJS.zF,$CLJS.H(["x",dT])));var CT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EM],null);$CLJS.X(fQ,fM($CLJS.EG,$CLJS.H(["x",dT])));var DT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fQ],null);
$CLJS.X(dN,fM($CLJS.YI,$CLJS.H(["datetime-x",eT,"datetime-y",eT,"unit",ska])));var ET=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dN],null);$CLJS.X(vN,fM($CLJS.mJ,$CLJS.H(["datetime",eT,"unit",rka,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,FS],null)])));var FT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vN],null);$CLJS.X(mP,fM($CLJS.UI,$CLJS.H(["date",eT])));var GT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mP],null);$CLJS.X(OP,fM($CLJS.qJ,$CLJS.H(["date",eT])));
var HT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OP],null);$CLJS.X(rM,fM($CLJS.NI,$CLJS.H(["date",eT])));var IT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rM],null);$CLJS.X(nS,fM($CLJS.gJ,$CLJS.H(["date",eT,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,FS],null)])));var JT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nS],null);$CLJS.X(zO,fM($CLJS.fJ,$CLJS.H(["date",eT])));var KT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zO],null);$CLJS.X(IP,fM($CLJS.kJ,$CLJS.H(["date",eT])));
var LT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IP],null);$CLJS.X($M,fM($CLJS.nJ,$CLJS.H(["datetime",eT])));var MT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$M],null);$CLJS.X(EO,fM($CLJS.jJ,$CLJS.H(["datetime",eT])));var NT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EO],null);$CLJS.X(TM,fM($CLJS.TI,$CLJS.H(["datetime",eT])));var OT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TM],null);$CLJS.X(jO,fM($CLJS.PI,$CLJS.H(["datetime",eT,"to",ES,"from",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,ES],null)])));
var PT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jO],null),QT=new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.pt,"datetime arithmetic unit"],null),$CLJS.XG,$CLJS.UG,$CLJS.Ui,$CLJS.qk,$CLJS.Yt,$CLJS.pj,$CLJS.yj,$CLJS.pi,$CLJS.ji],null);$CLJS.X(oM,fM($CLJS.iJ,$CLJS.H(["datetime",eT,"amount",dT,"unit",QT])));var RT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oM],null);
$CLJS.X(MM,function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return fM(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)}($CLJS.cJ));var ST=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MM],null);$CLJS.X(nM,fM($CLJS.oJ,$CLJS.H(["datetime",eT,"amount",dT,"unit",QT])));var TT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nM],null);
$CLJS.X(FQ,gM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof sT?new $CLJS.Gc(function(){return sT},$CLJS.od(UP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.bQ,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),IO,"metabase/mbql/schema.cljc",51,$CLJS.Wr,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Wr,$CLJS.zE,$CLJS.U($CLJS.Qj,YP),vR,$CLJS.U($CLJS.Qj,YP),MN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,YP))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n(sT)?sT.H:null])):null));return $CLJS.n(a)?a:IO}(),sT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof RT?new $CLJS.Gc(function(){return RT},
$CLJS.od(Uia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[cO,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PP,"metabase/mbql/schema.cljc",67,$CLJS.iJ,1,703,703,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.iJ,$CLJS.bJ,$CLJS.U($CLJS.Qj,iS),PQ,$CLJS.U($CLJS.Qj,
UQ),$CLJS.si,$CLJS.U($CLJS.Qj,bR)],null),$CLJS.Kc,"Schema for a valid datetime-add clause.",$CLJS.n(RT)?RT.H:null])):null));return $CLJS.n(a)?a:PP}(),RT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof TT?new $CLJS.Gc(function(){return TT},$CLJS.od(Nha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,
$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[cO,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gS,"metabase/mbql/schema.cljc",72,$CLJS.oJ,1,710,710,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.oJ,$CLJS.bJ,$CLJS.U($CLJS.Qj,iS),PQ,$CLJS.U($CLJS.Qj,UQ),$CLJS.si,$CLJS.U($CLJS.Qj,bR)],null),$CLJS.Kc,"Schema for a valid datetime-subtract clause.",$CLJS.n(TT)?TT.H:null])):null));
return $CLJS.n(a)?a:gS}(),TT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof PT?new $CLJS.Gc(function(){return PT},$CLJS.od(Sja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.PI,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JN,"metabase/mbql/schema.cljc",71,$CLJS.PI,1,695,695,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.PI,$CLJS.bJ,$CLJS.U($CLJS.Qj,iS),Mia,$CLJS.U($CLJS.Qj,yR),Rha,$CLJS.U($CLJS.Qj,$CLJS.U(kR,yR))],null),$CLJS.Kc,"Schema for a valid convert-timezone clause.",$CLJS.n(PT)?PT.H:null])):null));return $CLJS.n(a)?a:JN}(),PT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&
"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof ST?new $CLJS.Gc(function(){return ST},$CLJS.od(uja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.cJ,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nP,"metabase/mbql/schema.cljc",
45,$CLJS.cJ,1,708,708,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.cJ],null),$CLJS.Kc,"Schema for a valid now clause.",$CLJS.n(ST)?ST.H:null])):null));return $CLJS.n(a)?a:nP}(),ST],null)])));$CLJS.UT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KP],null);$CLJS.X(tM,fM($CLJS.xs,$CLJS.H(["first-clause",$CLJS.UT,"second-clause",$CLJS.UT,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,$CLJS.UT],null)])));var VT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tM],null);
$CLJS.X(fO,fM($CLJS.rs,$CLJS.H(["first-clause",$CLJS.UT,"second-clause",$CLJS.UT,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,$CLJS.UT],null)])));var WT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fO],null);$CLJS.X(OM,fM($CLJS.ts,$CLJS.H(["clause",$CLJS.UT])));
var XT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OM],null),YT=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,3,[$CLJS.pt,":field or :expression reference or :relative-datetime",$CLJS.rt,$CLJS.Ue(":field or :expression reference or :relative-datetime"),$CLJS.Oi,function(a){return $CLJS.n(eM($CLJS.aJ,a))?$CLJS.aJ:$CLJS.Et}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aJ,HS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Et,$CLJS.QS],null)],null);
$CLJS.X(CQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qs,new $CLJS.h(null,1,[$CLJS.pt,"equality comparable"],null),new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.rs,$CLJS.Gs,$CLJS.Bl,$CLJS.Zj,LS,YT,fT,MS],null)],null));var ZT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CQ],null);
$CLJS.X(TO,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.pt,"order comparable",$CLJS.Oi,function(a){return $CLJS.n(eM($CLJS.Ej,a))?$CLJS.Ej:$CLJS.Et}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,MS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Et,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.rs,$CLJS.Bl,$CLJS.Zj,LS,fT,YT],null)],null)],null));var $T=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TO],null);
$CLJS.X(BR,fM($CLJS.Ur,$CLJS.H(["field",ZT,"value-or-field",ZT,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,ZT],null)])));var aU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BR],null);$CLJS.X(FR,fM($CLJS.yG,$CLJS.H(["field",ZT,"value-or-field",ZT,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,ZT],null)])));var bU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FR],null);$CLJS.X(AR,fM($CLJS.Rr,$CLJS.H(["field",$T,"value-or-field",$T])));
var cU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AR],null);$CLJS.X(zR,fM($CLJS.Nr,$CLJS.H(["field",$T,"value-or-field",$T])));var dU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zR],null);$CLJS.X(oN,fM($CLJS.Tr,$CLJS.H(["field",$T,"value-or-field",$T])));var eU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oN],null);$CLJS.X(uN,fM($CLJS.Pr,$CLJS.H(["field",$T,"value-or-field",$T])));var fU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uN],null);$CLJS.X(jQ,fM($CLJS.KF,$CLJS.H(["field",$T,"min",$T,"max",$T])));
var gU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jQ],null);$CLJS.X(MQ,fM($CLJS.QF,$CLJS.H(["lat-field",$T,"lon-field",$T,"lat-max",$T,"lon-min",$T,"lat-min",$T,"lon-max",$T])));var hU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MQ],null);$CLJS.X(YN,fM($CLJS.JF,$CLJS.H(["field",$CLJS.QS])));var iU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YN],null);$CLJS.X(NO,fM($CLJS.UF,$CLJS.H(["field",$CLJS.QS])));var jU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NO],null);$CLJS.X(pO,fM($CLJS.LF,$CLJS.H(["field",$CLJS.QS])));
var kU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pO],null);$CLJS.X(wM,fM($CLJS.SF,$CLJS.H(["field",$CLJS.QS])));var lU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wM],null),mU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Gs],null)],null);$CLJS.X(CO,fM($CLJS.ZF,$CLJS.H(["field",VS,"string-or-field",VS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,mU],null)])));var nU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CO],null);
$CLJS.X(vP,fM($CLJS.sG,$CLJS.H(["field",VS,"string-or-field",VS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,mU],null)])));var oU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vP],null);$CLJS.X(nO,fM($CLJS.gG,$CLJS.H(["field",VS,"string-or-field",VS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,mU],null)])));var pU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nO],null);$CLJS.X(dQ,fM($CLJS.$F,$CLJS.H(["field",VS,"string-or-field",VS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,mU],null)])));
var qU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dQ],null);$CLJS.X(FM,fM($CLJS.tG,$CLJS.H(["field",$CLJS.QS,"n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,$CLJS.Cs,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.os,$CLJS.Sv,$CLJS.Ux,$CLJS.PF],null)],null),"unit",GS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gk,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Gs],null)],null)],null)])));var rU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FM],null);
$CLJS.X(vQ,fM($CLJS.GF,$CLJS.H(["segment-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.QK],null),rS],null)])));var sU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vQ],null);
$CLJS.X(DN,gM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof VT?new $CLJS.Gc(function(){return VT},$CLJS.od(oR,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),XO,"metabase/mbql/schema.cljc",15,$CLJS.xs,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.xs,NN,$CLJS.U($CLJS.Qj,bP),FP,$CLJS.U($CLJS.Qj,bP),XN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,bP))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n(VT)?VT.H:null])):null));return $CLJS.n(a)?a:XO}(),VT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof WT?new $CLJS.Gc(function(){return WT},
$CLJS.od(JP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MP,"metabase/mbql/schema.cljc",14,$CLJS.rs,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.rs,NN,$CLJS.U($CLJS.Qj,bP),FP,$CLJS.U($CLJS.Qj,bP),XN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,bP))],null),$CLJS.Kc,
"Schema for a valid or clause.",$CLJS.n(WT)?WT.H:null])):null));return $CLJS.n(a)?a:MP}(),WT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof XT?new $CLJS.Gc(function(){return XT},$CLJS.od(wQ,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oP,"metabase/mbql/schema.cljc",15,$CLJS.ts,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,$CLJS.cS,$CLJS.U($CLJS.Qj,bP)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n(XT)?XT.H:null])):null));return $CLJS.n(a)?a:oP}(),XT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==
typeof cU?new $CLJS.Gc(function(){return cU},$CLJS.od(SP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QO,"metabase/mbql/schema.cljc",13,$CLJS.Rr,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Rr,$CLJS.hG,$CLJS.U($CLJS.Qj,PN),$Q,$CLJS.U($CLJS.Qj,PN)],
null),$CLJS.Kc,"Schema for a valid \x3c clause.",$CLJS.n(cU)?cU.H:null])):null));return $CLJS.n(a)?a:QO}(),cU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof eU?new $CLJS.Gc(function(){return eU},$CLJS.od(VR,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PO,"metabase/mbql/schema.cljc",14,$CLJS.Tr,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Tr,$CLJS.hG,$CLJS.U($CLJS.Qj,PN),$Q,$CLJS.U($CLJS.Qj,PN)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(eU)?eU.H:null])):null));return $CLJS.n(a)?a:PO}(),eU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&
"undefined"!==typeof kM&&"undefined"!==typeof dU?new $CLJS.Gc(function(){return dU},$CLJS.od(QP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RO,"metabase/mbql/schema.cljc",13,$CLJS.Nr,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Nr,$CLJS.hG,$CLJS.U($CLJS.Qj,
PN),$Q,$CLJS.U($CLJS.Qj,PN)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n(dU)?dU.H:null])):null));return $CLJS.n(a)?a:RO}(),dU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof fU?new $CLJS.Gc(function(){return fU},$CLJS.od(OR,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],
[$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YO,"metabase/mbql/schema.cljc",14,$CLJS.Pr,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Pr,$CLJS.hG,$CLJS.U($CLJS.Qj,PN),$Q,$CLJS.U($CLJS.Qj,PN)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(fU)?fU.H:null])):null));return $CLJS.n(a)?a:YO}(),fU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&
"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof aU?new $CLJS.Gc(function(){return aU},$CLJS.od(RP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SO,"metabase/mbql/schema.cljc",13,$CLJS.Ur,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,
[$CLJS.Ur,$CLJS.hG,$CLJS.U($CLJS.Qj,aS),$Q,$CLJS.U($CLJS.Qj,aS),DQ,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,aS))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",$CLJS.n(aU)?aU.H:null])):null));return $CLJS.n(a)?a:SO}(),aU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof bU?new $CLJS.Gc(function(){return bU},$CLJS.od(eN,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,
$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AN,"metabase/mbql/schema.cljc",14,$CLJS.yG,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.yG,$CLJS.hG,$CLJS.U($CLJS.Qj,aS),$Q,$CLJS.U($CLJS.Qj,aS),DQ,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,aS))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n(bU)?bU.H:null])):null));
return $CLJS.n(a)?a:AN}(),bU],null)])));
$CLJS.X(KP,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.pt,"valid filter expression",$CLJS.Oi,function(a){return $CLJS.n(eM(ZS,a))?$CLJS.bJ:$CLJS.n(eM(WS,a))?jS:$CLJS.n(eM(TS,a))?$CLJS.Zj:$CLJS.n(eM(XS,a))?$CLJS.Gs:$CLJS.Et}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bJ,$CLJS.bT],null),new $CLJS.P(null,2,5,$CLJS.Q,[jS,$S],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zj,US],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,aT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Et,gM($CLJS.H([new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof VT?new $CLJS.Gc(function(){return VT},$CLJS.od(oR,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XO,"metabase/mbql/schema.cljc",
15,$CLJS.xs,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.xs,NN,$CLJS.U($CLJS.Qj,bP),FP,$CLJS.U($CLJS.Qj,bP),XN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,bP))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n(VT)?VT.H:null])):null));return $CLJS.n(a)?a:XO}(),VT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof WT?new $CLJS.Gc(function(){return WT},$CLJS.od(JP,new $CLJS.h(null,1,[$CLJS.bk,
!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MP,"metabase/mbql/schema.cljc",14,$CLJS.rs,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.rs,NN,$CLJS.U($CLJS.Qj,bP),FP,$CLJS.U($CLJS.Qj,bP),XN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,bP))],null),$CLJS.Kc,"Schema for a valid or clause.",$CLJS.n(WT)?
WT.H:null])):null));return $CLJS.n(a)?a:MP}(),WT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof XT?new $CLJS.Gc(function(){return XT},$CLJS.od(wQ,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),oP,"metabase/mbql/schema.cljc",15,$CLJS.ts,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,$CLJS.cS,$CLJS.U($CLJS.Qj,bP)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n(XT)?XT.H:null])):null));return $CLJS.n(a)?a:oP}(),XT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof aU?new $CLJS.Gc(function(){return aU},$CLJS.od(RP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,
$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SO,"metabase/mbql/schema.cljc",13,$CLJS.Ur,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Ur,$CLJS.hG,$CLJS.U($CLJS.Qj,aS),$Q,$CLJS.U($CLJS.Qj,aS),DQ,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,aS))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",$CLJS.n(aU)?aU.H:null])):
null));return $CLJS.n(a)?a:SO}(),aU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof bU?new $CLJS.Gc(function(){return bU},$CLJS.od(eN,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),AN,"metabase/mbql/schema.cljc",14,$CLJS.yG,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.yG,$CLJS.hG,$CLJS.U($CLJS.Qj,aS),$Q,$CLJS.U($CLJS.Qj,aS),DQ,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,aS))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n(bU)?bU.H:null])):null));return $CLJS.n(a)?a:AN}(),bU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof cU?new $CLJS.Gc(function(){return cU},
$CLJS.od(SP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QO,"metabase/mbql/schema.cljc",13,$CLJS.Rr,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Rr,$CLJS.hG,$CLJS.U($CLJS.Qj,PN),$Q,$CLJS.U($CLJS.Qj,PN)],null),$CLJS.Kc,"Schema for a valid \x3c clause.",
$CLJS.n(cU)?cU.H:null])):null));return $CLJS.n(a)?a:QO}(),cU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof dU?new $CLJS.Gc(function(){return dU},$CLJS.od(QP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),RO,"metabase/mbql/schema.cljc",13,$CLJS.Nr,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Nr,$CLJS.hG,$CLJS.U($CLJS.Qj,PN),$Q,$CLJS.U($CLJS.Qj,PN)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n(dU)?dU.H:null])):null));return $CLJS.n(a)?a:RO}(),dU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof eU?new $CLJS.Gc(function(){return eU},$CLJS.od(VR,new $CLJS.h(null,
1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PO,"metabase/mbql/schema.cljc",14,$CLJS.Tr,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Tr,$CLJS.hG,$CLJS.U($CLJS.Qj,PN),$Q,$CLJS.U($CLJS.Qj,PN)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(eU)?eU.H:null])):
null));return $CLJS.n(a)?a:PO}(),eU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof fU?new $CLJS.Gc(function(){return fU},$CLJS.od(OR,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),YO,"metabase/mbql/schema.cljc",14,$CLJS.Pr,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Pr,$CLJS.hG,$CLJS.U($CLJS.Qj,PN),$Q,$CLJS.U($CLJS.Qj,PN)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(fU)?fU.H:null])):null));return $CLJS.n(a)?a:YO}(),fU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof gU?new $CLJS.Gc(function(){return gU},$CLJS.od(lja,new $CLJS.h(null,
1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gN,"metabase/mbql/schema.cljc",19,$CLJS.KF,1,804,804,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.KF,$CLJS.hG,$CLJS.U($CLJS.Qj,PN),$CLJS.Zn,$CLJS.U($CLJS.Qj,PN),$CLJS.jk,$CLJS.U($CLJS.Qj,PN)],null),$CLJS.Kc,"Schema for a valid between clause.",
$CLJS.n(gU)?gU.H:null])):null));return $CLJS.n(a)?a:gN}(),gU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof nU?new $CLJS.Gc(function(){return nU},$CLJS.od(nja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),eR,"metabase/mbql/schema.cljc",23,$CLJS.ZF,1,829,829,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ZF,$CLJS.hG,$CLJS.U($CLJS.Qj,ZQ),zN,$CLJS.U($CLJS.Qj,ZQ),$CLJS.gj,$CLJS.U($CLJS.Qj,$CLJS.U(kR,uQ))],null),$CLJS.Kc,"Schema for a valid starts-with clause.",$CLJS.n(nU)?nU.H:null])):null));return $CLJS.n(a)?a:eR}(),nU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof oU?new $CLJS.Gc(function(){return oU},
$CLJS.od(oia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EN,"metabase/mbql/schema.cljc",21,$CLJS.sG,1,830,830,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.sG,$CLJS.hG,$CLJS.U($CLJS.Qj,ZQ),zN,$CLJS.U($CLJS.Qj,ZQ),$CLJS.gj,$CLJS.U($CLJS.Qj,$CLJS.U(kR,uQ))],null),
$CLJS.Kc,"Schema for a valid ends-with clause.",$CLJS.n(oU)?oU.H:null])):null));return $CLJS.n(a)?a:EN}(),oU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof pU?new $CLJS.Gc(function(){return pU},$CLJS.od(Bia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QN,"metabase/mbql/schema.cljc",20,$CLJS.gG,1,831,831,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.gG,$CLJS.hG,$CLJS.U($CLJS.Qj,ZQ),zN,$CLJS.U($CLJS.Qj,ZQ),$CLJS.gj,$CLJS.U($CLJS.Qj,$CLJS.U(kR,uQ))],null),$CLJS.Kc,"Schema for a valid contains clause.",$CLJS.n(pU)?pU.H:null])):null));return $CLJS.n(a)?a:QN}(),pU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==
typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof qU?new $CLJS.Gc(function(){return qU},$CLJS.od(fka,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,mR,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uM,"metabase/mbql/schema.cljc",36,$CLJS.$F,1,834,834,!0,new $CLJS.P(null,
7,5,$CLJS.Q,[$CLJS.$F,$CLJS.hG,$CLJS.U($CLJS.Qj,ZQ),zN,$CLJS.U($CLJS.Qj,ZQ),$CLJS.gj,$CLJS.U($CLJS.Qj,$CLJS.U(kR,uQ))],null),$CLJS.Kc,"Schema for a valid does-not-contain clause.",$CLJS.n(qU)?qU.H:null])):null));return $CLJS.n(a)?a:uM}(),qU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof hU?new $CLJS.Gc(function(){return hU},$CLJS.od(Pja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),
$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,mR,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cR,"metabase/mbql/schema.cljc",26,$CLJS.QF,1,807,807,!0,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.QF,Xha,$CLJS.U($CLJS.Qj,PN),kka,$CLJS.U($CLJS.Qj,PN),Wja,$CLJS.U($CLJS.Qj,PN),$ha,$CLJS.U($CLJS.Qj,PN),Qja,$CLJS.U($CLJS.Qj,PN),Wha,$CLJS.U($CLJS.Qj,
PN)],null),$CLJS.Kc,"Schema for a valid inside clause.",$CLJS.n(hU)?hU.H:null])):null));return $CLJS.n(a)?a:cR}(),hU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof kU?new $CLJS.Gc(function(){return kU},$CLJS.od(Cia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,mR,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xP,"metabase/mbql/schema.cljc",28,$CLJS.LF,1,821,821,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LF,$CLJS.hG,$CLJS.U($CLJS.Qj,IN)],null),$CLJS.Kc,"Schema for a valid is-empty clause.",$CLJS.n(kU)?kU.H:null])):null));return $CLJS.n(a)?a:xP}(),kU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&
"undefined"!==typeof lU?new $CLJS.Gc(function(){return lU},$CLJS.od(Aja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,mR,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kQ,"metabase/mbql/schema.cljc",29,$CLJS.SF,1,822,822,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SF,$CLJS.hG,$CLJS.U($CLJS.Qj,IN)],null),
$CLJS.Kc,"Schema for a valid not-empty clause.",$CLJS.n(lU)?lU.H:null])):null));return $CLJS.n(a)?a:kQ}(),lU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof iU?new $CLJS.Gc(function(){return iU},$CLJS.od(aka,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,mR,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WN,"metabase/mbql/schema.cljc",27,$CLJS.JF,1,816,816,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JF,$CLJS.hG,$CLJS.U($CLJS.Qj,IN)],null),$CLJS.Kc,"Schema for a valid is-null clause.",$CLJS.n(iU)?iU.H:null])):null));return $CLJS.n(a)?a:WN}(),iU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&
"undefined"!==typeof jU?new $CLJS.Gc(function(){return jU},$CLJS.od(Oia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,mR,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lN,"metabase/mbql/schema.cljc",28,$CLJS.UF,1,817,817,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UF,$CLJS.hG,$CLJS.U($CLJS.Qj,IN)],null),
$CLJS.Kc,"Schema for a valid not-null clause.",$CLJS.n(jU)?jU.H:null])):null));return $CLJS.n(a)?a:lN}(),jU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof rU?new $CLJS.Gc(function(){return rU},$CLJS.od(Oha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,mR,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KQ,"metabase/mbql/schema.cljc",33,$CLJS.tG,1,856,856,!0,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.tG,$CLJS.hG,$CLJS.U($CLJS.Qj,IN),$CLJS.rk,$CLJS.U($CLJS.Qj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,$CLJS.Cs,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.os,$CLJS.Sv,$CLJS.Ux,$CLJS.PF],null)],null)),$CLJS.si,$CLJS.U($CLJS.Qj,WO),$CLJS.gj,$CLJS.U($CLJS.Qj,$CLJS.U(kR,qja))],null),$CLJS.Kc,"Schema for a valid time-interval clause.",
$CLJS.n(rU)?rU.H:null])):null));return $CLJS.n(a)?a:KQ}(),rU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof sU?new $CLJS.Gc(function(){return sU},$CLJS.od(Wia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,mR,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),MR,"metabase/mbql/schema.cljc",27,$CLJS.GF,1,872,872,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GF,dia,$CLJS.U($CLJS.Qj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,Gja,YM],null))],null),$CLJS.Kc,"Schema for a valid segment clause.",$CLJS.n(sU)?sU.H:null])):null));return $CLJS.n(a)?a:MR}(),sU],null)]))],null)],null));
$CLJS.X(xQ,fM($CLJS.RF,$CLJS.H(["clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.us,new $CLJS.h(null,1,[$CLJS.pt,":case subclause"],null),$CLJS.UT,fT],null)],null)],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.pt,":case options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),fT],null)],null)],null)])));
var tU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xQ],null);
$CLJS.X(dR,gM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof sT?new $CLJS.Gc(function(){return sT},$CLJS.od(UP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.bQ,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),IO,"metabase/mbql/schema.cljc",51,$CLJS.Wr,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Wr,$CLJS.zE,$CLJS.U($CLJS.Qj,YP),vR,$CLJS.U($CLJS.Qj,YP),MN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,YP))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n(sT)?sT.H:null])):null));return $CLJS.n(a)?a:IO}(),sT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof tT?new $CLJS.Gc(function(){return tT},
$CLJS.od(hja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.bQ,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JO,"metabase/mbql/schema.cljc",51,$CLJS.it,1,615,615,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.it,$CLJS.zE,$CLJS.U($CLJS.Qj,UQ),
vR,$CLJS.U($CLJS.Qj,YP),MN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,YP))],null),$CLJS.Kc,"Schema for a valid - clause.",$CLJS.n(tT)?tT.H:null])):null));return $CLJS.n(a)?a:JO}(),tT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM?new $CLJS.Gc(function(){return uT},$CLJS.od(gja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,lP,$CLJS.Ti,$CLJS.oj,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],
[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.bQ,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.cA,"metabase/mbql/schema.cljc",$CLJS.CG,1,618,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.CG,$CLJS.zE,$CLJS.U($CLJS.Qj,UQ),vR,$CLJS.U($CLJS.Qj,UQ),MN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,UQ))],null),$CLJS.Kc,"Schema for a valid / clause.",$CLJS.n(uT)?uT.H:null])):null));return $CLJS.n(a)?
a:$CLJS.cA}(),uT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof vT?new $CLJS.Gc(function(){return vT},$CLJS.od(ija,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.bQ,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),HO,"metabase/mbql/schema.cljc",51,$CLJS.Xr,1,620,620,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Xr,$CLJS.zE,$CLJS.U($CLJS.Qj,UQ),vR,$CLJS.U($CLJS.Qj,UQ),MN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,UQ))],null),$CLJS.Kc,"Schema for a valid * clause.",$CLJS.n(vT)?vT.H:null])):null));return $CLJS.n(a)?a:HO}(),vT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof hT?new $CLJS.Gc(function(){return hT},
$CLJS.od(HQ,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.bQ,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WQ,"metabase/mbql/schema.cljc",58,$CLJS.kG,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.kG,ER,$CLJS.U($CLJS.Qj,zM),DR,$CLJS.U($CLJS.Qj,
zM),MN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,zM))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(hT)?hT.H:null])):null));return $CLJS.n(a)?a:WQ}(),hT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof jT?new $CLJS.Gc(function(){return jT},$CLJS.od(zja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,
$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.bQ,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nQ,"metabase/mbql/schema.cljc",56,$CLJS.Ox,1,585,585,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ox,$CLJS.ky,$CLJS.U($CLJS.Qj,ZQ)],null),$CLJS.Kc,"Schema for a valid length clause.",$CLJS.n(jT)?jT.H:null])):null));return $CLJS.n(a)?a:nQ}(),jT],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof wT?new $CLJS.Gc(function(){return wT},$CLJS.od(pia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.bQ,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),iP,"metabase/mbql/schema.cljc",55,$CLJS.fG,1,622,622,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fG,$CLJS.zE,$CLJS.U($CLJS.Qj,UQ)],null),$CLJS.Kc,"Schema for a valid floor clause.",$CLJS.n(wT)?wT.H:null])):null));return $CLJS.n(a)?a:iP}(),wT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof xT?new $CLJS.Gc(function(){return xT},$CLJS.od(jia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),
$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.bQ,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XM,"metabase/mbql/schema.cljc",54,$CLJS.DF,1,625,625,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DF,$CLJS.zE,$CLJS.U($CLJS.Qj,UQ)],null),$CLJS.Kc,"Schema for a valid ceil clause.",$CLJS.n(xT)?
xT.H:null])):null));return $CLJS.n(a)?a:XM}(),xT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof yT?new $CLJS.Gc(function(){return yT},$CLJS.od(yia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.bQ,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZO,"metabase/mbql/schema.cljc",55,$CLJS.mG,1,628,628,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mG,$CLJS.zE,$CLJS.U($CLJS.Qj,UQ)],null),$CLJS.Kc,"Schema for a valid round clause.",$CLJS.n(yT)?yT.H:null])):null));return $CLJS.n(a)?a:ZO}(),yT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&
"undefined"!==typeof zT?new $CLJS.Gc(function(){return zT},$CLJS.od(gka,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.bQ,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BP,"metabase/mbql/schema.cljc",53,$CLJS.IF,1,631,631,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.IF,$CLJS.zE,$CLJS.U($CLJS.Qj,UQ)],null),$CLJS.Kc,"Schema for a valid abs clause.",$CLJS.n(zT)?zT.H:null])):null));return $CLJS.n(a)?a:BP}(),zT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof AT?new $CLJS.Gc(function(){return AT},$CLJS.od(Uja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,
hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[SR,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nR,"metabase/mbql/schema.cljc",69,$CLJS.FF,1,634,634,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.FF,$CLJS.zE,$CLJS.U($CLJS.Qj,UQ),vR,$CLJS.U($CLJS.Qj,UQ)],null),$CLJS.Kc,"Schema for a valid power clause.",$CLJS.n(AT)?AT.H:null])):null));return $CLJS.n(a)?a:nR}(),AT],
null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof BT?new $CLJS.Gc(function(){return BT},$CLJS.od(Ria,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[SR,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),DO,"metabase/mbql/schema.cljc",68,$CLJS.VF,1,637,637,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VF,$CLJS.zE,$CLJS.U($CLJS.Qj,UQ)],null),$CLJS.Kc,"Schema for a valid sqrt clause.",$CLJS.n(BT)?BT.H:null])):null));return $CLJS.n(a)?a:DO}(),BT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof CT?new $CLJS.Gc(function(){return CT},$CLJS.od(Vja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),
$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[SR,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aP,"metabase/mbql/schema.cljc",67,$CLJS.zF,1,640,640,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zF,$CLJS.zE,$CLJS.U($CLJS.Qj,UQ)],null),$CLJS.Kc,"Schema for a valid exp clause.",$CLJS.n(CT)?
CT.H:null])):null));return $CLJS.n(a)?a:aP}(),CT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof DT?new $CLJS.Gc(function(){return DT},$CLJS.od(cia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[SR,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YR,"metabase/mbql/schema.cljc",67,$CLJS.EG,1,643,643,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EG,$CLJS.zE,$CLJS.U($CLJS.Qj,UQ)],null),$CLJS.Kc,"Schema for a valid log clause.",$CLJS.n(DT)?DT.H:null])):null));return $CLJS.n(a)?a:YR}(),DT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&
"undefined"!==typeof tU?new $CLJS.Gc(function(){return tU},$CLJS.od(KM,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.CF,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sP,"metabase/mbql/schema.cljc",61,$CLJS.RF,1,909,909,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.RF,$CLJS.ax,$CLJS.U($CLJS.Qj,bO),$CLJS.gj,$CLJS.U($CLJS.Qj,$CLJS.U(kR,LP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(tU)?tU.H:null])):null));return $CLJS.n(a)?a:sP}(),tU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof ET?new $CLJS.Gc(function(){return ET},$CLJS.od(fia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,
$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.YI,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CP,"metabase/mbql/schema.cljc",65,$CLJS.YI,1,656,656,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.YI,sia,$CLJS.U($CLJS.Qj,iS),tia,$CLJS.U($CLJS.Qj,iS),$CLJS.si,$CLJS.U($CLJS.Qj,Zia)],null),$CLJS.Kc,"Schema for a valid datetime-diff clause.",
$CLJS.n(ET)?ET.H:null])):null));return $CLJS.n(a)?a:CP}(),ET],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof FT?new $CLJS.Gc(function(){return FT},$CLJS.od(Dja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.mJ,"null"],null),null),$CLJS.od(SQ,
new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VP,"metabase/mbql/schema.cljc",71,$CLJS.mJ,1,661,661,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.mJ,$CLJS.bJ,$CLJS.U($CLJS.Qj,iS),$CLJS.si,$CLJS.U($CLJS.Qj,$ja),$CLJS.yi,$CLJS.U($CLJS.Qj,$CLJS.U(kR,qS))],null),$CLJS.Kc,"Schema for a valid temporal-extract clause.",$CLJS.n(FT)?FT.H:null])):null));return $CLJS.n(a)?a:VP}(),FT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof GT?new $CLJS.Gc(function(){return GT},$CLJS.od(Kia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,mR,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.mJ,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),tN,"metabase/mbql/schema.cljc",71,$CLJS.UI,1,667,667,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UI,$CLJS.EP,$CLJS.U($CLJS.Qj,iS)],null),$CLJS.Kc,"Schema for a valid get-year clause.",$CLJS.n(GT)?GT.H:null])):null));return $CLJS.n(a)?a:tN}(),GT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof HT?new $CLJS.Gc(function(){return HT},$CLJS.od(zia,new $CLJS.h(null,1,[$CLJS.bk,!0],
null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,mR,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.mJ,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TP,"metabase/mbql/schema.cljc",74,$CLJS.qJ,1,670,670,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qJ,$CLJS.EP,$CLJS.U($CLJS.Qj,iS)],null),$CLJS.Kc,"Schema for a valid get-quarter clause.",
$CLJS.n(HT)?HT.H:null])):null));return $CLJS.n(a)?a:TP}(),HT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof IT?new $CLJS.Gc(function(){return IT},$CLJS.od(fja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,mR,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.mJ,"null"],null),null),
$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pQ,"metabase/mbql/schema.cljc",72,$CLJS.NI,1,673,673,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NI,$CLJS.EP,$CLJS.U($CLJS.Qj,iS)],null),$CLJS.Kc,"Schema for a valid get-month clause.",$CLJS.n(IT)?IT.H:null])):null));return $CLJS.n(a)?a:pQ}(),IT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==
typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof JT?new $CLJS.Gc(function(){return JT},$CLJS.od(Zja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,mR,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.mJ,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oQ,"metabase/mbql/schema.cljc",
71,$CLJS.gJ,1,676,676,!0,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.gJ,$CLJS.EP,$CLJS.U($CLJS.Qj,iS),$CLJS.yi,$CLJS.U($CLJS.Qj,$CLJS.U(kR,qS))],null),$CLJS.Kc,"Schema for a valid get-week clause.",$CLJS.n(JT)?JT.H:null])):null));return $CLJS.n(a)?a:oQ}(),JT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof KT?new $CLJS.Gc(function(){return KT},$CLJS.od(Uha,new $CLJS.h(null,1,[$CLJS.bk,
!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,mR,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.mJ,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hQ,"metabase/mbql/schema.cljc",70,$CLJS.fJ,1,680,680,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fJ,$CLJS.EP,$CLJS.U($CLJS.Qj,iS)],null),$CLJS.Kc,"Schema for a valid get-day clause.",
$CLJS.n(KT)?KT.H:null])):null));return $CLJS.n(a)?a:hQ}(),KT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof LT?new $CLJS.Gc(function(){return LT},$CLJS.od($ia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,mR,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.mJ,"null"],null),null),
$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZP,"metabase/mbql/schema.cljc",78,$CLJS.kJ,1,683,683,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kJ,$CLJS.EP,$CLJS.U($CLJS.Qj,iS)],null),$CLJS.Kc,"Schema for a valid get-day-of-week clause.",$CLJS.n(LT)?LT.H:null])):null));return $CLJS.n(a)?a:ZP}(),LT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==
typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof MT?new $CLJS.Gc(function(){return MT},$CLJS.od(Yia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,mR,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.mJ,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AM,"metabase/mbql/schema.cljc",
71,$CLJS.nJ,1,686,686,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nJ,$CLJS.bJ,$CLJS.U($CLJS.Qj,iS)],null),$CLJS.Kc,"Schema for a valid get-hour clause.",$CLJS.n(MT)?MT.H:null])):null));return $CLJS.n(a)?a:AM}(),MT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof NT?new $CLJS.Gc(function(){return NT},$CLJS.od(Hja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,
$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,mR,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.mJ,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bS,"metabase/mbql/schema.cljc",73,$CLJS.jJ,1,689,689,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jJ,$CLJS.bJ,$CLJS.U($CLJS.Qj,iS)],null),$CLJS.Kc,"Schema for a valid get-minute clause.",$CLJS.n(NT)?NT.H:null])):null));
return $CLJS.n(a)?a:bS}(),NT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof OT?new $CLJS.Gc(function(){return OT},$CLJS.od(Via,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,mR,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.mJ,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,
[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uP,"metabase/mbql/schema.cljc",73,$CLJS.TI,1,692,692,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TI,$CLJS.bJ,$CLJS.U($CLJS.Qj,iS)],null),$CLJS.Kc,"Schema for a valid get-second clause.",$CLJS.n(OT)?OT.H:null])):null));return $CLJS.n(a)?a:uP}(),OT],null)])));
$CLJS.X(PM,gM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof iT?new $CLJS.Gc(function(){return iT},$CLJS.od(xja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.bQ,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),yQ,"metabase/mbql/schema.cljc",59,$CLJS.AF,1,582,582,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.AF,$CLJS.ky,$CLJS.U($CLJS.Qj,ZQ),$CLJS.xv,$CLJS.U($CLJS.Qj,via),$CLJS.Ox,$CLJS.U($CLJS.Qj,$CLJS.U(kR,UQ))],null),$CLJS.Kc,"Schema for a valid substring clause.",$CLJS.n(iT)?iT.H:null])):null));return $CLJS.n(a)?a:yQ}(),iT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof kT?new $CLJS.Gc(function(){return kT},
$CLJS.od(Xja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.bQ,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VQ,"metabase/mbql/schema.cljc",54,$CLJS.xG,1,588,588,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xG,$CLJS.ky,$CLJS.U($CLJS.Qj,ZQ)],
null),$CLJS.Kc,"Schema for a valid trim clause.",$CLJS.n(kT)?kT.H:null])):null));return $CLJS.n(a)?a:VQ}(),kT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof mT?new $CLJS.Gc(function(){return mT},$CLJS.od(aja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,
1,[$CLJS.bQ,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EQ,"metabase/mbql/schema.cljc",55,$CLJS.zG,1,594,594,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zG,$CLJS.ky,$CLJS.U($CLJS.Qj,ZQ)],null),$CLJS.Kc,"Schema for a valid ltrim clause.",$CLJS.n(mT)?mT.H:null])):null));return $CLJS.n(a)?a:EQ}(),mT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&
"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof lT?new $CLJS.Gc(function(){return lT},$CLJS.od(yja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.bQ,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GP,"metabase/mbql/schema.cljc",
55,$CLJS.GG,1,591,591,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GG,$CLJS.ky,$CLJS.U($CLJS.Qj,ZQ)],null),$CLJS.Kc,"Schema for a valid rtrim clause.",$CLJS.n(lT)?lT.H:null])):null));return $CLJS.n(a)?a:GP}(),lT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof pT?new $CLJS.Gc(function(){return pT},$CLJS.od(Pha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,
$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.bQ,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GR,"metabase/mbql/schema.cljc",57,$CLJS.FG,1,603,603,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.FG,$CLJS.ky,$CLJS.U($CLJS.Qj,ZQ),aia,$CLJS.U($CLJS.Qj,$CLJS.Zj),$CLJS.jA,$CLJS.U($CLJS.Qj,$CLJS.Zj)],null),$CLJS.Kc,"Schema for a valid replace clause.",
$CLJS.n(pT)?pT.H:null])):null));return $CLJS.n(a)?a:GR}(),pT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof oT?new $CLJS.Gc(function(){return oT},$CLJS.od(Bja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.bQ,"null"],null),null),$CLJS.od(SQ,
new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lS,"metabase/mbql/schema.cljc",55,$CLJS.It,1,600,600,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.It,$CLJS.ky,$CLJS.U($CLJS.Qj,ZQ)],null),$CLJS.Kc,"Schema for a valid lower clause.",$CLJS.n(oT)?oT.H:null])):null));return $CLJS.n(a)?a:lS}(),oT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==
typeof kM&&"undefined"!==typeof nT?new $CLJS.Gc(function(){return nT},$CLJS.od(Yja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.bQ,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hR,"metabase/mbql/schema.cljc",55,$CLJS.Ct,1,597,597,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ct,$CLJS.ky,$CLJS.U($CLJS.Qj,ZQ)],null),$CLJS.Kc,"Schema for a valid upper clause.",$CLJS.n(nT)?nT.H:null])):null));return $CLJS.n(a)?a:hR}(),nT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof qT?new $CLJS.Gc(function(){return qT},$CLJS.od(pja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,
$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.bQ,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uO,"metabase/mbql/schema.cljc",56,$CLJS.HF,1,606,606,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.HF,ER,$CLJS.U($CLJS.Qj,ZQ),DR,$CLJS.U($CLJS.Qj,ZQ),MN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,ZQ))],null),$CLJS.Kc,"Schema for a valid concat clause.",
$CLJS.n(qT)?qT.H:null])):null));return $CLJS.n(a)?a:uO}(),qT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof rT?new $CLJS.Gc(function(){return rT},$CLJS.od(wja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.bQ,"null",$CLJS.Vda,"null"],
null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rN,"metabase/mbql/schema.cljc",74,$CLJS.jG,1,609,609,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.jG,$CLJS.ky,$CLJS.U($CLJS.Qj,ZQ),$CLJS.iA,$CLJS.U($CLJS.Qj,$CLJS.Zj)],null),$CLJS.Kc,"Schema for a valid regex-match-first clause.",$CLJS.n(rT)?rT.H:null])):null));return $CLJS.n(a)?a:rN}(),rT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==
typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof hT?new $CLJS.Gc(function(){return hT},$CLJS.od(HQ,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.bQ,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
WQ,"metabase/mbql/schema.cljc",58,$CLJS.kG,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.kG,ER,$CLJS.U($CLJS.Qj,zM),DR,$CLJS.U($CLJS.Qj,zM),MN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,zM))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(hT)?hT.H:null])):null));return $CLJS.n(a)?a:WQ}(),hT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof tU?new $CLJS.Gc(function(){return tU},
$CLJS.od(KM,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.CF,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sP,"metabase/mbql/schema.cljc",61,$CLJS.RF,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.RF,$CLJS.ax,$CLJS.U($CLJS.Qj,bO),
$CLJS.gj,$CLJS.U($CLJS.Qj,$CLJS.U(kR,LP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(tU)?tU.H:null])):null));return $CLJS.n(a)?a:sP}(),tU],null)])));
$CLJS.uU=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.pt,":field or :expression reference or expression",$CLJS.Oi,function(a){return $CLJS.n(eM(WS,a))?jS:$CLJS.n(eM(TS,a))?$CLJS.Zj:$CLJS.n(eM(XS,a))?$CLJS.Gs:$CLJS.n(eM(ZS,a))?$CLJS.bJ:$CLJS.n(eM($CLJS.RF,a))?$CLJS.RF:$CLJS.Et}],null),new $CLJS.P(null,2,5,$CLJS.Q,[jS,$S],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zj,US],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,aT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bJ,$CLJS.bT],null),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RF,tU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Et,$CLJS.QS],null)],null);$CLJS.X(PR,fM($CLJS.Qx,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,$CLJS.QS],null)])));var vU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PR],null);$CLJS.X(DP,fM($CLJS.iG,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,$CLJS.QS],null)])));var wU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DP],null);$CLJS.X(NM,fM($CLJS.bG,$CLJS.H(["field-or-expression",$CLJS.uU])));
var xU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NM],null);$CLJS.X(HR,fM($CLJS.DG,$CLJS.H(["field-or-expression",$CLJS.uU])));var yU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HR],null);$CLJS.X(mO,fM($CLJS.NF,$CLJS.H(["field-or-expression",$CLJS.uU])));var zU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mO],null);$CLJS.X(XQ,fM($CLJS.uG,$CLJS.H(["field-or-expression",$CLJS.uU])));var AU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XQ],null);$CLJS.X(hO,fM($CLJS.Zn,$CLJS.H(["field-or-expression",$CLJS.uU])));
var BU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hO],null);$CLJS.X(UN,fM($CLJS.jk,$CLJS.H(["field-or-expression",$CLJS.uU])));var CU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UN],null);$CLJS.X(ON,fM($CLJS.BG,$CLJS.H(["field-or-expression",$CLJS.uU,"pred",$CLJS.UT])));var DU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ON],null);$CLJS.X(eO,fM($CLJS.qG,$CLJS.H(["pred",$CLJS.UT])));var EU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eO],null);$CLJS.X(OQ,fM($CLJS.oG,$CLJS.H(["pred",$CLJS.UT])));
var FU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OQ],null);$CLJS.X(kN,fM($CLJS.wG,$CLJS.H(["field-or-expression",$CLJS.uU])));var GU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kN],null);$CLJS.X(mM,fM($CLJS.OF,$CLJS.H(["field-or-expression",$CLJS.uU])));var HU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mM],null);$CLJS.X(qO,fM($CLJS.vG,$CLJS.H(["field-or-expression",$CLJS.uU])));var IU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qO],null);$CLJS.X(dP,fM($CLJS.TF,$CLJS.H(["field-or-expression",$CLJS.uU,"percentile",dT])));
var JU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dP],null);$CLJS.X(RQ,fM($CLJS.cG,$CLJS.H(["metric-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.OK],null),rS],null)])));var KU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RQ],null);
$CLJS.X(NQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.pt,"unnamed aggregation clause or numeric expression",$CLJS.Oi,function(a){return $CLJS.n(eM(WS,a))?gP:$CLJS.Et}],null),new $CLJS.P(null,2,5,$CLJS.Q,[gP,$S],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Et,gM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof xU?new $CLJS.Gc(function(){return xU},$CLJS.od(vja,
new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.CF,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pP,"metabase/mbql/schema.cljc",60,$CLJS.bG,1,957,957,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bG,iR,$CLJS.U($CLJS.Qj,aR)],null),$CLJS.Kc,"Schema for a valid avg clause.",
$CLJS.n(xU)?xU.H:null])):null));return $CLJS.n(a)?a:pP}(),xU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof yU?new $CLJS.Gc(function(){return yU},$CLJS.od(Gia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.CF,"null"],null),null),$CLJS.od(SQ,
new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uR,"metabase/mbql/schema.cljc",64,$CLJS.DG,1,958,958,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DG,iR,$CLJS.U($CLJS.Qj,aR)],null),$CLJS.Kc,"Schema for a valid cum-sum clause.",$CLJS.n(yU)?yU.H:null])):null));return $CLJS.n(a)?a:uR}(),yU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==
typeof kM&&"undefined"!==typeof zU?new $CLJS.Gc(function(){return zU},$CLJS.od(xia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.CF,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tP,"metabase/mbql/schema.cljc",65,$CLJS.NF,1,959,959,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NF,iR,$CLJS.U($CLJS.Qj,aR)],null),$CLJS.Kc,"Schema for a valid distinct clause.",$CLJS.n(zU)?zU.H:null])):null));return $CLJS.n(a)?a:tP}(),zU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof GU?new $CLJS.Gc(function(){return GU},$CLJS.od(Rja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,
$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.rG,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HM,"metabase/mbql/schema.cljc",76,$CLJS.wG,1,973,973,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wG,iR,$CLJS.U($CLJS.Qj,aR)],null),$CLJS.Kc,"Schema for a valid stddev clause.",$CLJS.n(GU)?GU.H:null])):null));return $CLJS.n(a)?a:HM}(),GU],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof AU?new $CLJS.Gc(function(){return AU},$CLJS.od(kja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.CF,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),cN,"metabase/mbql/schema.cljc",60,$CLJS.uG,1,960,960,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uG,iR,$CLJS.U($CLJS.Qj,aR)],null),$CLJS.Kc,"Schema for a valid sum clause.",$CLJS.n(AU)?AU.H:null])):null));return $CLJS.n(a)?a:cN}(),AU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof BU?new $CLJS.Gc(function(){return BU},$CLJS.od(Yha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,
$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.CF,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Ws,"metabase/mbql/schema.cljc",60,$CLJS.Zn,1,961,961,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zn,iR,$CLJS.U($CLJS.Qj,aR)],null),$CLJS.Kc,"Schema for a valid min clause.",$CLJS.n(BU)?BU.H:
null])):null));return $CLJS.n(a)?a:$CLJS.Ws}(),BU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof CU?new $CLJS.Gc(function(){return CU},$CLJS.od(Tha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.CF,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.$s,"metabase/mbql/schema.cljc",60,$CLJS.jk,1,962,962,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jk,iR,$CLJS.U($CLJS.Qj,aR)],null),$CLJS.Kc,"Schema for a valid max clause.",$CLJS.n(CU)?CU.H:null])):null));return $CLJS.n(a)?a:$CLJS.$s}(),CU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&
"undefined"!==typeof KU?new $CLJS.Gc(function(){return KU},$CLJS.od(uia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tO,"metabase/mbql/schema.cljc",18,$CLJS.cG,1,994,994,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cG,Hia,$CLJS.U($CLJS.Qj,new $CLJS.P(null,3,
5,$CLJS.Q,[$CLJS.rs,Lia,YM],null))],null),$CLJS.Kc,"Schema for a valid metric clause.",$CLJS.n(KU)?KU.H:null])):null));return $CLJS.n(a)?a:tO}(),KU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof FU?new $CLJS.Gc(function(){return FU},$CLJS.od(Tia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,
$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.CF,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lO,"metabase/mbql/schema.cljc",62,$CLJS.oG,1,970,970,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oG,$CLJS.ro,$CLJS.U($CLJS.Qj,bP)],null),$CLJS.Kc,"Schema for a valid share clause.",$CLJS.n(FU)?FU.H:null])):null));return $CLJS.n(a)?a:lO}(),FU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof EU?new $CLJS.Gc(function(){return EU},$CLJS.od(Zha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.CF,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),ZM,"metabase/mbql/schema.cljc",68,$CLJS.qG,1,967,967,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qG,$CLJS.ro,$CLJS.U($CLJS.Qj,bP)],null),$CLJS.Kc,"Schema for a valid count-where clause.",$CLJS.n(EU)?EU.H:null])):null));return $CLJS.n(a)?a:ZM}(),EU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof DU?new $CLJS.Gc(function(){return DU},$CLJS.od(eka,new $CLJS.h(null,1,[$CLJS.bk,!0],
null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.CF,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qP,"metabase/mbql/schema.cljc",66,$CLJS.BG,1,964,964,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.BG,iR,$CLJS.U($CLJS.Qj,aR),$CLJS.ro,$CLJS.U($CLJS.Qj,bP)],null),$CLJS.Kc,"Schema for a valid sum-where clause.",
$CLJS.n(DU)?DU.H:null])):null));return $CLJS.n(a)?a:qP}(),DU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof tU?new $CLJS.Gc(function(){return tU},$CLJS.od(KM,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.CF,"null"],null),null),$CLJS.od(SQ,
new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sP,"metabase/mbql/schema.cljc",61,$CLJS.RF,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.RF,$CLJS.ax,$CLJS.U($CLJS.Qj,bO),$CLJS.gj,$CLJS.U($CLJS.Qj,$CLJS.U(kR,LP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(tU)?tU.H:null])):null));return $CLJS.n(a)?a:sP}(),tU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&
"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof IU?new $CLJS.Gc(function(){return IU},$CLJS.od(Kja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dG,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sR,"metabase/mbql/schema.cljc",
68,$CLJS.vG,1,979,979,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vG,iR,$CLJS.U($CLJS.Qj,aR)],null),$CLJS.Kc,"Schema for a valid median clause.",$CLJS.n(IU)?IU.H:null])):null));return $CLJS.n(a)?a:sR}(),IU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof JU?new $CLJS.Gc(function(){return JU},$CLJS.od(eja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,
lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dG,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QQ,"metabase/mbql/schema.cljc",72,$CLJS.TF,1,982,982,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.TF,iR,$CLJS.U($CLJS.Qj,aR),$CLJS.TF,$CLJS.U($CLJS.Qj,UQ)],null),$CLJS.Kc,"Schema for a valid percentile clause.",$CLJS.n(JU)?JU.H:null])):
null));return $CLJS.n(a)?a:QQ}(),JU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof HU?new $CLJS.Gc(function(){return HU},$CLJS.od(iia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),CM,"metabase/mbql/schema.cljc",77,$CLJS.OF,1,976,976,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OF,iR,$CLJS.U($CLJS.Qj,aR)],null),$CLJS.Kc,"Schema for a valid var clause.",$CLJS.n(HU)?HU.H:null])):null));return $CLJS.n(a)?a:CM}(),HU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof wU?new $CLJS.Gc(function(){return wU},$CLJS.od(Vha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,
$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,mR,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.CF,"null"],null),null),$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cP,"metabase/mbql/schema.cljc",74,$CLJS.iG,1,947,947,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iG,$CLJS.hG,$CLJS.U($CLJS.Qj,$CLJS.U(kR,IN))],null),$CLJS.Kc,"Schema for a valid cum-count clause.",
$CLJS.n(wU)?wU.H:null])):null));return $CLJS.n(a)?a:cP}(),wU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof vU?new $CLJS.Gc(function(){return vU},$CLJS.od(wia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([jP,$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,mR,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.CF,"null"],null),null),
$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.xi,"metabase/mbql/schema.cljc",70,$CLJS.Qx,1,946,946,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qx,$CLJS.hG,$CLJS.U($CLJS.Qj,$CLJS.U(kR,IN))],null),$CLJS.Kc,"Schema for a valid count clause.",$CLJS.n(vU)?vU.H:null])):null));return $CLJS.n(a)?a:$CLJS.xi}(),vU],null)]))],null)],null));
$CLJS.X(eQ,fM($CLJS.xM,$CLJS.H(["aggregation",NQ,"options",new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.pt,":aggregation-options options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),rS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mE,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),rS],null)],null)])));
$CLJS.X(jR,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.pt,"aggregation clause or numeric expression",$CLJS.Oi,function(a){return $CLJS.n(eM($CLJS.xM,a))?$CLJS.xM:aO}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eQ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[aO,NQ],null)],null));$CLJS.X(GM,fM($CLJS.eG,$CLJS.H(["field",SS])));var LU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GM],null);$CLJS.X(fP,fM($CLJS.YF,$CLJS.H(["field",SS])));
MU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fP],null);
wka=gM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof LU?new $CLJS.Gc(function(){return LU},$CLJS.od(Oja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
MO,"metabase/mbql/schema.cljc",15,$CLJS.eG,1,1049,1049,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eG,$CLJS.hG,$CLJS.U($CLJS.Qj,CR)],null),$CLJS.Kc,"Schema for a valid asc clause.",$CLJS.n(LU)?LU.H:null])):null));return $CLJS.n(a)?a:MO}(),LU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof MU?new $CLJS.Gc(function(){return MU},$CLJS.od(Fia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,
$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IQ,"metabase/mbql/schema.cljc",16,$CLJS.YF,1,1050,1050,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YF,$CLJS.hG,$CLJS.U($CLJS.Qj,CR)],null),$CLJS.Kc,"Schema for a valid desc clause.",$CLJS.n(MU)?MU.H:null])):null));return $CLJS.n(a)?a:IQ}(),MU],null)]));
NU=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.os,$CLJS.JM,$CLJS.yN,$CLJS.BF,$CLJS.Rt,$CLJS.uB,$CLJS.EP],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,rS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mE,rS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$i,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),rS],null)],null);
xka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,NU,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.JM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vM,rS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WP,uS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XP,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),uS],null)],null)],null);
yka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,NU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.yN],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fR,uS],null)],null)],null);
OU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,NU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.li],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$N,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Gs],null)],null)],null);zka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tR],null);
Aka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,OU,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.BF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BF,PS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TN,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bN],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gj,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,$CLJS.Yi,$CLJS.li],
null)],null)],null)],null)],null);$CLJS.PU=new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.EP,null,$CLJS.Rt,null,$CLJS.Gs,null,$CLJS.uB,null],null),null);Bka=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.os],null),$CLJS.PU);
$CLJS.X(BQ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.Oi,$CLJS.nj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BF,Aka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JM,xka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yN,yka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$o,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,OU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,Bka],null)],null)],null)],null)],null));
var QU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JQ,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,rS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BQ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.pt,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Qe(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,
$CLJS.T.h(b))},a)}],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oO,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,rS],null)],null)],null),Cka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,QU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EF,$CLJS.li],null)],null)],null),RU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iQ],null),SU=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.Oi,function(a){return $CLJS.n($CLJS.fE($CLJS.xd,
$CLJS.lQ)(a))?$CLJS.lQ:$CLJS.lE}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,QU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lQ,$CLJS.li],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lE,RU],null)],null),TU=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,rS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eD,sS],null),new $CLJS.P(null,2,5,$CLJS.Q,[mja,rS],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.GO,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,tS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VN,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.ik],null)],null)],null),UU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,oka,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,new $CLJS.h(null,1,[$CLJS.pt,"'card__\x3cid\x3e' string Table ID"],null),/^card__[1-9]\d*$/],null)],null),Dka=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.os],null),
new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.gF,null,$CLJS.QE,null,$CLJS.$E,null,$CLJS.dF,null],null),null)),VU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rR],null);
$CLJS.X(kO,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hP,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),UU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LQ,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),SU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TR,$CLJS.UT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CE,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),Dka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RE,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.pt,"Valid join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,$CLJS.vy,$CLJS.dA],null),VU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VE,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),rS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aL,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,wS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UO,new $CLJS.h(null,
1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,TU],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.pt,"Joins must have either a `source-table` or `source-query`, but not both."],null),$CLJS.fE($CLJS.yA($CLJS.hP,$CLJS.LQ),$CLJS.Te($CLJS.fE($CLJS.hP,$CLJS.LQ)))],null)],null));var Eka=$CLJS.Q,WU;var XU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kO],null)],null);
if($CLJS.wd(XU)&&$CLJS.E.g($CLJS.A(XU),$CLJS.Aj)){var YU=$CLJS.y(XU);$CLJS.A(YU);var ZU=$CLJS.B(YU),Fka=$CLJS.xd($CLJS.A(ZU))?ZU:$CLJS.ee(null,ZU),$U=$CLJS.y(Fka),Gka=$CLJS.A($U),Hka=$CLJS.B($U);WU=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,$CLJS.R.j(Gka,$CLJS.Zn,1)],null),Hka)}else WU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,XU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.pt,"non-empty"],null),$CLJS.y],null)],null);
$CLJS.X(mN,new $CLJS.P(null,3,5,Eka,[$CLJS.xs,WU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.pt,"All join aliases must be unique."],null),function(a){return $CLJS.RL($CLJS.lf($CLJS.Wa,$CLJS.hf.g($CLJS.VE,a)))}],null)],null));var Ika=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mN],null);
$CLJS.X(rR,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pl,new $CLJS.h(null,1,[$CLJS.pt,"Distinct, non-empty sequence of Field clauses"],null),hM(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.Zn,1],null),$CLJS.QS],null))],null));
$CLJS.X(iQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LQ,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),SU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hP,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),UU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XF,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.Zn,1],null),$CLJS.cT],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WF,new $CLJS.h(null,1,
[$CLJS.Ks,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.Zn,1],null),$CLJS.QS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bQ,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,rS,$CLJS.uU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RE,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),VU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MF,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.UT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oS,new $CLJS.h(null,
1,[$CLJS.Ks,!0],null),vS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RR,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),hM(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.Zn,1],null),wka],null))],null),new $CLJS.P(null,3,5,$CLJS.Q,[mQ,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[mQ,uS],null),new $CLJS.P(null,2,5,$CLJS.Q,[nia,uS],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GN,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),Ika],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UO,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,TU],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.pt,"Query must specify either `:source-table` or `:source-query`, but not both."],null),function(a){return $CLJS.E.g(1,$CLJS.D($CLJS.em(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LQ,$CLJS.hP],null))))}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,
new $CLJS.h(null,1,[$CLJS.pt,"Fields specified in `:breakout` should not be specified in `:fields`; this is implied."],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.WF);b=$CLJS.J.g(b,$CLJS.RE);return $CLJS.td($CLJS.fu.g($CLJS.fh(a),$CLJS.fh(b)))}],null)],null));
$CLJS.aV=$CLJS.Ig([$CLJS.AG,$CLJS.EP,gO,SN,VO,fS,BO,$CLJS.eS,$CLJS.Rt,AO,QM,YQ,UR,$CLJS.fN,QR,dO,$CLJS.$O,yO,$CLJS.$i,$CLJS.xO,yM,$CLJS.CN,$CLJS.wO,zP,$CLJS.Gs,$CLJS.uB,sO],[new $CLJS.h(null,1,[LO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.AG,null,$CLJS.EP,null,$CLJS.Rt,null,$CLJS.Gs,null,$CLJS.uB,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,$CLJS.EP,LO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.AG,null,$CLJS.EP,null,$CLJS.eS,null,$CLJS.$O,null,$CLJS.$i,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.nj,$CLJS.Zj,$CLJS.uE,gR,LO,new $CLJS.ah(null,new $CLJS.h(null,1,[gO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.nj,jS,$CLJS.uE,gR,LO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.AG,null,SN,null,$CLJS.Rt,null,$CLJS.$i,null,yM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.nj,$CLJS.Zj,$CLJS.uE,UM,LO,new $CLJS.ah(null,new $CLJS.h(null,1,[VO,null],null),null)],null),new $CLJS.h(null,1,[LO,new $CLJS.ah(null,new $CLJS.h(null,1,[fS,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.nj,
jS,$CLJS.uE,Eja,LO,new $CLJS.ah(null,new $CLJS.h(null,1,[BO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,$CLJS.EP,LO,new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.eS,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,jS,LO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.AG,null,SN,null,$CLJS.Rt,null,$CLJS.$i,null,yM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.nj,jS,$CLJS.uE,UM,LO,new $CLJS.ah(null,new $CLJS.h(null,1,[AO,null],null),null)],null),new $CLJS.h(null,1,[LO,new $CLJS.ah(null,
new $CLJS.h(null,1,[QM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.nj,$CLJS.Zj,$CLJS.uE,gR,LO,new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.AG,null,fS,null,QM,null,YQ,null,yO,null,$CLJS.$i,null,yM,null,$CLJS.uB,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.nj,$CLJS.Zj,$CLJS.uE,UM,LO,new $CLJS.ah(null,new $CLJS.h(null,1,[UR,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,$CLJS.EP,LO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.eS,null,$CLJS.fN,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.nj,$CLJS.Zj,$CLJS.uE,UM,LO,new $CLJS.ah(null,new $CLJS.h(null,1,[QR,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.nj,$CLJS.Zj,$CLJS.uE,UM,LO,new $CLJS.ah(null,new $CLJS.h(null,1,[dO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,$CLJS.EP,LO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.AG,null,$CLJS.EP,null,$CLJS.eS,null,$CLJS.$O,null,$CLJS.$i,null],null),null)],null),new $CLJS.h(null,1,[LO,new $CLJS.ah(null,new $CLJS.h(null,1,[yO,null],null),null)],null),new $CLJS.h(null,1,[LO,
new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.$i,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,$CLJS.EP,LO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.eS,null,$CLJS.xO,null],null),null)],null),new $CLJS.h(null,1,[LO,new $CLJS.ah(null,new $CLJS.h(null,1,[yM,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,$CLJS.EP,LO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.eS,null,$CLJS.CN,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,$CLJS.EP,LO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.eS,
null,$CLJS.wO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.nj,jS,$CLJS.uE,gR,LO,new $CLJS.ah(null,new $CLJS.h(null,1,[zP,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,$CLJS.Gs,LO,new $CLJS.ah(null,new $CLJS.h(null,3,[$CLJS.AG,null,$CLJS.$i,null,$CLJS.Gs,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,$CLJS.Zj,LO,new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.AG,null,fS,null,QM,null,YQ,null,yO,null,$CLJS.$i,null,yM,null,$CLJS.uB,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.nj,
jS,$CLJS.uE,UM,LO,new $CLJS.ah(null,new $CLJS.h(null,1,[sO,null],null),null)],null)]);$CLJS.X(tR,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.pt,"valid parameter type"],null)],null),$CLJS.Xg($CLJS.aV)));$CLJS.X(bN,$CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.pt,"valid template tag widget type"],null),$CLJS.dA],null),$CLJS.Xg($CLJS.aV)));
$CLJS.X(GQ,fM(eia,$CLJS.H(["tag-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,rS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$i,rS],null)],null)],null)])));var bV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GQ],null);$CLJS.X(BM,fM($CLJS.BF,$CLJS.H(["target",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,$CLJS.QS,bV],null)])));var cV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BM],null);$CLJS.X(wN,fM(vO,$CLJS.H(["target",bV])));
var dV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wN],null),Jka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,$CLJS.QS,gM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof cV?new $CLJS.Gc(function(){return cV},$CLJS.od(cja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rQ,"metabase/mbql/schema.cljc",21,$CLJS.BF,1,1612,1612,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BF,$CLJS.tJ,$CLJS.U($CLJS.Qj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,IN,KO],null))],null),$CLJS.Kc,"Schema for a valid dimension clause.",$CLJS.n(cV)?cV.H:null])):null));return $CLJS.n(a)?a:rQ}(),cV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lP.h($CLJS.pd("undefined"!==typeof $CLJS.iM&&
"undefined"!==typeof jM&&"undefined"!==typeof kM&&"undefined"!==typeof dV?new $CLJS.Gc(function(){return dV},$CLJS.od(sja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.pk,$CLJS.vi,lP,$CLJS.Ti,$CLJS.oj,$CLJS.yk,hN,$CLJS.ui,$CLJS.kk,$CLJS.ok],[$CLJS.od(SQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kS,"metabase/mbql/schema.cljc",20,vO,1,1615,1615,new $CLJS.P(null,3,5,$CLJS.Q,[vO,
$CLJS.tJ,$CLJS.U($CLJS.Qj,KO)],null),$CLJS.Kc,"Schema for a valid variable clause.",$CLJS.n(dV)?dV.H:null])):null));return $CLJS.n(a)?a:kS}(),dV],null)]))],null),bM=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iN],null);
$CLJS.X(iN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.xs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.pt,"Query must specify either `:native` or `:query`, but not both."],null),$CLJS.fE($CLJS.yA($CLJS.lQ,$CLJS.EF),$CLJS.Te($CLJS.fE($CLJS.lQ,$CLJS.EF)))],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.pt,"Native queries must specify `:native`; MBQL queries must specify `:query`."],null),function(b){var c=
$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.lQ);var d=$CLJS.J.g(c,$CLJS.EF);c=$CLJS.J.g(c,$CLJS.nj);c=c instanceof $CLJS.M?c.T:null;switch(c){case "native":return b;case "query":return d;default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}}],null)],null)}(new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.pt,"valid Database ID"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eL],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZK],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,$CLJS.EF,$CLJS.lQ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lQ,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),Cka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EF,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),RU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xN,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,
8,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,zka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$i,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),rS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tJ,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),Jka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.li],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),rS],null),new $CLJS.P(null,3,5,$CLJS.Q,[kia,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),
rS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.li],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cL,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[cka,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),ES],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[bja,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[rP,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),vS],null),new $CLJS.P(null,3,5,$CLJS.Q,[aN,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),vS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.pt,"max-results-bare-rows must be less or equal to than max-results"],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,rP);b=$CLJS.J.g(b,aN);return $CLJS.Va($CLJS.n(a)?b:a)?!0:a>=b}],null)],
null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Sia,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[Nja,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Gs],null),new $CLJS.P(null,3,5,$CLJS.Q,[gia,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Gs],null),new $CLJS.P(null,3,5,$CLJS.Q,[Qha,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Gs],null),new $CLJS.P(null,3,5,$CLJS.Q,[mia,new $CLJS.h(null,1,[$CLJS.Ks,
!0],null),$CLJS.Gs],null),new $CLJS.P(null,3,5,$CLJS.Q,[Fja,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.Gs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Sha,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.Gs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Tja,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.Gs],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fy,new $CLJS.h(null,
1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sN,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,18,5,$CLJS.Q,[$CLJS.os,rja,oja,$CLJS.oO,qia,mka,tja,Iia,dja,Aia,hia,Eia,Cja,Qia,bia,ria,lka,ika],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Ija,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,uS],null)],null),new $CLJS.P(null,3,5,
$CLJS.Q,[Nia,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,uS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fR,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,nka],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[dka,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,rS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Pia,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,uS],null)],null),
new $CLJS.P(null,3,5,$CLJS.Q,[bka,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,$CLJS.li,$CLJS.li],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Jia,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,uS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rO,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Wi,$CLJS.li,$CLJS.li],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MK,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.li],null)],null)],null)],null)],null)],null)),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.pt,"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)"],null),$CLJS.Te($CLJS.UO)],null)],null));var eV=$CLJS.aM(bM);
(function(){var a=Lha();return function(b){if($CLJS.n(eV.h?eV.h(b):eV.call(null,b)))return b;b=a.h?a.h(b):a.call(null,b);var c=$CLJS.YL(b);throw $CLJS.hi($CLJS.hE("Invalid query: {0}",$CLJS.H([$CLJS.Mh.l($CLJS.H([c]))])),new $CLJS.h(null,2,[$CLJS.By,c,Xia,b],null));}})();