var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.schema.js");require("./metabase.util.malli.registry.js");
'use strict';var fV,gV,hV,jV,kV,lV,mV,nV,oV,pV;fV=new $CLJS.M("metabase.lib.schema.template-tag","widget-type","metabase.lib.schema.template-tag/widget-type",2021141560);gV=new $CLJS.M("metabase.lib.schema.template-tag","raw-value","metabase.lib.schema.template-tag/raw-value",-530604923);hV=new $CLJS.M("metabase.lib.schema.template-tag","template-tag","metabase.lib.schema.template-tag/template-tag",-1178540449);
$CLJS.iV=new $CLJS.M("metabase.lib.schema.template-tag","template-tag-map","metabase.lib.schema.template-tag/template-tag-map",1471181299);jV=new $CLJS.M("metabase.lib.schema.template-tag","type","metabase.lib.schema.template-tag/type",1926679295);kV=new $CLJS.M("metabase.lib.schema.template-tag","field-filter","metabase.lib.schema.template-tag/field-filter",1376344463);lV=new $CLJS.M("metabase.lib.schema.template-tag","snippet","metabase.lib.schema.template-tag/snippet",190689821);
mV=new $CLJS.M("metabase.lib.schema.template-tag","common","metabase.lib.schema.template-tag/common",1713198790);nV=new $CLJS.M("metabase.lib.schema.template-tag","value.common","metabase.lib.schema.template-tag/value.common",-974864959);oV=new $CLJS.M("metabase.lib.schema.template-tag","raw-value.type","metabase.lib.schema.template-tag/raw-value.type",-805651412);pV=new $CLJS.M("metabase.lib.schema.template-tag","source-query","metabase.lib.schema.template-tag/source-query",479361081);$CLJS.X(fV,$CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.pt,"Valid template tag :widget-type"],null),$CLJS.dA],null),$CLJS.Xg($CLJS.aV)));$CLJS.X(jV,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.os,$CLJS.JM,$CLJS.yN,$CLJS.BF,$CLJS.Rt,$CLJS.uB,$CLJS.EP],null));
$CLJS.X(mV,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.rE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mE,$CLJS.rE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$i,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,$CLJS.rE,$CLJS.Fs],null)],null)],null));
$CLJS.X(nV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Dl],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$N,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Gs],null)],null)],null));
$CLJS.X(kV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nV],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.BF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TN,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gj,new $CLJS.h(null,1,[$CLJS.Ks,!0],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.ik],null)],null)],null)],null));
$CLJS.X(lV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mV],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.JM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vM,$CLJS.rE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WP,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.gL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XP,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.ZK],null)],null)],null));
$CLJS.X(pV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.yN],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fR,$CLJS.YK],null)],null)],null));$CLJS.X(oV,$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.os],null),$CLJS.PU));
$CLJS.X(gV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oV],null)],null)],null)],null));
$CLJS.X(hV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jV],null)],null)],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.Oi,$CLJS.nj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yN,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$o,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gV],null)],null)],null)],null));
$CLJS.X($CLJS.iV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,$CLJS.rE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.pt,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Qe(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.T.h(b))},a)}],null)],null));