var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var l7,n7,o7,rra,sra,tra,r7,s7,u7,v7,ura,w7,vra,wra;l7=function(a){var b=new $CLJS.h(null,3,[$CLJS.yE,$CLJS.p.h($CLJS.IE()),$CLJS.pB,$CLJS.pB.h(a),$CLJS.Fi,$CLJS.yA($CLJS.Fi,$CLJS.pB)(a)],null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mB,b,$CLJS.yA($CLJS.qE,$CLJS.T)(a)],null)};$CLJS.m7=function(a){return $CLJS.B5.h(a)};n7=function(a){return $CLJS.R.j(a,$CLJS.Xt,$CLJS.S4)};
o7=function(a,b){return $CLJS.E.g($CLJS.lE.h(a),$CLJS.em(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CE,$CLJS.kE,$CLJS.BE],null)))};rra=function(a){return $CLJS.p1(a,new $CLJS.h(null,1,[$CLJS.CE,$CLJS.zh],null))};
sra=function(a,b){var c=$CLJS.Re(function(e){return $CLJS.uH(function(f){return $CLJS.E.g($CLJS.J.g(f,e),a)},b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LZ,$CLJS.T],null));if($CLJS.n(c))return c;c=$CLJS.IA($CLJS.Dy);if($CLJS.n($CLJS.HA("metabase.lib.field",c))){var d=$CLJS.hE("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.H([$CLJS.Mh.l($CLJS.H([a])),$CLJS.Mh.l($CLJS.H([$CLJS.Rk.g($CLJS.LZ,b)]))]));d instanceof Error?$CLJS.GA("metabase.lib.field",c,$CLJS.zw(),d):$CLJS.GA("metabase.lib.field",
c,$CLJS.zw.l($CLJS.H([d])),null)}return null};
tra=function(a,b,c){if($CLJS.n(p7))return null;var d=p7;p7=!0;try{var e=$CLJS.a2(a,b),f=$CLJS.n(e)?$CLJS.HW(a,e):$CLJS.HW(a,b),k=function(){var m=$CLJS.q7.h(f);if($CLJS.n(m))return m;m=$CLJS.zA(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qV,$CLJS.EW],null));if($CLJS.n(m))return m;m=$CLJS.n(function(){var u=$CLJS.QV.h(f);if($CLJS.n(u))return u;u=$CLJS.hP.h(f);if($CLJS.n(u))return u;u=$CLJS.bQ.h(f);return $CLJS.n(u)?u:$CLJS.RE.h(f)}())?$CLJS.L0.j(a,b,f):null;if($CLJS.n(m))return m;m=$CLJS.IA($CLJS.Dy);if($CLJS.n($CLJS.HA("metabase.lib.field",
m))){var t=$CLJS.hE("Cannot resolve column {0}: stage has no metadata",$CLJS.H([$CLJS.Mh.l($CLJS.H([c]))]));return t instanceof Error?$CLJS.GA("metabase.lib.field",m,$CLJS.zw(),t):$CLJS.GA("metabase.lib.field",m,$CLJS.zw.l($CLJS.H([t])),null)}return null}(),l=function(){var m=$CLJS.y(k);return m?sra(c,k):m}();return $CLJS.n(l)?$CLJS.n(e)?$CLJS.R.j($CLJS.R.j($CLJS.B3($CLJS.Hk.l(l,$CLJS.$i,$CLJS.H([$CLJS.bL,$CLJS.RW,$CLJS.LW])),null),$CLJS.T,function(){var m=$CLJS.LZ.h(l);return $CLJS.n(m)?m:$CLJS.T.h(l)}()),
$CLJS.PK,$CLJS.zZ):l:null}finally{p7=d}};
r7=function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.wP);var f=$CLJS.I(c,2,null);c=$CLJS.gl.l($CLJS.H([function(){var k=$CLJS.pB.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.pB,k],null):null}(),function(){var k=$CLJS.yA($CLJS.Fi,$CLJS.pB)(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.Fi,k],null):null}(),function(){var k=$CLJS.xR.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.RW,k],null):null}(),function(){var k=$CLJS.nG.h(e);return $CLJS.n(k)?new $CLJS.h(null,
1,[$CLJS.LW,k],null):null}(),$CLJS.Fd(f)?function(){var k=$CLJS.f2(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Xt,$CLJS.NK,$CLJS.T,f],null)}():$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.Xt,$CLJS.NK,$CLJS.T,f],null):function(){var k=tra(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Xt,$CLJS.NK,$CLJS.T,f],null)}()]));return $CLJS.n(d)?$CLJS.B3(c,d):c};s7=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.LW);return $CLJS.n($CLJS.n(b)?$CLJS.Id($CLJS.rH,b):b)?$CLJS.xj:$CLJS.yA($CLJS.Fi,$CLJS.pB)(a)};
$CLJS.t7=function(a,b,c,d){$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null),f=$CLJS.O(e),k=$CLJS.J.g(f,$CLJS.yE),l=$CLJS.J.g(f,$CLJS.pB),m=$CLJS.J.g(f,$CLJS.xR),t=$CLJS.J.g(f,$CLJS.Fi);e=$CLJS.J.g(f,$CLJS.wP);var u=$CLJS.J.g(f,$CLJS.HN),v=$CLJS.J.g(f,$CLJS.nG),x=$CLJS.gl.l;k=new $CLJS.h(null,2,[$CLJS.Xt,$CLJS.NK,$CLJS.d1,k],null);f=$CLJS.mE.h(f);a=$CLJS.n(f)?f:$CLJS.a0.j(a,b,d);c=x.call($CLJS.gl,$CLJS.H([k,c,new $CLJS.h(null,1,[$CLJS.mE,a],null)]));c=$CLJS.n(t)?$CLJS.R.j(c,$CLJS.Fi,t):c;l=$CLJS.n(l)?$CLJS.R.j(c,
$CLJS.pB,l):c;v=$CLJS.n(v)?$CLJS.R.j(l,$CLJS.LW,v):l;m=$CLJS.n(m)?$CLJS.R.j(v,$CLJS.RW,m):v;u=$CLJS.n(u)?$CLJS.R.j(m,$CLJS.aL,u):m;return $CLJS.n(e)?$CLJS.B3(u,e):u};u7=function(a,b,c){return $CLJS.n($CLJS.Re(function(d){return $CLJS.E.g($CLJS.si.h(d),c)},a))?$CLJS.Rk.g(function(d){var e=$CLJS.Id(d,b)?$CLJS.Hk.l(d,d,$CLJS.H([b])):d;return $CLJS.E.g($CLJS.si.h(d),c)?$CLJS.R.j(e,b,!0):e},a):a};
v7=function(a){var b=$CLJS.n($CLJS.K1.h(a))?null:function(){var d=$CLJS.PK.h(a),e=new $CLJS.ah(null,new $CLJS.h(null,3,[$CLJS.OZ,null,$CLJS.UZ,null,$CLJS.zZ,null],null),null);return e.h?e.h(d):e.call(null,d)}(),c=$CLJS.gl.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.yE,$CLJS.p.h($CLJS.IE()),$CLJS.pB,$CLJS.pB.h(a),$CLJS.Fi,s7(a)],null),function(){var d=$CLJS.D1(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.wP,d],null):null}(),function(){var d=$CLJS.LW.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.nG,d],
null):null}(),function(){var d=$CLJS.PW.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.PW,d],null):null}(),function(){var d=$CLJS.RW.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.xR,d],null):null}(),function(){var d=$CLJS.aL.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.HN,d],null):null}()]));b=($CLJS.n(b)?$CLJS.yA($CLJS.LZ,$CLJS.T):$CLJS.yA($CLJS.$i,$CLJS.T))(a);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,c,b],null)};
ura=function(a,b){return $CLJS.lf(function(c){return $CLJS.E.g($CLJS.PK.h(c),$CLJS.FZ)},$CLJS.L0.v(a,b,$CLJS.HW(a,b),new $CLJS.h(null,3,[$CLJS.t0,!1,$CLJS.z0,!0,$CLJS.p0,!1],null)))};w7=new $CLJS.M(null,"parent-id","parent-id",-1400729131);$CLJS.q7=new $CLJS.M("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);vra=new $CLJS.M(null,"earliest","earliest",-1928154382);wra=new $CLJS.M(null,"latest","latest",24323665);var zra;$CLJS.q1.m(null,$CLJS.hG,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zh.h(b),$CLJS.p1(c,new $CLJS.h(null,2,[$CLJS.nG,$CLJS.zh,$CLJS.xR,rra],null)),a],null)});var p7=!1,x7=function x7(a,b){var d=$CLJS.w1(a,w7.h(b));a=$CLJS.n(w7.h(d))?x7.g?x7.g(a,d):x7.call(null,a,d):d;a=$CLJS.O(a);var e=$CLJS.J.g(a,$CLJS.T);return $CLJS.Sk.j(b,$CLJS.T,function(f){return[$CLJS.p.h(e),".",$CLJS.p.h(f)].join("")})};
$CLJS.E0.m(null,$CLJS.NK,function(a,b,c){return s7(c)});$CLJS.E0.m(null,$CLJS.hG,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.nG);$CLJS.I(c,2,null);c=r7(a,b,c);c=$CLJS.n(d)?$CLJS.R.j(c,$CLJS.LW,d):c;return $CLJS.F0.j(a,b,c)});$CLJS.G0.m(null,$CLJS.NK,function(a,b,c){a=$CLJS.O(c);b=$CLJS.J.g(a,$CLJS.T);return $CLJS.R.j(a,$CLJS.T,b)});$CLJS.G0.m(null,$CLJS.hG,function(a,b,c){var d=r7(a,b,c);b=$CLJS.t7(a,b,d,c);return $CLJS.n(w7.h(b))?x7(a,b):b});
$CLJS.A0.m(null,$CLJS.NK,function(a,b,c,d){c=$CLJS.O(c);var e=$CLJS.J.g(c,$CLJS.mE),f=$CLJS.J.g(c,$CLJS.T),k=$CLJS.J.g(c,$CLJS.si),l=$CLJS.J.g(c,$CLJS.RW),m=$CLJS.J.g(c,$CLJS.XZ),t=$CLJS.J.g(c,$CLJS.aL),u=$CLJS.J.g(c,$CLJS.bL);e=$CLJS.n(e)?e:"string"===typeof f?$CLJS.B1.g($CLJS.vB,f):$CLJS.p.h(f);$CLJS.E.g(d,$CLJS.b0)&&-1==e.indexOf(" → ")?($CLJS.n(t)?(t=$CLJS.w1(a,t),$CLJS.n(t)?a=(0,$CLJS.ma)($CLJS.CA($CLJS.mE.h($CLJS.d0.j(a,b,t)),$CLJS.TW,"")):(u=$CLJS.C1(a,u),a=$CLJS.a0.v(a,b,u,d))):a=null,m=$CLJS.n(a)?
a:$CLJS.n(m)?m:$CLJS.D1(c)):m=null;m=$CLJS.n(m)?[$CLJS.p.h(m)," → ",$CLJS.p.h(e)].join(""):e;return $CLJS.n(k)?(c=$CLJS.ZA($CLJS.CA($CLJS.gh(k),"-"," ")),$CLJS.fa.j?$CLJS.fa.j("%s: %s",m,c):$CLJS.fa.call(null,"%s: %s",m,c)):$CLJS.n(l)?(c=$CLJS.y5(l,c),$CLJS.fa.j?$CLJS.fa.j("%s: %s",m,c):$CLJS.fa.call(null,"%s: %s",m,c)):m});
$CLJS.A0.m(null,$CLJS.hG,function(a,b,c,d){$CLJS.I(c,0,null);var e=$CLJS.I(c,1,null),f=$CLJS.O(e);e=$CLJS.J.g(f,$CLJS.xR);var k=$CLJS.J.g(f,$CLJS.wP),l=$CLJS.J.g(f,$CLJS.nG);f=$CLJS.J.g(f,$CLJS.HN);$CLJS.I(c,2,null);c=r7(a,b,c);c=$CLJS.n(k)?$CLJS.R.j(c,$CLJS.XZ,k):c;l=$CLJS.n(l)?$CLJS.R.j(c,$CLJS.si,l):c;e=$CLJS.n(e)?$CLJS.R.j(l,$CLJS.RW,e):l;e=$CLJS.n(f)?$CLJS.R.j(e,$CLJS.aL,f):e;return $CLJS.n(e)?$CLJS.a0.v(a,b,e,d):$CLJS.JE("[Unknown Field]")});
$CLJS.B0.m(null,$CLJS.NK,function(a,b,c){a=$CLJS.O(c);return $CLJS.J.g(a,$CLJS.T)});$CLJS.B0.m(null,$CLJS.hG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=r7(a,b,c);return $CLJS.n(c)?$CLJS.C0.j(a,b,c):"unknown_field"});
$CLJS.H0.m(null,$CLJS.NK,function(a,b,c){return $CLJS.gl.l($CLJS.H([function(){var d=$CLJS.nI($CLJS.H0,$CLJS.ci);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),$CLJS.E.g($CLJS.PK.h(c),$CLJS.OZ)?function(){var d=$CLJS.WZ.h(c);return $CLJS.n(d)?(d=$CLJS.X_(a,d),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.QW,new $CLJS.h(null,2,[$CLJS.T,$CLJS.T.h(d),$CLJS.mE,$CLJS.T.h(d)],null)],null):null):null}():null]))});$CLJS.V0.m(null,$CLJS.hG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);return $CLJS.nG.h(b)});
$CLJS.V0.m(null,$CLJS.NK,function(a){return $CLJS.LW.h(a)});
$CLJS.U0.m(null,$CLJS.hG,function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(b)){var d=$CLJS.Id($CLJS.rH,b),e=$CLJS.pC($CLJS.PW,$CLJS.Fi,$CLJS.pB)(c);c=$CLJS.R.l(c,$CLJS.nG,b,$CLJS.H([$CLJS.Fi,d?$CLJS.xj:e,$CLJS.PW,e]));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,c,a],null)}b=$CLJS.PW.h(c);c=$CLJS.n(b)?$CLJS.Hk.g($CLJS.R.j(c,$CLJS.Fi,b),$CLJS.PW):c;c=$CLJS.Hk.g(c,$CLJS.nG);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,c,a],null)});
$CLJS.U0.m(null,$CLJS.NK,function(a,b){return $CLJS.n(b)?$CLJS.R.l(a,$CLJS.LW,b,$CLJS.H([$CLJS.PW,$CLJS.pC($CLJS.PW,$CLJS.Fi,$CLJS.pB)(a)])):$CLJS.Hk.l(a,$CLJS.LW,$CLJS.H([$CLJS.PW]))});$CLJS.X0.m(null,$CLJS.hG,function(a,b,c){return $CLJS.Y0.j(a,b,r7(a,b,c))});
$CLJS.X0.m(null,$CLJS.NK,function(a,b,c){if($CLJS.Nk.g($CLJS.PK.h(c),$CLJS.FZ)){a=$CLJS.yA($CLJS.Fi,$CLJS.pB)(c);b=null==c?null:$CLJS.VN.h(c);if(null==b)var d=null;else try{var e=$CLJS.rD.h($CLJS.nj.h(b));if($CLJS.n(e)){var f=$CLJS.O(e),k=$CLJS.J.g(f,vra),l=$CLJS.J.g(f,wra),m=$CLJS.Fna.l($CLJS.H([$CLJS.S0.h(k),$CLJS.S0.h(l)]));d=isNaN(m)?null:$CLJS.n($CLJS.Or.g?$CLJS.Or.g(1,m):$CLJS.Or.call(null,1,m))?$CLJS.Ui:$CLJS.n($CLJS.Or.g?$CLJS.Or.g(31,m):$CLJS.Or.call(null,31,m))?$CLJS.Yt:$CLJS.n($CLJS.Or.g?
$CLJS.Or.g(365,m):$CLJS.Or.call(null,365,m))?$CLJS.pj:$CLJS.yj}else d=null}catch(t){if(t instanceof Error)d=null;else throw t;}e=$CLJS.rC(a,$CLJS.rD)?$CLJS.Nna:$CLJS.rC(a,$CLJS.SC)?$CLJS.Mna:$CLJS.rC(a,$CLJS.RD)?$CLJS.Lna:$CLJS.Cf;d=$CLJS.n(d)?u7(e,$CLJS.ci,d):e;return $CLJS.n($CLJS.LW.h(c))?u7(d,$CLJS.i0,$CLJS.LW.h(c)):d}return $CLJS.Cf});
$CLJS.B5.m(null,$CLJS.hG,function(a){var b=null==a?null:$CLJS.FE(a);b=null==b?null:$CLJS.xR.h(b);return null==b?null:$CLJS.R.l(b,$CLJS.Xt,$CLJS.A5,$CLJS.H([$CLJS.z5,function(c,d){return r7(c,d,a)}]))});$CLJS.B5.m(null,$CLJS.NK,function(a){var b=null==a?null:$CLJS.RW.h(a);return null==b?null:$CLJS.R.l(b,$CLJS.Xt,$CLJS.A5,$CLJS.H([$CLJS.z5,$CLJS.Ue(a)]))});$CLJS.v5.m(null,$CLJS.hG,function(a,b){return $CLJS.GE(a,$CLJS.EE,$CLJS.H([$CLJS.xR,b]))});
$CLJS.v5.m(null,$CLJS.NK,function(a,b){return $CLJS.EE(a,$CLJS.RW,b)});$CLJS.C5.m(null,$CLJS.hG,function(a,b,c){return $CLJS.D5.j(a,b,r7(a,b,c))});
$CLJS.C5.m(null,$CLJS.NK,function(a,b,c){b=$CLJS.O(c);c=$CLJS.J.g(b,$CLJS.Fi);var d=$CLJS.J.g(b,$CLJS.VN),e=$CLJS.J.g(b,$CLJS.Vj);if($CLJS.Nk.g($CLJS.PK.h(b),$CLJS.FZ)){var f=function(){var m=null==a?null:$CLJS.u1($CLJS.U_(a));m=null==m?null:$CLJS.eA.h(m);return null==m?null:$CLJS.Id(m,$CLJS.xR)}(),k=$CLJS.zA(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,$CLJS.Pj],null)),l=$CLJS.m7(b);return function u(t){return new $CLJS.ne(null,function(){for(;;){var v=$CLJS.y(t);if(v){if($CLJS.Ad(v)){var x=$CLJS.lc(v),
z=$CLJS.D(x),C=$CLJS.qe(z);return function(){for(var K=0;;)if(K<z){var S=$CLJS.kd(x,K),V=C,Z=S;S=o7(S,l)?$CLJS.R.j(Z,$CLJS.i0,!0):Z;V.add(S);K+=1}else return!0}()?$CLJS.te($CLJS.ve(C),u($CLJS.mc(v))):$CLJS.te($CLJS.ve(C),null)}var G=$CLJS.A(v);return $CLJS.ee(function(){var K=G;return o7(G,l)?$CLJS.R.j(K,$CLJS.i0,!0):K}(),u($CLJS.Lc(v)))}return null}},null,null)}($CLJS.Va(function(){if($CLJS.n(f)){var t=$CLJS.Zn.h(k);return $CLJS.n(t)?$CLJS.jk.h(k):t}return f}())?null:$CLJS.rC(e,$CLJS.zj)?$CLJS.Rk.g(n7,
new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.x5(),new $CLJS.h(null,2,[$CLJS.mE,$CLJS.JE("Bin every 0.1 degrees"),$CLJS.lE,new $CLJS.h(null,2,[$CLJS.CE,$CLJS.BE,$CLJS.BE,.1],null)],null),new $CLJS.h(null,2,[$CLJS.mE,$CLJS.JE("Bin every 1 degree"),$CLJS.lE,new $CLJS.h(null,2,[$CLJS.CE,$CLJS.BE,$CLJS.BE,1],null)],null),new $CLJS.h(null,2,[$CLJS.mE,$CLJS.JE("Bin every 10 degrees"),$CLJS.lE,new $CLJS.h(null,2,[$CLJS.CE,$CLJS.BE,$CLJS.BE,10],null)],null),new $CLJS.h(null,2,[$CLJS.mE,$CLJS.JE("Bin every 20 degrees"),
$CLJS.lE,new $CLJS.h(null,2,[$CLJS.CE,$CLJS.BE,$CLJS.BE,20],null)],null)],null)):$CLJS.rC(c,$CLJS.Pj)&&!$CLJS.rC(e,$CLJS.ni)?$CLJS.Rk.g(n7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.x5(),new $CLJS.h(null,2,[$CLJS.mE,$CLJS.JE("10 bins"),$CLJS.lE,new $CLJS.h(null,2,[$CLJS.CE,$CLJS.kE,$CLJS.kE,10],null)],null),new $CLJS.h(null,2,[$CLJS.mE,$CLJS.JE("50 bins"),$CLJS.lE,new $CLJS.h(null,2,[$CLJS.CE,$CLJS.kE,$CLJS.kE,50],null)],null),new $CLJS.h(null,2,[$CLJS.mE,$CLJS.JE("100 bins"),$CLJS.lE,new $CLJS.h(null,2,
[$CLJS.CE,$CLJS.kE,$CLJS.kE,100],null)],null)],null)):null)}return $CLJS.Cf});$CLJS.qW.m(null,$CLJS.hG,function(a){return a});
$CLJS.qW.m(null,$CLJS.NK,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.PK);switch(b instanceof $CLJS.M?b.T:null){case "source/aggregations":return b=new $CLJS.h(null,2,[$CLJS.yE,$CLJS.p.h($CLJS.IE()),$CLJS.Fi,$CLJS.yA($CLJS.Fi,$CLJS.pB)(a)],null),a=$CLJS.d1.h(a),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XF,b,a],null);case "source/expressions":return l7(a);case "source/fields":case "source/breakouts":return $CLJS.n($CLJS.qE.h(a))?l7(a):v7(a);default:return v7(a)}});
$CLJS.xra=function(){function a(e,f,k){k=$CLJS.Fe($CLJS.Rk.g($CLJS.sW,k));var l=ura(e,f),m=$CLJS.eg.j($CLJS.bh,$CLJS.Xl(function(t){return $CLJS.l2.v(e,f,t,l)}),$CLJS.n(k)?k:$CLJS.Cf);m=$CLJS.Qk.g(m,l);k=$CLJS.n(k)?$CLJS.eg.j(k,$CLJS.hf.h($CLJS.sW),m):null;return $CLJS.SW.l(e,f,$CLJS.EE,$CLJS.H([$CLJS.RE,k]))}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return function(f,k){return d.j?d.j(f,k,e):d.call(null,f,k,e)}}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,
e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();$CLJS.y7=function(){function a(d,e){return $CLJS.RE.h($CLJS.HW(d,e))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.yra=function(){function a(d,e){var f=$CLJS.L0.v(d,e,$CLJS.HW(d,e),new $CLJS.h(null,3,[$CLJS.t0,!1,$CLJS.z0,!1,$CLJS.p0,!1],null)),k=$CLJS.y7.g(d,e);return $CLJS.td(k)?$CLJS.Rk.g(function(l){return $CLJS.R.j(l,$CLJS.MZ,!0)},f):$CLJS.z3(d,e,f,k)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
zra=function(){function a(d,e,f){var k=$CLJS.HW(d,e),l=$CLJS.E.g($CLJS.dC(f),$CLJS.XF)?$CLJS.J0:$CLJS.L0;k=l.j?l.j(d,e,k):l.call(null,d,e,k);return $CLJS.l2.v(d,e,f,k)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.Ara=function(){function a(d,e,f){f=$CLJS.vZ.j(d,e,f);return zra.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();