var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./medley.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var FY,ema,HY,fma,gma,hma,JY,jma,kma,lma,mma,MY,NY,OY,PY,RY,oma,SY,TY,pma,qma,rma,LY,ima,UY,VY,nma,XY;FY=function(a,b){var c=$CLJS.y(b);for(a=$CLJS.y($CLJS.bf(a,b));;)if(a)c=$CLJS.B(c),a=$CLJS.B(a);else return c};
ema=function(){return function(){function a(k,l,m){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj.j?$CLJS.nj.j(k,l,m):$CLJS.nj.call(null,k,l,m),$CLJS.ml.j?$CLJS.ml.j(k,l,m):$CLJS.ml.call(null,k,l,m)],null)}function b(k,l){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj.g?$CLJS.nj.g(k,l):$CLJS.nj.call(null,k,l),$CLJS.ml.g?$CLJS.ml.g(k,l):$CLJS.ml.call(null,k,l)],null)}function c(k){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj.h?$CLJS.nj.h(k):$CLJS.nj.call(null,k),$CLJS.ml.h?$CLJS.ml.h(k):$CLJS.ml.call(null,
k)],null)}function d(){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj.o?$CLJS.nj.o():$CLJS.nj.call(null),$CLJS.ml.o?$CLJS.ml.o():$CLJS.ml.call(null)],null)}var e=null,f=function(){function k(m,t,u,v){var x=null;if(3<arguments.length){x=0;for(var z=Array(arguments.length-3);x<z.length;)z[x]=arguments[x+3],++x;x=new $CLJS.w(z,0,null)}return l.call(this,m,t,u,x)}function l(m,t,u,v){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Se.N($CLJS.nj,m,t,u,v),$CLJS.Se.N($CLJS.ml,m,t,u,v)],null)}k.A=3;k.B=function(m){var t=
$CLJS.A(m);m=$CLJS.B(m);var u=$CLJS.A(m);m=$CLJS.B(m);var v=$CLJS.A(m);m=$CLJS.Lc(m);return l(t,u,v,m)};k.l=l;return k}();e=function(k,l,m,t){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,k);case 2:return b.call(this,k,l);case 3:return a.call(this,k,l,m);default:var u=null;if(3<arguments.length){u=0;for(var v=Array(arguments.length-3);u<v.length;)v[u]=arguments[u+3],++u;u=new $CLJS.w(v,0,null)}return f.l(k,l,m,u)}throw Error("Invalid arity: "+arguments.length);};e.A=
3;e.B=f.B;e.o=d;e.h=c;e.g=b;e.j=a;e.l=f.l;return e}()};
$CLJS.GY=function(a){return function(b){var c=$CLJS.$e(-1);return function(){function d(l,m){var t=c.Dd(null,c.Qb(null)+1);m=a.g?a.g(t,m):a.call(null,t,m);return null==m?l:b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=f;k.h=e;k.g=d;return k}()}};
HY=function(a){return $CLJS.TL($CLJS.nW)(a,$CLJS.Cf,$CLJS.Cf)};fma=function(a,b,c){return $CLJS.eb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return null==e?d:$CLJS.R.j(d,f,e)},null==b?a:$CLJS.R.j(a,$CLJS.XF,b),$CLJS.ft(2,2,c))};gma=function(a,b){var c=$CLJS.fm(a,$CLJS.JQ);if($CLJS.n(c)){var d=$CLJS.R.j;c=$CLJS.Fb(c);b=$CLJS.zt.g?$CLJS.zt.g(c,b):$CLJS.zt.call(null,c,b);a=d.call($CLJS.R,a,$CLJS.JQ,b)}return a};hma=function(a){return $CLJS.gf.g($CLJS.OV(a),$CLJS.PV(a))};
$CLJS.IY=function(a,b){return $CLJS.zW(a)&&$CLJS.E.g($CLJS.A(a),b)};JY=function(a,b,c){var d=new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.Ns,null,$CLJS.tl,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.uu(2,c):$CLJS.uu(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?FY(2,c):FY(1,c);var f=$CLJS.I(b,0,null);return $CLJS.y(e)?$CLJS.xX.j(a,e,function(k){var l=$CLJS.R.j(k,f,null);return $CLJS.zd(k)?$CLJS.eg.j($CLJS.Cf,$CLJS.Qk.h($CLJS.Al),l):$CLJS.xd(k)?$CLJS.FA($CLJS.Wa,l):l}):$CLJS.Hk.g(a,f)};
jma=function(a){return $CLJS.eb(function(b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);return JY(b,ima,d)},a,hma(a))};
kma=function(a){for(var b=0;;){var c=$CLJS.F($CLJS.qL.h(a),b);a:{var d=c;for(var e=$CLJS.Cf;;){var f=$CLJS.A($CLJS.hf.g(ema(),$CLJS.lf($CLJS.Pk.j(KY,$CLJS.A,$CLJS.ml),$CLJS.Rs.h(HY(d)))));if($CLJS.n(f)){var k=f;f=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);var l=JY(d,f,k),m=$CLJS.IA($CLJS.Dy);if($CLJS.n($CLJS.HA("metabase.lib.convert",m))){var t=$CLJS.zB.l($CLJS.H([$CLJS.yz,$CLJS.Mh.l($CLJS.H([k]))])),u=$CLJS.zB,v=u.l,x=$CLJS.Mh,z=x.l;var C=f;C=$CLJS.n(C)?C:$CLJS.YL(HY(d));$CLJS.GA("metabase.lib.convert",
m,$CLJS.wW("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.H([t,v.call(u,$CLJS.H([$CLJS.yz,z.call(x,$CLJS.H([C]))])),$CLJS.zB.l($CLJS.H([$CLJS.nz,$CLJS.xW($CLJS.A($CLJS.bu(d,l)))]))])),null)}if($CLJS.E.g(l,d))break a;d=l;e=$CLJS.be.g(e,new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null))}else break a}}d=jma(d);if($CLJS.E.g(c,d)){if($CLJS.E.g(b,$CLJS.D($CLJS.qL.h(a))-1))return a;b+=1}else a=$CLJS.Sk.N(a,$CLJS.qL,$CLJS.R,b,d)}};
lma=function(a){var b=$CLJS.KW();return $CLJS.Rk.g(function(c){return $CLJS.E.g($CLJS.VE.h(c),"__join")?$CLJS.Sk.j(c,$CLJS.VE,b):c},a)};mma=function(a){return"string"===typeof $CLJS.hP.h(a)?$CLJS.Hk.g($CLJS.R.j(a,$CLJS.QV,$CLJS.IW($CLJS.hP.h(a))),$CLJS.hP):a};MY=function(a,b){var c=$CLJS.J.j(a,b,LY);if($CLJS.E.g(c,LY))throw $CLJS.hi(["Unable to find ",$CLJS.Mh.l($CLJS.H([b]))," in map."].join(""),new $CLJS.h(null,2,[nma,a,$CLJS.Uw,b],null));return c};
NY=function(){return $CLJS.Qk.h(function(a){var b=$CLJS.I(a,0,null);$CLJS.I(a,1,null);(a=$CLJS.me(b))?(b=$CLJS.ie(b),b=$CLJS.n(b)?$CLJS.E.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};OY=function(a){return $CLJS.eg.j($CLJS.N,NY(),a)};PY=function(a){return $CLJS.Fe($CLJS.eg.j($CLJS.N,$CLJS.Pk.g(NY(),$CLJS.Qk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.Fi)})),a))};
RY=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);var d=$CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.hf.h($CLJS.QY),c);a=$CLJS.E.g(a,$CLJS.RF)&&$CLJS.B(c)?$CLJS.be.g($CLJS.sd(d),new $CLJS.h(null,1,[$CLJS.ci,$CLJS.rd(d)],null)):d;b=$CLJS.Fe(PY(b));return $CLJS.n(b)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xM,a,b],null):a};oma=function(a){return $CLJS.eg.j($CLJS.Cf,$CLJS.Pk.g($CLJS.hf.h(function(b){return $CLJS.AA(b,$CLJS.EB)}),$CLJS.hf.h($CLJS.QY)),$CLJS.EW.h(a))};
SY=function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.qL);a=$CLJS.A($CLJS.eb(function(b,c){var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.Q;var f=$CLJS.QY.h(c);d=$CLJS.n(d)?$CLJS.R.j(f,$CLJS.LQ,d):f;d=$CLJS.n(e)?$CLJS.R.j(d,$CLJS.UO,oma(e)):d;return new $CLJS.P(null,2,5,b,[d,$CLJS.qV.h(c)],null)},null,a));return $CLJS.n($CLJS.lQ.h(a))?$CLJS.BV(a,new $CLJS.h(null,1,[$CLJS.lQ,$CLJS.EF],null)):a};
TY=function(a,b,c){var d=$CLJS.E.g($CLJS.D($CLJS.J.g(a,b)),1)?$CLJS.WW(a,b,$CLJS.Pk.g($CLJS.QY,$CLJS.A)):a;a=1<$CLJS.D($CLJS.J.g(a,b))?$CLJS.WW(d,b,function(e){return $CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.xs],null),$CLJS.hf.h($CLJS.QY),e)}):d;return $CLJS.BV(a,$CLJS.Ee([b,c]))};pma=function(a){var b=$CLJS.QV.h(a);return $CLJS.n(b)?$CLJS.R.j($CLJS.Hk.g(a,$CLJS.QV),$CLJS.hP,["card__",$CLJS.p.h(b)].join("")):a};
qma=new $CLJS.M(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336);rma=new $CLJS.M(null,"legacy-ref","legacy-ref",1686404700);LY=new $CLJS.M("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);ima=new $CLJS.M("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);UY=new $CLJS.M("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);
VY=new $CLJS.M("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);$CLJS.WY=new $CLJS.M("lib.convert","converted?","lib.convert/converted?",1937433683);nma=new $CLJS.M(null,"m","m",1632677161);XY=new $CLJS.M("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var KY,sma,tma,uma,vma,wma,xma,yma,zma,Ama,Bma;$CLJS.YY=$CLJS.N;$CLJS.ZY=$CLJS.N;KY=new $CLJS.ah(null,new $CLJS.h(null,7,[$CLJS.kW,null,$CLJS.RE,null,$CLJS.GN,null,$CLJS.bQ,null,$CLJS.WF,null,$CLJS.RR,null,$CLJS.XF,null],null),null);sma=$CLJS.Xe($CLJS.N);tma=$CLJS.Xe($CLJS.N);uma=$CLJS.Xe($CLJS.N);vma=$CLJS.Xe($CLJS.N);wma=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.ij,$CLJS.fC],null),$CLJS.ij,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));
$CLJS.$Y=new $CLJS.di($CLJS.Fh.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.dC,wma,sma,tma,uma,vma);$CLJS.$Y.m(null,$CLJS.ci,function(a){if($CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M){var b=$CLJS.rd(a);var c=$CLJS.xd(b);a=c?$CLJS.sd(a):a;var d=$CLJS.y(a);a=$CLJS.A(d);d=$CLJS.B(d);b=$CLJS.rW($CLJS.eg.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,c?b:$CLJS.N],null),$CLJS.hf.h($CLJS.$Y),d))}else b=a;return b});$CLJS.$Y.m(null,$CLJS.jW,function(a){return a});
$CLJS.$Y.m(null,$CLJS.XV,function(a){var b=$CLJS.$Y.h($CLJS.XF.h(a)),c=$CLJS.Fe($CLJS.Rk.g(function(t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return $CLJS.BW($CLJS.$Y.h(t),u)},$CLJS.bQ.h(a))),d=$CLJS.eg.j($CLJS.N,$CLJS.gm(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.yE);return new $CLJS.P(null,2,5,$CLJS.Q,[t,u],null)}),b),e=$CLJS.eg.j($CLJS.N,$CLJS.gm(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.yE);return new $CLJS.P(null,
2,5,$CLJS.Q,[u,t],null)}),b),f=$CLJS.ZY,k=$CLJS.YY;$CLJS.ZY=d;$CLJS.YY=e;try{var l=fma(mma(a),b,$CLJS.H([$CLJS.bQ,c])),m=$CLJS.eb(function(t,u){return $CLJS.Va($CLJS.J.g(t,u))?t:$CLJS.Sk.j(t,u,$CLJS.$Y)},l,$CLJS.Ik.l(KY,$CLJS.XF,$CLJS.H([$CLJS.bQ])));return $CLJS.n($CLJS.GN.h(m))?$CLJS.Sk.j(m,$CLJS.GN,lma):m}finally{$CLJS.YY=k,$CLJS.ZY=f}});$CLJS.$Y.m(null,$CLJS.dW,function(a){return gma(a,function(b){return $CLJS.WW(b,$CLJS.BF,$CLJS.$Y)})});
$CLJS.$Y.m(null,$CLJS.lL,function(a){a=$CLJS.Sk.j($CLJS.Sk.j(a,$CLJS.tL,$CLJS.$Y),$CLJS.qL,$CLJS.$Y);var b=$CLJS.n($CLJS.RE.h(a))?$CLJS.Sk.j(a,$CLJS.RE,function(c){return $CLJS.Ml(c)?$CLJS.Rk.g($CLJS.$Y,c):$CLJS.zh.h(c)}):a;return $CLJS.Va($CLJS.VE.h(a))?$CLJS.R.j(b,$CLJS.VE,"__join"):b});$CLJS.$Y.m(null,$CLJS.jB,function(a){return $CLJS.Rk.g($CLJS.$Y,a)});
$CLJS.$Y.m(null,$CLJS.lB,function(a){return $CLJS.n($CLJS.nj.h(a))?kma($CLJS.R.j($CLJS.Sk.j($CLJS.FW(a),$CLJS.qL,function(b){return $CLJS.Rk.g($CLJS.$Y,b)}),$CLJS.WY,!0)):$CLJS.zt(a,$CLJS.$Y)});$CLJS.$Y.m(null,$CLJS.hG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.xd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null):new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.rW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,a,b],null))});
$CLJS.$Y.m(null,$CLJS.Ej,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.BV(a,new $CLJS.h(null,3,[$CLJS.eD,$CLJS.pB,$CLJS.GO,$CLJS.Vj,$CLJS.$R,$CLJS.tE],null));var c=$CLJS.R.j;var d=$CLJS.Fi.h(a);$CLJS.n(d)||(d=$CLJS.pB.h(a),d=$CLJS.n(d)?d:$CLJS.ME(b));a=c.call($CLJS.R,a,$CLJS.Fi,d);return $CLJS.rW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,a,b],null))});
$CLJS.$Y.m(null,$CLJS.RF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.ci.h(c);b=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RF,$CLJS.Hk.g(c,$CLJS.ci),$CLJS.Rk.g($CLJS.$Y,b)],null);b=$CLJS.rW(b);return null!=a?$CLJS.be.g(b,$CLJS.$Y.h(a)):b});$CLJS.$Y.m(null,$CLJS.mB,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.rW(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,c],null))});
$CLJS.$Y.m(null,$CLJS.XF,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.rW(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,MY($CLJS.ZY,c)],null))});$CLJS.$Y.m(null,$CLJS.xM,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=$CLJS.$Y.h(b);var c=$CLJS.y(b);b=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);d=$CLJS.B(d);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.gl.l($CLJS.H([c,a]))],null),d)});xma=$CLJS.Xe($CLJS.N);yma=$CLJS.Xe($CLJS.N);
zma=$CLJS.Xe($CLJS.N);Ama=$CLJS.Xe($CLJS.N);Bma=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.ij,$CLJS.fC],null),$CLJS.ij,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));$CLJS.QY=new $CLJS.di($CLJS.Fh.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.dC,Bma,xma,yma,zma,Ama);
$CLJS.QY.m(null,$CLJS.ci,function(a){if($CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M){a=$CLJS.y(a);var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);if($CLJS.xd(a)){c=$CLJS.eg.g;b=new $CLJS.P(null,1,5,$CLJS.Q,[b],null);var e=$CLJS.gf.g;d=$CLJS.hf.g($CLJS.QY,d);a=PY(a);a=c.call($CLJS.eg,b,e.call($CLJS.gf,d,$CLJS.n(a)?new $CLJS.P(null,1,5,$CLJS.Q,[a],null):null))}else a=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[b],null),$CLJS.hf.g($CLJS.QY,$CLJS.ee(a,d)))}else a=$CLJS.xd(a)?$CLJS.zt(OY(a),$CLJS.QY):
a;return a});for(var aZ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[XY,VY],null)),bZ=null,cZ=0,dZ=0;;)if(dZ<cZ){var Cma=bZ.X(null,dZ);$CLJS.pF(Cma,UY);dZ+=1}else{var eZ=$CLJS.y(aZ);if(eZ){var fZ=eZ;if($CLJS.Ad(fZ)){var gZ=$CLJS.lc(fZ),Dma=$CLJS.mc(fZ),Ema=gZ,Fma=$CLJS.D(gZ);aZ=Dma;bZ=Ema;cZ=Fma}else{var Gma=$CLJS.A(fZ);$CLJS.pF(Gma,UY);aZ=$CLJS.B(fZ);bZ=null;cZ=0}dZ=0}else break}
for(var hZ=$CLJS.y(new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.Qx,$CLJS.bG,$CLJS.qG,$CLJS.NF,$CLJS.jk,$CLJS.vG,$CLJS.Zn,$CLJS.TF,$CLJS.oG,$CLJS.wG,$CLJS.uG,$CLJS.BG],null)),iZ=null,jZ=0,kZ=0;;)if(kZ<jZ){var Hma=iZ.X(null,kZ);$CLJS.pF(Hma,XY);kZ+=1}else{var lZ=$CLJS.y(hZ);if(lZ){var mZ=lZ;if($CLJS.Ad(mZ)){var nZ=$CLJS.lc(mZ),Ima=$CLJS.mc(mZ),Jma=nZ,Kma=$CLJS.D(nZ);hZ=Ima;iZ=Jma;jZ=Kma}else{var Lma=$CLJS.A(mZ);$CLJS.pF(Lma,XY);hZ=$CLJS.B(mZ);iZ=null;jZ=0}kZ=0}else break}
for(var oZ=$CLJS.y($CLJS.yf([$CLJS.Wr,$CLJS.it,$CLJS.Xr,$CLJS.CG,$CLJS.RF,$CLJS.kG,$CLJS.IF,$CLJS.EG,$CLJS.zF,$CLJS.VF,$CLJS.DF,$CLJS.fG,$CLJS.mG,$CLJS.FF,$CLJS.ZI,$CLJS.aJ,$CLJS.Ey,$CLJS.VI,$CLJS.cJ,$CLJS.PI,$CLJS.gJ,$CLJS.UI,$CLJS.NI,$CLJS.fJ,$CLJS.nJ,$CLJS.jJ,$CLJS.TI,$CLJS.qJ,$CLJS.iJ,$CLJS.oJ,$CLJS.HF,$CLJS.AF,$CLJS.FG,$CLJS.ZH,$CLJS.jG,$CLJS.Ox,$CLJS.xG,$CLJS.zG,$CLJS.GG,$CLJS.Ct,$CLJS.It],!0)),pZ=null,qZ=0,rZ=0;;)if(rZ<qZ){var Mma=pZ.X(null,rZ);$CLJS.pF(Mma,VY);rZ+=1}else{var sZ=$CLJS.y(oZ);
if(sZ){var tZ=sZ;if($CLJS.Ad(tZ)){var uZ=$CLJS.lc(tZ),Nma=$CLJS.mc(tZ),Oma=uZ,Pma=$CLJS.D(uZ);oZ=Nma;pZ=Oma;qZ=Pma}else{var Qma=$CLJS.A(tZ);$CLJS.pF(Qma,VY);oZ=$CLJS.B(tZ);pZ=null;qZ=0}rZ=0}else break}$CLJS.QY.m(null,UY,function(a){return RY(a)});$CLJS.QY.m(null,$CLJS.lB,function(a){return $CLJS.eg.j($CLJS.N,$CLJS.Pk.g(NY(),$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.QY.h(b)],null)})),a)});
$CLJS.QY.m(null,$CLJS.XF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);if($CLJS.xd(b))try{var d=PY(b),e=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XF,MY($CLJS.YY,c)],null);return $CLJS.n(d)?$CLJS.be.g(e,d):e}catch(f){throw b=f,c=$CLJS.vV(b),c=$CLJS.fa.g?$CLJS.fa.g("Error converting aggregation reference to pMBQL: %s",c):$CLJS.fa.call(null,"Error converting aggregation reference to pMBQL: %s",c),$CLJS.gi(c,new $CLJS.h(null,1,[$CLJS.W,a],null),b);}else return a});
$CLJS.QY.m(null,$CLJS.jB,function(a){return $CLJS.Rk.g($CLJS.QY,a)});$CLJS.QY.m(null,$CLJS.hG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=null==b||$CLJS.xd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,$CLJS.QY.h(a),PY(b)],null)});
$CLJS.QY.m(null,$CLJS.Ej,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=PY($CLJS.BV(b,new $CLJS.h(null,3,[$CLJS.pB,$CLJS.eD,$CLJS.Vj,$CLJS.GO,$CLJS.tE,$CLJS.$R],null)));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,a,b],null)});
$CLJS.QY.m(null,$CLJS.lL,function(a){var b=OY(a);a=0==$CLJS.VE.h(a).lastIndexOf("__join",0)?$CLJS.Hk.g(b,$CLJS.VE):b;return $CLJS.gl.l($CLJS.H([$CLJS.zt($CLJS.Hk.l(a,$CLJS.qL,$CLJS.H([$CLJS.tL])),$CLJS.QY),TY($CLJS.em(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.tL],null)),$CLJS.tL,$CLJS.TR),SY(a)]))});
$CLJS.QY.m(null,$CLJS.XV,function(a){var b=$CLJS.XF.h(a),c=$CLJS.eg.j($CLJS.N,$CLJS.gm(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.yE);return new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}),b);b=$CLJS.eg.j($CLJS.N,$CLJS.gm(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.yE);return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),b);var d=$CLJS.ZY,e=$CLJS.YY;$CLJS.ZY=c;$CLJS.YY=b;try{return $CLJS.eb(function(f,k){return $CLJS.WW(f,
k,$CLJS.QY)},TY($CLJS.WW($CLJS.WW(pma(OY(a)),$CLJS.XF,function(f){return $CLJS.Rk.g(RY,f)}),$CLJS.bQ,function(f){return $CLJS.eg.g($CLJS.N,function(){return function m(l){return new $CLJS.ne(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.Ad(t)){var u=$CLJS.lc(t),v=$CLJS.D(u),x=$CLJS.qe(v);a:for(var z=0;;)if(z<v){var C=$CLJS.kd(u,z),G=$CLJS.QY.h(C);C=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AW(C),$CLJS.E.g($CLJS.Ej,$CLJS.A(G))?$CLJS.hd(G):G],null);x.add(C);z+=1}else{u=!0;break a}return u?$CLJS.te($CLJS.ve(x),
m($CLJS.mc(t))):$CLJS.te($CLJS.ve(x),null)}x=$CLJS.A(t);u=$CLJS.QY.h(x);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AW(x),$CLJS.E.g($CLJS.Ej,$CLJS.A(u))?$CLJS.hd(u):u],null),m($CLJS.Lc(t)))}return null}},null,null)}(f)}())}),$CLJS.kW,$CLJS.MF),$CLJS.Ik.l(KY,$CLJS.XF,$CLJS.H([$CLJS.kW,$CLJS.bQ])))}finally{$CLJS.YY=e,$CLJS.ZY=d}});$CLJS.QY.m(null,$CLJS.dW,function(a){return $CLJS.zt(OY(a),$CLJS.QY)});
$CLJS.QY.m(null,$CLJS.jW,function(a){try{var b=OY(a),c=$CLJS.xN.h(b),d=SY(b),e=$CLJS.E.g($CLJS.Xt.h($CLJS.id($CLJS.qL.h(a))),$CLJS.dW)?$CLJS.lQ:$CLJS.EF;return $CLJS.gl.l($CLJS.H([$CLJS.Hk.l(b,$CLJS.qL,$CLJS.H([$CLJS.xN,$CLJS.WY])),function(){var k=$CLJS.Ee([$CLJS.nj,e,e,d]);return $CLJS.y(c)?$CLJS.R.j(k,$CLJS.xN,c):k}()]))}catch(k){var f=k;throw $CLJS.gi(function(){var l=$CLJS.vV(f);return $CLJS.fa.g?$CLJS.fa.g("Error converting MLv2 query to legacy query: %s",l):$CLJS.fa.call(null,"Error converting MLv2 query to legacy query: %s",
l)}(),new $CLJS.h(null,1,[$CLJS.EF,a],null),f);}});
$CLJS.vZ=function(){function a(d,e,f){f=$CLJS.EY(null,$CLJS.Lz(f,$CLJS.H([$CLJS.zi,!0])));var k=$CLJS.HW(d,e);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.XF);k=$CLJS.eg.j($CLJS.N,$CLJS.gm(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.yE);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),l);l=$CLJS.eg.j($CLJS.N,$CLJS.gm(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.yE);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),l);var m=
$CLJS.ZY,t=$CLJS.YY;$CLJS.ZY=k;$CLJS.YY=l;try{try{return $CLJS.$Y.h(f)}catch(v){var u=v;throw $CLJS.gi(function(){var x=$CLJS.vV(u);return $CLJS.fa.g?$CLJS.fa.g("Error converting legacy ref to pMBQL: %s",x):$CLJS.fa.call(null,"Error converting legacy ref to pMBQL: %s",x)}(),new $CLJS.h(null,4,[$CLJS.EF,d,$CLJS.FL,e,rma,f,qma,$CLJS.ZY],null),u);}}finally{$CLJS.YY=t,$CLJS.ZY=m}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();