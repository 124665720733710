var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var vH,wH,yH,zH,Pfa,Qfa,Rfa,AH,xH;$CLJS.tH=function(a,b){return $CLJS.gc($CLJS.eb(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.hg.j(c,e,$CLJS.be.g($CLJS.J.j(c,e,$CLJS.Cf),d))},$CLJS.ec($CLJS.N),b))};$CLJS.uH=function(a,b){return $CLJS.eb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Sc(d):null},null,b)};
vH=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);b=$CLJS.rC(b,$CLJS.SC)?$CLJS.eH:$CLJS.rC(b,$CLJS.RD)?$CLJS.$G:$CLJS.rC(b,$CLJS.rD)?$CLJS.bH:null;return $CLJS.n(b)?$CLJS.oF(b,a):!0};
wH=function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);$CLJS.A(a);a=$CLJS.B(a);a=$CLJS.tH(function(d){return $CLJS.rC($CLJS.ME(d),$CLJS.cD)},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,!1);b=$CLJS.J.g(b,!0);if($CLJS.Nk.g($CLJS.D(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.D(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.ME($CLJS.A(a));return $CLJS.Re(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);$CLJS.I(d,2,null);
var e=$CLJS.I(d,3,null);return $CLJS.n(vH(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
yH=function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.pt,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xH],null)],
null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.rt,function(b){b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.Ej);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(wH(b))].join("")}],null),$CLJS.Te(wH)],null)],null)};
zH=function(a){return new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.fs,new $CLJS.h(null,1,[$CLJS.pt,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ds,new $CLJS.h(null,1,[$CLJS.Zn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)],null)],null)};
Pfa=function(a){return $CLJS.Qd($CLJS.rF,$CLJS.hf.g(function(b){var c=$CLJS.ME(b),d=$CLJS.rC(c,$CLJS.YE);b=d?$CLJS.oF($CLJS.lG,b):d;return $CLJS.n(b)?$CLJS.Pj:c},a))};Qfa=function(a){a=$CLJS.uH(function(b){return!$CLJS.rC(b,$CLJS.cD)},$CLJS.hf.g($CLJS.ME,a));return $CLJS.rC(a,$CLJS.YE)?$CLJS.Wj:a};
Rfa=function(a,b){return $CLJS.n($CLJS.Re(function(c){return $CLJS.rC($CLJS.ME(c),$CLJS.cD)},b))?Qfa(b):$CLJS.E.g(a,$CLJS.it)&&$CLJS.E.g($CLJS.D(b),2)&&($CLJS.Qe(function(c){return $CLJS.rC($CLJS.ME(c),$CLJS.SC)},b)||$CLJS.Qe(function(c){return $CLJS.rC($CLJS.ME(c),$CLJS.rD)},b))?$CLJS.cD:Pfa(b)};AH=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);
$CLJS.BH=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);xH=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.X(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.is,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wr,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,xH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.pt,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);var c=$CLJS.ME(a);return $CLJS.Qe(function(d){return vH(d,c)},b)}],null)],null));
$CLJS.X(AH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ds,new $CLJS.h(null,1,[$CLJS.Zn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)],null));
var Sfa=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.fs,new $CLJS.h(null,1,[$CLJS.pt,":- clause taking the difference of two temporal expressions"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.it],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null)],null)],
null);$CLJS.HG.g($CLJS.Wr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,yH($CLJS.Wr),zH($CLJS.Wr)],null));$CLJS.HG.g($CLJS.it,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rs,yH($CLJS.it),Sfa,zH($CLJS.it)],null));$CLJS.yF($CLJS.Xr,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,AH],null)]));$CLJS.yF($CLJS.CG,$CLJS.H([$CLJS.it,$CLJS.OD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,AH],null)]));
for(var CH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wr,$CLJS.it,$CLJS.Xr],null)),DH=null,EH=0,FH=0;;)if(FH<EH){var Tfa=DH.X(null,FH);$CLJS.pF(Tfa,$CLJS.BH);FH+=1}else{var GH=$CLJS.y(CH);if(GH){var HH=GH;if($CLJS.Ad(HH)){var IH=$CLJS.lc(HH),Ufa=$CLJS.mc(HH),Vfa=IH,Wfa=$CLJS.D(IH);CH=Ufa;DH=Vfa;EH=Wfa}else{var Xfa=$CLJS.A(HH);$CLJS.pF(Xfa,$CLJS.BH);CH=$CLJS.B(HH);DH=null;EH=0}FH=0}else break}
$CLJS.LE.m(null,$CLJS.BH,function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);$CLJS.A(b);b=$CLJS.B(b);return Rfa(a,b)});$CLJS.wF($CLJS.IF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)]));$CLJS.pF($CLJS.IF,$CLJS.bF);
for(var JH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EG,$CLJS.zF,$CLJS.VF],null)),KH=null,LH=0,MH=0;;)if(MH<LH){var Yfa=KH.X(null,MH);$CLJS.wF(Yfa,$CLJS.H([$CLJS.it,$CLJS.OD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)]));MH+=1}else{var NH=$CLJS.y(JH);if(NH){var OH=NH;if($CLJS.Ad(OH)){var PH=$CLJS.lc(OH),Zfa=$CLJS.mc(OH),$fa=PH,aga=$CLJS.D(PH);JH=Zfa;KH=$fa;LH=aga}else{var bga=$CLJS.A(OH);$CLJS.wF(bga,$CLJS.H([$CLJS.it,$CLJS.OD,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)]));JH=$CLJS.B(OH);KH=null;LH=0}MH=0}else break}
for(var QH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DF,$CLJS.fG,$CLJS.mG],null)),RH=null,SH=0,TH=0;;)if(TH<SH){var cga=RH.X(null,TH);$CLJS.wF(cga,$CLJS.H([$CLJS.it,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)]));TH+=1}else{var UH=$CLJS.y(QH);if(UH){var VH=UH;if($CLJS.Ad(VH)){var WH=$CLJS.lc(VH),dga=$CLJS.mc(VH),ega=WH,fga=$CLJS.D(WH);QH=dga;RH=ega;SH=fga}else{var gga=$CLJS.A(VH);$CLJS.wF(gga,$CLJS.H([$CLJS.it,$CLJS.xj,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)]));QH=$CLJS.B(VH);RH=null;SH=0}TH=0}else break}$CLJS.wF($CLJS.FF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)]));
$CLJS.LE.m(null,$CLJS.FF,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.rC($CLJS.ME(b),$CLJS.xj)&&$CLJS.rC($CLJS.ME(a),$CLJS.xj)?$CLJS.xj:$CLJS.OD});